import Divider from "@mui/material/Divider";
import React from "react";
import {Row} from "reactstrap";
import {ServiceCard} from "../views/services/ServiceCard";
import InfoCard from "./InfoCard";

function ServiceSliders({artistGenre, dataList}) {

    return (
        <div className="m-2">
            <InfoCard
                caption={artistGenre}
                sx={{ marginBottom: 2, marginTop: 4 }}
            />
            <Divider/>
            <Row className="justify-content-start overflow-auto">
                <div className="mt-2">
                    <ServiceCard itemKey="artist_musician" data={dataList}/>
                </div>
            </Row>
        </div>
    )
}

export default ServiceSliders;
