import {Typography} from "@mui/material";
import Rating from '@mui/material/Rating';
import React, {useEffect, useState} from 'react';
import toast from "react-hot-toast";
import {useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import ImgSlider from "../../tools/ImgSlider";
import {checkServiceId, useWindowSize} from "../../tools/Tools";
import {artist_t} from "../../tools/Variables";
import ArtistFollow from "../profile/ArtistFollow";
import SkeletonServices from "../skeletons/SkeletonServices";
import TabsDetail from "./TabsDetail";
import ServiceDetails from "./ServiceDetails";
import Tooltip from '@mui/material/Tooltip';
import SendTimeExtensionIcon from '@mui/icons-material/SendTimeExtension';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import makeStyles from '@mui/styles/makeStyles';

const backGround = 'https://res.cloudinary.com/kantobiz/image/upload/v1729965383/images/kantobiz/cr0ifvuauy0sc22afjvn.png'

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: 343,
        margin: 'auto',
        borderRadius: 12,
        backdropFilter: "blur(10px)",
        // background: "linear-gradient(180deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.4))",
        background: "linear-gradient(180deg, rgba(255, 255, 255, 0.4), rgba(0, 0, 0, 0.3))",
        padding: 12,
    },
}));

const Service = () => {

    let {id} = useParams()
    const navigate = useNavigate();
    const styles = useStyles();
    const width = useWindowSize();
    const [currency, setCurrency] = useState("$");
    const [rating, setRating] = useState(0);
    const [loading, setLoading] = useState(true);
    const [service, setService] = useState({});
    const [disabled, setDisabled] = useState(false);
    const {isDev, countries} = useSelector(state => state.global);
    const auditor = useSelector(state => state.axios.auditor);
    const auth = useSelector(state => state.session.authenticated);
    const userId = useSelector(state => state.profile.userId);
    const results = useSelector(state => state.searchBarData.results);

    const serviceChecked = async (_s) => {
        let countryChoice = countries?.filter(tmp => tmp.name === _s.country)[0]
        setCurrency(countryChoice?.currencies.symbol)
        setService(_s)
        setLoading(false)
    }

    const updateNotes = (note) => {
        if (!auth) {
            document.getElementById("login_button").click()
        } else {
            let data = {service_id: service.id, note: [note]}
            auditor.put('stars/update', data).then((resp) => {
                setRating(note)
                toast.success('Vous avez noté la prestation de ' + service.artist_name)
            })
        }
    }

    const CardHeader = (props) => {
        return (
            <Box display="flex" alignItems="center" justifyContent="space-between" {...props}>
                <Box flex={1} textAlign="left">
                    <h5 style={{fontSize: 12}}>
                        Contact {service.contact_amount || "*Offert"}
                        {service.contact_amount ? currency : ""}
                    </h5>
                </Box>
                <Box textAlign="right" mr={1}>
                    <Button
                        size="small"
                        variant="contained"
                        disabled={userId === service?.user_id}
                        onClick={() => navigate("/artist/contact/service/" + service.id)}
                        endIcon={<SendTimeExtensionIcon/>}
                    >
                        Prendre Contact
                    </Button>
                </Box>
            </Box>
        );
    };

    useEffect(() => {
        if (countries?.length) {
            checkServiceId(results, serviceChecked, setLoading, navigate, auditor, id).then(r => null);
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [countries]);

    useEffect(() => {
        if (auth && userId === service.user_id) {
            setDisabled(true)
        } else if (!isDev && service.hidden) {
            setDisabled(false)
        } else if (disabled) {
            setDisabled(false)
        }
    }, [auth, isDev, disabled, userId, service.user_id, service.hidden]);

    return (
        <div className="content mb-5">

            <img
                hidden={width < 775}
                src={backGround}
                alt=""
                style={{
                    position: 'absolute',
                    bottom: 1 / 2 + '%',
                    right: 0,
                    width: '80%',
                    height: '80%',
                    objectFit: 'cover',
                    opacity: 0.3,
                }}
            />

            <div className="justify-content-center bg-transparent border-0 overflow-auto"
                 style={{height: "100vh"}}>
                {loading
                    ? <SkeletonServices/>
                    : <div className="row justify-content-center border-0 mt-3">
                        <div className="col-lg-8 col-md-12 mt-2 height-full">

                            <Box display="flex" flexDirection="column">
                                <CardHeader className="mb-3"/>
                                <ServiceDetails service={service} currency={currency}/>
                                <br/>
                                <ArtistFollow
                                    service={service}
                                    disabled={disabled}
                                    tag={artist_t[service?.artist_type]}/>
                            </Box>
                        </div>
                        <div className="col-lg-4 col-md-12 mt-3">


                            <Box flexGrow={1}>
                                <Box minHeight={200} borderRadius={8}>
                                    <Card className={styles.root}>

                                        <ImgSlider images={service.galleries}/>

                                        <CardContent>
                                            <Box display="flex" justifyContent="center" alignItems="center">
                                                <Tooltip title="Je note la fiche" placement="top">
                                                    <Rating
                                                        name="half-rating"
                                                        value={rating}
                                                        size="medium"
                                                        readOnly={disabled}
                                                        onChange={(e, newValue) => updateNotes(newValue)}
                                                    />
                                                </Tooltip>
                                            </Box>
                                            <Typography
                                                variant="h6"
                                                align="center"
                                                gutterBottom
                                            >
                                                {service?.title?.substring(0, 10) +
                                                    `${service?.title?.length > 10 ? "..." : ""}`}
                                            </Typography>
                                            <TabsDetail
                                                materials={service.materials}
                                                rPolicy={service.refund_policy}
                                                description={service.description}
                                            />
                                        </CardContent>
                                    </Card>
                                </Box>
                            </Box>

                        </div>
                    </div>}
            </div>
        </div>
    );
};

export default Service;
