import CloseIcon from "@mui/icons-material/Close";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {FormGroup, Row, Col} from "reactstrap";
import {
    InputAdornment,
    TextField,
    CircularProgress,
    Paper,
    List,
    ListItem,
    Tooltip,
    Button, IconButton, Box,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import toast from "react-hot-toast";
import apis from "../../../config/apis";
import {addProfileData} from "../../../reducer";
import {fetchSuggestions} from "../../../tools/AddressUtils";
import {
    fillInBodyForm,
    checkErrorMessage,
    extractHouseNumber,
    ITextField,
    changeFields,
    IMultiSelectTools, searchCity, btnStyle, deleteNullInObj, footerStyle
} from "../../../tools/Tools";

function AddDefaultAddress({closeModal, streetDefault, postalCodeDefault, cityDefault}) {

    const dispatch = useDispatch();
    const auditor = useSelector(state => state.axios.auditor);
    const [city, setCity] = useState(cityDefault);
    const [street, setStreet] = useState(streetDefault);
    const [postalCode, setPostalCode] = useState(postalCodeDefault);
    const [query, setQuery] = useState("");
    const [listOfCity, setListOfCity] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const [isFocused, setIsFocused] = useState(false);
    const [manualEntry, setManualEntry] = useState(false);
    const auditorForm = useSelector((state) => state.axios.auditorForm);
    const profileData = useSelector((state) => state.profile.profileData);
    const {countryLogID, countryLog, countryCode} = useSelector(state => state.global);
    const [isValid, setIsValid] = useState({
        city: false,
        street: false,
        postalCode: false,
    });

    const handleInputChange = (e) => {
        const newValue = e.target.value;
        setQuery(newValue);

        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }

        const newTimeout = setTimeout(async () => {
            try {
                if (newValue.length > 2) {
                    setLoading(true);
                    const suggestions = await fetchSuggestions(newValue, countryCode);
                    setSuggestions(suggestions);
                } else {
                    setSuggestions([]); // Réinitialise si la requête est trop courte
                }
            } catch (error) {
                toast.error(error.message);
            } finally {
                setLoading(false);
            }
        }, 1000);

        setDebounceTimeout(newTimeout);
    };

    const handleSuggestionClick = (addressData) => {
        const placeName = addressData.place_name;
        if (!placeName) return;

        const houseNumber = extractHouseNumber(query);
        const context = addressData.context || [];

        const streetName = `${houseNumber || ""} ${addressData.text || ""}`.trim();
        const cityName = context.find((c) => c.id.includes("place"))?.text || "";
        const postalCodeValue = context.find((c) => c.id.includes("postcode"))?.text || "";
        const countryCodeFromAddress = context.find((c) => c.id.includes("country"))?.short_code;

        if (countryCodeFromAddress?.toLowerCase() !== countryCode?.toLowerCase()) {
            toast.error("L'adresse est invalide pour ce pays.");
            return;
        }

        if (!streetName || !cityName || !postalCodeValue) {
            toast.error("L'adresse sélectionnée est incomplète.");
            return;
        }

        setStreet(streetName);
        setCity(cityName);
        setPostalCode(postalCodeValue);
        setQuery("");
        setSuggestions([]);
    };

    const addAddress = async () => {
        if (!Object.values(isValid).includes(false)) {
            const updatedProfile = deleteNullInObj({...profileData});
            delete updatedProfile['is_certified'];
            updatedProfile.address = `${street}, ${postalCode} ${city}, ${countryLog}`;
            const bodyFormData = fillInBodyForm(new FormData(), updatedProfile);

            try {
                const response = await auditorForm.put(apis.updateProfile, bodyFormData);
                dispatch(addProfileData(response.data));
                toast.success("Votre adresse a été mise à jour.");
                manualEntry && setManualEntry(false)
                closeModal();
            } catch (error) {
                toast.error(checkErrorMessage(error).message);
            }
        } else {
            toast.error("Tous les champs sont obligatoires.");
        }
    };

    useEffect(() => {
        setIsValid((state) => ({
            ...state,
            city: city?.length > 0,
            street: street?.length > 0,
            postalCode: postalCode?.length > 0,
        }));
    }, [city, street, postalCode]);

    return manualEntry ? (
            <div className="mt-4 p-1">

                <Row>
                    <Col md="7">
                        <FormGroup>
                            {ITextField(
                                "Numéro et nom de rue",
                                null,
                                street,
                                (e) => changeFields(setStreet, e)
                            )}
                        </FormGroup>
                    </Col>
                    <Col md="5">
                        <FormGroup>
                            {ITextField(
                                'Code Postale',
                                null,
                                postalCode,
                                (e) => changeFields(setPostalCode, e),
                                null, false
                            )}
                        </FormGroup>
                    </Col>
                    <Col md="12">
                        <FormGroup>
                            {IMultiSelectTools(
                                "Localisation (Une ville)",
                                city,
                                searchCity,
                                listOfCity,
                                (e, value) => changeFields(setCity, value),
                                null,
                                "standard",
                                auditor,
                                countryLogID,
                                setListOfCity,
                                true)}
                        </FormGroup>
                    </Col>
                </Row>

                <Box sx={footerStyle}>
                    <Tooltip title="Annuler" placement="top">
                        <IconButton
                            size="small"
                            aria-label="settings"
                            disabled={loading}
                            sx={btnStyle('lightGreen')}
                            onClick={() => setManualEntry(false)}>
                            <CloseIcon fontSize="small"/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Enregistrer" placement="top">
                        <IconButton
                            size="small"
                            aria-label="settings"
                            disabled={loading}
                            sx={btnStyle('lightGreen')}
                            onClick={() => addAddress()}>
                            <small>Enregistrer&nbsp;</small>
                            <DoneAllIcon sx={{color: 'lightGreen!important'}}/>
                        </IconButton>
                    </Tooltip>
                </Box>

            </div>
        )
        : (
            <div>

                <Button
                    onClick={() => setManualEntry(true)}
                    color="secondary"
                    style={{
                        marginTop: 10,
                        marginBottom: 10,
                        fontSize: "0.8rem",
                        color: "#a0a0a0",
                        backgroundColor: "transparent",
                        border: "none"
                    }}>
                    Entrer manuellement
                </Button>


                <TextField
                    fullWidth
                    value={!isFocused && street && city ? `${street}, ${postalCode} ${city}` : query}
                    variant="filled"
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    label="Entrez l'adresse"
                    placeholder="123 Elm Street...."
                    onChange={handleInputChange}
                    InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon/>
                            </InputAdornment>
                        ),
                        endAdornment: loading && (
                            <InputAdornment position="end">
                                <CircularProgress size={20}/>
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        "& .MuiFilledInput-root": {
                            borderRadius: "10px",
                            backgroundColor: "#f1f3f4",
                        },
                    }}
                />
                {suggestions.length > 0 && (
                    <Paper
                        sx={{
                            position: "absolute",
                            width: "100%",
                            maxHeight: 200,
                            overflowY: "auto",
                            marginTop: 1,
                            zIndex: 1,
                            backgroundColor: "#f3f3f3",
                        }}
                    >
                        <List>
                            {suggestions.map((suggestion) => (
                                <ListItem
                                    button
                                    key={suggestion.id}
                                    onClick={() => handleSuggestionClick(suggestion)}
                                >
                                    {suggestion.place_name.length > 40
                                        ? `${suggestion.place_name.slice(0, 40)}...`
                                        : suggestion.place_name}
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                )}

                <Box sx={footerStyle}>
                    <Tooltip title="Annuler" placement="top">
                        <IconButton
                            size="small"
                            aria-label="settings"
                            disabled={loading}
                            sx={btnStyle('lightGreen')}
                            onClick={() => closeModal()}>
                            <CloseIcon fontSize="small"/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Enregistrer" placement="top">
                        <IconButton
                            size="small"
                            aria-label="settings"
                            disabled={loading}
                            sx={btnStyle('lightGreen')}
                            onClick={() => addAddress()}>
                            <small>Enregistrer&nbsp;</small>
                            <DoneAllIcon sx={{color: 'lightGreen!important'}}/>
                        </IconButton>
                    </Tooltip>
                </Box>
            </div>
        )
}

export default AddDefaultAddress;
