import VerifiedIcon from "@mui/icons-material/Verified";
import Tooltip from "@mui/material/Tooltip";
import React from "react"
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
import Badge from "@mui/material/Badge";
import moment from "moment/moment";
import {useSelector} from "react-redux";
import AddTaskIcon from '@mui/icons-material/AddTask';
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

dayjs.extend(utc)


function ContactCardHeader({userId, book, setShowBook}) {

    let now = moment()
    const bookedList = parseInt(userId) !== parseInt(book?.artist_id)
    const tabIndex = useSelector(state => state.contactCards.tabIndex);
    const isPaidAllow = !bookedList
        && (book?.reservation?.status === 'paid'
            && book?.reservation?.payment
            && now.isAfter(new Date(book?.reservation?.event_date))
        )

    return (
        <CardHeader
            style={{
                width: "100%",
                display: "flex",
                padding: "5px 10px",
                justifyContent: "space-between",
                alignItems: "center",
            }}
            onClick={() => setShowBook(book)}
            action={
                <Typography
                    variant="caption"
                    color="textSecondary"
                    style={{
                        fontSize: "11px", // Ajuste la taille pour qu'elle soit cohérente avec le titre
                        display: "flex", // Ajoute Flexbox pour aligner
                        alignItems: "center", // Centré verticalement avec le texte du titre
                        marginBottom: '5px'
                    }}
                >
                    {userId === book?.user_id ? "Envoyée le" : "Reçu le" }
                    &nbsp;
                    {dayjs(book.created_at).utc().format("DD-MM-YYYY")}
                </Typography>
            }
            avatar={
                <div className="text-lg-center mt-1">
                    <div
                        className="s-24 m-auto"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            fontSize: "10px", // Réduction de la taille globale
                        }}
                    >
                        {isPaidAllow ? (
                            <AddTaskIcon
                                fontSize="small"
                                sx={{
                                    color: "lightGreen!important",
                                    padding: 0.2,
                                    fontSize: "14px", // Icône plus petite
                                }}
                            />
                        ) : tabIndex === 1 ? (
                            <FiberManualRecordIcon
                                fontSize="small"
                                sx={{
                                    mb: 0.5,
                                    fontSize: "12px", // Taille de l'icône réduite
                                    color: ['paid', 'finish', 'accepted'].includes(
                                        book.reservation.status
                                    )
                                        ? 'lightGreen!important'
                                        : book.reservation.status === 'declined' || book.aborted
                                            ? 'danger!important'
                                            : 'silver!important',
                                }}
                            />
                        ) : null}
                        <div style={{ fontWeight: 500 }}>
                            {dayjs(book.reservation.event_date).format("DD")}
                        </div>
                        <small style={{ marginTop: "-4px", fontSize: "11px" }}>
                            {dayjs(book.reservation.event_date).format("MM")},&nbsp;
                            {dayjs(book.reservation.event_date).format("YYYY")}
                        </small>
                    </div>
                </div>
            }
            title={
                <div>
                    {/* Événement */}
                    <Badge
                        variant="dot"
                        color="secondary"
                        invisible={!isPaidAllow}
                    >
                        <Typography
                            variant="body1"
                            fontWeight="bold"
                            style={{
                                fontSize: "13px",
                                lineHeight: "1.2",
                            }}
                        >
                            {book?.reservation?.event}
                        </Typography>
                    </Badge>

                    {/* Statuts supplémentaires */}
                    <Typography
                        variant="caption"
                        className="d-none d-lg-block"
                        style={{fontSize: "11px", fontWeight: 200}}
                    >
                        {isPaidAllow
                            ? "Fonds disponibles pour encaissement"
                            : book?.reservation?.status === "finish" && !bookedList
                                ? "Encaissement effectué"
                                : ""}
                    </Typography>
                </div>
            }
            subheader={
                <Typography
                    variant="caption"
                    style={{
                        fontSize: "12px",
                    }}
                >
                    {bookedList ? (
                        <em
                            style={{
                                display: "flex",
                                gap: "4px",
                            }}
                        >
                            {book?.reservation?.artist_name}
                            {book?.is_certified && (
                                <Tooltip title="Profil vérifié" placement="top">
                                    <VerifiedIcon
                                        sx={{
                                            marginTop: .1,
                                            color: "#1976d2 !important",
                                            fontSize: "14px",
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </em>
                    ) : (
                        <em
                            style={{
                            display: "flex",
                            gap: "4px",
                        }}>
                            {book?.lastname} {book?.name}
                        </em>
                    )}
                </Typography>
            }
        />

    )
}

export default ContactCardHeader;
