import Button from "@mui/material/Button";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React, {useEffect, useRef, useState} from "react";
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import {Col, Row} from "reactstrap";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {addReservationPrice} from "../../reducer";
import TextCard from "../../tools/TextCard";
import {outputHtPrice} from "../../tools/Tools";

function ContactAmountInfos({isPaid}) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {currencySymbol, send, process, service} = useSelector(state => state.toContact);
    const isMounted = useRef(false);
    const [totalAmount, setTotalAmount] = useState('');
    const [ttcArtist, setTTCArtist] = useState(0);
    const [serviceFee, setServiceFee] = useState(0);

    useEffect(() => {

        let _tmp = service?.contact_amount;
        let tmp = outputHtPrice(_tmp)
        setTTCArtist(tmp.artistFee)
        setServiceFee(tmp.serviceFee)
        setTotalAmount(_tmp);
        dispatch(addReservationPrice(_tmp))

        return () => {
            isMounted.current = true
        };
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    return (
        <div>

            {service?.contact_amount && !isPaid && !send
                ? (
                    <div>
                        <h6 className="d-flex justify-content-between align-items-center mb-3">
                            <span className="text-dark">Prise de contact</span>
                            <span className="badge badge-primary">{totalAmount + ' ' + currencySymbol}</span>
                        </h6>
                        <ul className="list-group mb-4">
                            <li className="list-group-item d-flex justify-content-between">
                                <div className="text-left">
                            <pre className="my-0 pl-1 pr-1 rounded">
                                {service?.artist_name}&nbsp;
                                <small>({service?.title})</small>
                            </pre>
                                    <small className="text-muted ml-1">Frais de contact</small>
                                </div>
                                <small className="text-red">{ttcArtist + ' ' + currencySymbol}</small>
                            </li>
                            <li className="list-group-item d-flex justify-content-between">
                                <div className="text-left">
                                    <pre className="my-0 pl-1 pr-1 rounded">Frais de service</pre>
                                </div>
                                <small className="text-red">{serviceFee + ' ' + currencySymbol}</small>
                            </li>
                            <li className="list-group-item d-flex justify-content-between">
                                <div className="text-left">
                            <pre className="my-0 pl-1 pr-1 rounded">
                                Total&nbsp;
                                <small>TTC du contact</small>
                            </pre>
                                </div>
                                <strong className="text-red">{totalAmount + ' ' + currencySymbol}</strong>
                            </li>
                        </ul>
                        <hr/>
                    </div>
                ) : isPaid || send
                    ? (
                        <Row className="justify-content-end">
                            <Col className="ml-2 mr-2">
                                <CheckCircleIcon fontSize="large" sx={{mr: 1, mb: 1, color: 'lightGreen !important'}}/>
                                Le contact a été établi avec succès.
                            </Col>
                        </Row>
                    ) : ''}
            <Box className="width-full">
                <CardContent>
                    <TextCard
                        overline={'Informations'}
                        heading={"Une prise de contact ?"}
                        body={"La prise de contact permet de présenter à l'artiste tous les détails nécessaires pour l'événement que vous lui proposez. La réponse est sous 48 heures au maximum."}
                    />
                    <TextCard
                        heading={"Ce qui se passe après ?"}
                        body={<>
                            {"• Une discussion avec l'artiste sera ensuite ouverte concernant la réservation de l'événement."}
                            <br/>
                            {"• La conversation porte généralement sur l'échange d'informations pour assurer le bon déroulement de l'événement."}
                            <br/>
                        </>}
                    />
                    <Button
                        className="m-3"
                        variant="contained"
                        disabled={process}
                        onClick={() => navigate('/faq')}
                        endIcon={<ChevronRightIcon/>}>
                        Voir plus
                    </Button>
                </CardContent>
            </Box>
        </div>
    );
}

export default ContactAmountInfos;
