import React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
// import Flicking from "@egjs/react-flicking";
// import "@egjs/react-flicking/dist/flicking.css";

const SkeletonPage = () => {

    return (
        <div className="content mb-5">
            <Box sx={{mt: 3}}>
                {/* Skeleton sliders pour les catégories */}
                {["ARTIST / MUSICIEN", "DJ", "AUTRES"].map((category, idx) => (
                    <Box key={idx} sx={{mb: 2}}>
                        {/* Titre de la catégorie */}
                        <Skeleton variant="text" width="20%" animation="wave" sx={{mb: 3}}/>

                    </Box>
                ))}
            </Box>
        </div>
    );
};

export default SkeletonPage;
