import Avatar from '@mui/material/Avatar';
import makeStyles from '@mui/styles/makeStyles';
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import React from 'react';

const useStyles = makeStyles(() => ({
    root: {
        position: "relative",
        cursor: "pointer",
        overflow: "hidden",
        borderRadius: "0.8rem",
        "&:before": {
            content: '""',
            position: "absolute",
            width: "100%",
            height: "100%",
            background: "linear-gradient(180deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3))",
            zIndex: 0,
        },
    },
    cover: {
        width: "100%",
        height: 120,
        borderRadius: "0.8rem",
    },
    content: {
        position: "relative",
        zIndex: 1,
        background: "linear-gradient(180deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.4))",
        padding: "12px",
        borderRadius: "0.8rem",
    },
    avatar: {
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        color: "#fff",
        width: 40,
        height: 40,
        fontSize: "1.5rem",
        borderRadius: "0.8rem",
    },
    textPrimary: {
        fontSize: "1rem",
        fontWeight: 500,
        marginBottom: "4px",
    },
    textSecondary: {
        fontSize: "0.75rem",
        color: "#888",
    },
}));

function SkeletonServiceCard({index}) {

    const styles = useStyles();

    return (
        <Box
            key={index}
            className="flicking-panel"
            sx={{
                width: 180,
                p: 1,
                flexShrink: 0,
            }}
        >
            <Box className={styles.root}>
                <Box className={styles.content}>
                    <Avatar className={styles.avatar}>
                        <Skeleton variant="circular" width={40} height={40}/>
                    </Avatar>
                    <Box sx={{mt: 1}}>
                        <Skeleton
                            variant="text"
                            width="70%"
                            className={styles.textPrimary}
                            animation="wave"
                        />
                        <Skeleton
                            variant="text"
                            width="50%"
                            className={styles.textSecondary}
                            animation="wave"
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default SkeletonServiceCard;
