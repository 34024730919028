import CardHeader from "@mui/material/CardHeader";
import Skeleton from "@mui/material/Skeleton";
import React from "react";

function SkeletonContactCardHeader() {

    return (
        <CardHeader
            style={{width: "100%", marginTop: 2}}
            avatar={
                <div className="text-lg-center">
                    <div className="s-24">
                        <Skeleton animation="wave" width="30px" height={10}/>
                    </div>
                    <span>
                        <Skeleton animation="wave" width="40px" height={30}/>
                    </span>
                </div>
            }
            action={(<Skeleton animation="wave" width="50px" height={15}/>)}
            title={
                (<Skeleton
                    sx={{marginLeft: "15px !important"}}
                    animation="wave"
                    height={25}
                    width="20%"
                />)
            }
            subheader={
                (<Skeleton
                        animation="wave"
                        height={15}
                        width="35%"
                        sx={{marginBottom: 1, marginLeft: "15px !important"}}
                    />
                )
            }
        />
    );
}

export default SkeletonContactCardHeader;
