import React, {useState, useRef, useEffect} from 'react';
import {Box, Typography, Tooltip, IconButton} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {useSelector} from "react-redux";
import dayjs from 'dayjs';
import Timer from "../../../tools/Countdown/timer";
import {checkTimer} from "../../../tools/Tools";

function EventBanner() {

    const [time, setTime] = useState({days: 0, hours: 0, minutes: 0, seconds: 0});
    const [expanded, setExpanded] = useState(false);
    const [title, setTitle] = useState(""); // État pour le titre
    const [tooltip, setTooltip] = useState(""); // État pour le tooltip
    const contentRef = useRef(null);
    const {contactToChat, currenciesChat} = useSelector(state => state.global);
    const updateInfo = contactToChat?.reservation?.last_user_updated;
    const userId = useSelector((state) => state.profile.userId);
    const isArtist = parseInt(userId) === parseInt(contactToChat?.artist_id);
    const reservationStatus = contactToChat?.reservation?.status;
    const isPaid = reservationStatus === 'paid';

    const toggleExpand = () => {
        setExpanded(!expanded);
    };

    const isProposition = () => {
        if (updateInfo?.object !== 'proposition')
            return null;

        const isSameUser = parseInt(updateInfo?.user_id) === parseInt(userId);
        const userType = !isArtist ? "l'artiste" : "l'auditeur";
        return isSameUser ? "- Ma proposition" : `- Proposition de ${userType}`;
    };

    const getContractAction = () => {

        let text, tooltip
        const isPending = reservationStatus === 'pending';
        const isAccepted = reservationStatus === 'accepted';
        const isPropositionExist = updateInfo?.object === 'proposition'
        const userHasUpdated = parseInt(updateInfo?.user_id) === parseInt(userId)

        switch (true) {
            case isPending && !isPropositionExist && !isArtist:
                return {
                    text: "En attente de signature de la part de l'artiste",
                    tooltip: "L'artiste examine votre événement. Elle signera bientôt, sauf si une nouvelle proposition est faite."
                };
            case isPending && isPropositionExist && !userHasUpdated:
                text = "Proposition reçu"
                tooltip = `${isArtist ? "L'auditeur" : "L'artiste"} a proposé un nouveau montant pour l'événement.`
                return {text, tooltip};
            case isPending && isPropositionExist && userHasUpdated:
                text = "Proposition envoyée"
                tooltip = `Vous avez soumis une nouvelle proposition à ${isArtist ? "l'auditeur" : "l'artiste"}.`
                return {text, tooltip};
            case isAccepted && isArtist:
                return {
                    text: "En attente de signature de la part de l'auditeur",
                    tooltip: "Vous avez déjà signé le contrat, la signature de l'auditeur est attendue."
                }

            default:
                return {
                    text: "Signer le contrat",
                    tooltip: "Si votre analyse de l'événement est terminée, veuillez finaliser en signant le contrat via le bouton \"Signer\" en haut."
                };
        }
    };


    useEffect(() => {
        if (isPaid) {
            checkTimer(contactToChat, setTime);
        }
        const {text, tooltip} = getContractAction();
        setTitle(text);
        setTooltip(tooltip);
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [contactToChat, updateInfo, isArtist, userId]); // Dépendances nécessaires

    return (
        <Box
            sx={{
                position: 'relative',
                backgroundColor: '#eeeeee',
                color: '#424242',
                padding: '10px 20px 15px 20px',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderBottomLeftRadius: '10px',
                borderBottomRightRadius: '10px',
                overflow: 'hidden',
            }}
        >
            {/* Section principale */}
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                // marginBottom: '15px',
            }}>
                <Typography variant="subtitle1" sx={{fontWeight: 'bold', fontSize: '0.7rem'}}>
                    {isPaid ? <Timer label="Événement dans" timerData={time}/> : title}
                </Typography>
                <Box sx={{display: 'flex', alignItems: 'center', gap: 0.5}}>
                    <Tooltip title={tooltip} arrow>
                        {/* Utilisation de l'état `tooltip` */}
                        <IconButton size="small">
                            <InfoIcon fontSize="small"/>
                        </IconButton>
                    </Tooltip>
                    <IconButton size="small" sx={{color: '#424242'}} onClick={toggleExpand}>
                        {expanded ? <ExpandLessIcon fontSize="small"/> : <ExpandMoreIcon fontSize="small"/>}
                    </IconButton>
                </Box>
            </Box>

            {/* Section expandée */}
            <Box
                ref={contentRef}
                sx={{
                    width: 'calc(100% - 20px)',
                    color: '#333333',
                    overflow: 'auto',
                    position: 'relative',
                    backgroundColor: '#f5f5f5',
                    maxHeight: expanded ? '400px' : 0,
                    boxShadow: '0px -4px 6px rgba(0, 0, 0, 0.1)',
                    borderRadius: '10px',
                    transition: 'max-height 0.4s ease, padding 0.4s ease, margin-top 0.4s ease',
                    padding: expanded ? '15px' : '0px',
                    marginLeft: expanded ? '10px' : '0px',
                    marginRight: expanded ? '10px' : '0px',
                    boxSizing: 'content-box',
                }}
            >
                <Typography
                    variant="subtitle2"
                    sx={{marginBottom: '10px', fontSize: '0.65rem', fontWeight: 'bold'}}
                >
                    Détails de l'évenemement
                </Typography>

                <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                    {/* Event */}
                    <Box>
                        <Typography variant="body2" sx={{color: '#424242', fontWeight: 'bold', fontSize: '0.7rem'}}>
                            {isArtist ? "L'auditeur qui reserve" : "L'artiste invité"}
                        </Typography>
                        <Typography variant="body2" sx={{color: '#757575'}}>
                            {isArtist
                                ? contactToChat?.lastname + " " + contactToChat?.name
                                : contactToChat?.reservation?.artist_name}
                        </Typography>
                    </Box>

                    {/* Event */}
                    <Box>
                        <Typography variant="body2" sx={{color: '#424242', fontWeight: 'bold', fontSize: '0.7rem'}}>
                            Événement organisé
                        </Typography>
                        <Typography variant="body2" sx={{color: '#757575'}}>
                            {contactToChat?.reservation?.event || 'Non spécifiée'}
                        </Typography>
                    </Box>

                    {/* Adresse */}
                    <Box>
                        <Typography variant="body2" sx={{color: '#424242', fontWeight: 'bold', fontSize: '0.7rem'}}>
                            Adresse de l'Événement
                        </Typography>
                        <Typography variant="body2" sx={{color: '#757575'}}>
                            {contactToChat?.reservation?.address || 'Non spécifiée'}
                        </Typography>
                    </Box>

                    {/* Durée */}
                    <Box>
                        <Typography variant="body2" sx={{color: '#424242', fontWeight: 'bold', fontSize: '0.7rem'}}>
                            Durée minimale de la prestation
                        </Typography>
                        <Typography variant="body2" sx={{color: '#757575'}}>
                            {contactToChat?.reservation?.duration || 'Non spécifiée'}
                        </Typography>
                    </Box>

                    {/* Montant */}
                    <Box>
                        <Typography variant="body2" sx={{color: '#424242', fontWeight: 'bold', fontSize: '0.7rem'}}>
                            Cachet de la prestation
                        </Typography>
                        <Typography variant="body2" sx={{color: '#757575'}}>
                            {contactToChat?.reservation?.total_amount + currenciesChat?.symbol || 'Non spécifiée'}
                            &nbsp;
                            <span style={{color: '#bdbdbd'}}>{isProposition()}</span>
                        </Typography>
                    </Box>

                    {/* Date de l'événement */}
                    <Box>
                        <Typography variant="body2" sx={{color: '#424242', fontWeight: 'bold', fontSize: '0.7rem'}}>
                            Date de l'Événement
                        </Typography>
                        <Typography variant="body2" sx={{color: '#757575'}}>
                            {contactToChat?.reservation?.event_date
                                ? dayjs(contactToChat.reservation.event_date).format('DD MMMM YYYY à HH:mm')
                                : 'Non spécifiée'}
                        </Typography>
                    </Box>

                    {/* Dernière mise à jour */}
                    <Box>
                        <Typography variant="body2" sx={{color: '#424242', fontWeight: 'bold', fontSize: '0.7rem'}}>
                            Dernière mise à jour
                        </Typography>
                        <Typography variant="body2" sx={{color: '#757575'}}>
                            {contactToChat?.reservation?.modified_at
                                ? dayjs(contactToChat.reservation.modified_at).format('DD MMMM YYYY à HH:mm')
                                : 'Non spécifiée'}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default EventBanner;
