import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import ServiceList from "../services/ServiceList";

function KantoBizCustomCards(props) {

    const navigate = useNavigate();
    const auth = useSelector(state => state.session.authenticated);
    const role = useSelector(state => state.profile.role);
    const isLogout = [null, ''].includes(role);
    const isAuditor = isLogout || role === "professional_auditor";

    useEffect(() => {

        isAuditor && !auth && navigate('/')

    }, [isAuditor, auth, navigate])

    return (
        <div className="content mb-5">
            <ServiceList />
        </div>
    )
}

export default KantoBizCustomCards;
