import React, {useState, useEffect} from 'react';
import {Elements} from "@stripe/react-stripe-js";
import {useSelector} from "react-redux";
import {loadStripe} from "@stripe/stripe-js";
import {useNavigate} from "react-router-dom";
import {Box} from '@mui/material';
import settings from "../../config/tsconfig.json";
import Transfers from "../profile/Banks/Transfers";
import {WalletBalance} from "../profile/Banks/WalletBalance";
import SkeletonRectangular from "../skeletons/SkeletonRectangular";

const Wallet = () => {

    const navigate = useNavigate();
    const isDev = useSelector(state => state.global.isDev);
    const [loading, setLoading] = useState(true);
    const [stripePromise, setStripPromise] = useState();
    const role = useSelector(state => state.profile.role);
    const auth = useSelector(state => state.session.authenticated);
    const isLogout = [null, ''].includes(role);
    const isAuditor = isLogout || role === "professional_auditor";

    const toLoadStripePromise = async () => {
        setStripPromise(loadStripe(isDev ? settings.configs.StripeKeyDev : settings.configs.StripeKeyProd));
    }

    useEffect(() => {

        isAuditor && !auth && navigate('/')
        !isLogout && toLoadStripePromise().then(() => setLoading(false))

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [isAuditor, auth, navigate, role])


    return (
        <div className="content">
            {loading
                ? <div className="card-content">
                    <div className="card-body cleartfix">
                        <SkeletonRectangular width="100%" height={61}/>
                    </div>
                </div>
                : <Elements stripe={stripePromise}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100vh',
                            paddingY: 2,
                        }}
                    >
                        <WalletBalance/>

                        <Transfers/>

                    </Box>
                </Elements>}
        </div>
    )

};

export default Wallet;
