import SearchIcon from '@mui/icons-material/Search';
import {IconButton, Typography} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import React, {useEffect, useRef, useState} from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import TuneIcon from '@mui/icons-material/Tune';
import {useNavigate} from "react-router-dom";
import Box from '@mui/material/Box';
import Notify from "../../FixedPlugin/Notify";
import Account from "../Account";
import {
    addArtistMusicianServicesData,
    addDjServicesData,
    addOthersServicesData,
    addSearchResults,
    changeLoadingResult,
} from "../../../reducer";
import {getPathFromUrl, useWindowSize} from "../../../tools/Tools";
import ArtistSearch from "./ArtistSearch";
import ServiceSearch from "./ServiceSearch";


function SearchBar() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isMounted = useRef(false);
    const width = useWindowSize();
    const [opened, setOpened] = useState(false);
    const [loading, setLoading] = useState(false);
    const [thematicsList, setThematicsList] = useState([]);
    const auditor = useSelector(state => state.axios.auditor);
    const searchBarData = useSelector(state => state.searchBarData);
    const serviceResults = useSelector(state => state.serviceResults);
    const countryLog = useSelector(state => state.global.countryLog);
    const artistTypes = useSelector(state => state.global.artistTypes);
    const countryLogID = useSelector(state => state.global.countryLogID);
    const auth = useSelector(state => state.session.authenticated);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const loadingResult = useSelector(state => state.serviceResults.loadingResult);


    const dispatchResult = async (results) => {

        let _dj = []
        let _others = []
        let _artistMusician = []

        for (let row in results) {
            if (results[row]['artist_type'] === 'artist_musician') {
                _artistMusician.push(results[row])
            } else if (results[row]['artist_type'] === 'dj') {
                _dj.push(results[row])
            } else {
                _others.push(results[row])
            }
        }

        await dispatch(addDjServicesData(_dj))
        await dispatch(addOthersServicesData(_others))
        await dispatch(addArtistMusicianServicesData(_artistMusician))
    }

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const closeFilter = () => {
        setAnchorEl(null);
    };

    const searchServices = async (isRefresh) => {

        let _thematicList = searchBarData.thematics
        if (_thematicList?.length) {
            let tmp = []
            /* eslint-disable-next-line array-callback-return */
            _thematicList?.map((val) => {
                /* eslint-disable-next-line array-callback-return */
                artistTypes?.map((data) => {
                    if (data.label === val) {
                        tmp.push(data.value)
                    }
                })
            })
            _thematicList = tmp
        }

        await setLoading(true);
        !loadingResult && await dispatch(changeLoadingResult(true))

        let query = {
            "country": countryLog,
            "city": searchBarData.city,
            "event_date": searchBarData.event_date.toISOString(),
            "event": searchBarData.event,
            "thematics": _thematicList
        }

        await auditor.post("service_search/moment", query).then(async resp => {
            const isOpen = open
            !isRefresh && closeFilter();
            let data = resp.data.filter((_service) => !_service.test)
            if (resp.data?.length) {
                await dispatchResult(data);
                await dispatch(addSearchResults(data))
            } else {
                toast.error("Aucun fiche KantoBiz trouvé")
            }
            await dispatch(changeLoadingResult(false))
            setLoading(false);

            const pathname = getPathFromUrl(window.location.href);
            if (pathname !== "/" && isOpen) {
                navigate("/")
            }

        }).catch(async err => {
            await dispatch(changeLoadingResult(!serviceResults.loadingResult))
            await dispatch(changeLoadingResult(false))
            setLoading(false);
            toast.error("Erreur, veuillez recommencer.")
        })
    }

    useEffect(() => {
        if (countryLogID) {
            let t = [];
            /* eslint-disable-next-line array-callback-return */
            artistTypes?.map((data) => {t.push(data.label)})
            setThematicsList(t)
            searchServices().then(r => null)
        }
        return () => {
            isMounted.current = true
        };
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [artistTypes, countryLogID]);

    useEffect(() => {

        return () => {
            isMounted.current = true
        };
    }, []);

    return (
        <div>
            <div className="ml-auto mr-auto">

                <Box sx={{display: 'flex', alignItems: 'center', width: 'fit-content'}}>
                    {/* Search Button */}
                    <Tooltip title="Recherche..." placement="bottom">
                        {width < 460 ? (
                            <IconButton
                                sx={{color: '#1976d2', mx: 0.5}}
                                onClick={(e) => !loading && setOpened(true)}>
                                <SearchIcon/>
                            </IconButton>
                        ) : (
                            <Box
                                onClick={() => setOpened(true)}
                                sx={{
                                    display: 'flex',
                                    borderColor: '#AAA',
                                    alignItems: 'center',
                                    backgroundColor: '#f5f5f5', // Light background color
                                    borderRadius: 5,
                                    padding: '4px 12px',
                                    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)', // Subtle shadow
                                    cursor: 'pointer',
                                }}
                            >
                                {/* Search Icon */}
                                <IconButton
                                    sx={{p: 0, mr: 1, color: '#1976d2'}} // Blue color for search icon
                                    aria-label="search"
                                >
                                    <SearchIcon/>
                                </IconButton>

                                {/* Search Placeholder Text */}
                                <Typography variant="body2" color="text.secondary" sx={{flex: 1}}>
                                    Recherche...
                                </Typography>

                                {/* Shortcut Key Indicator */}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: '#e0e0e0', // Light grey background for shortcut
                                        borderRadius: 1,
                                        padding: '2px 6px',
                                        ml: 1,
                                    }}
                                >
                                    <Typography
                                        variant="caption"
                                        sx={{fontSize: 12, color: 'text.secondary'}}>
                                        ⌘K
                                    </Typography>
                                </Box>
                            </Box>
                        )}
                    </Tooltip>

                    {/* Settings Icon Button */}
                    <Tooltip title="Trouver une fiche kantobiz" placement="bottom">
                        <IconButton
                            sx={{color: '#1976d2', mx: 0.5}}
                            onClick={(e) => !loading && handleClick(e)}>
                            <TuneIcon/>
                        </IconButton>
                    </Tooltip>

                    {auth && <Notify/>}

                    {!auth ? null : <Account/>}

                </Box>
            </div>

            <Modal size="md" show={opened}>
                <ArtistSearch setOpened={setOpened} setLoading={setLoading}/>
            </Modal>

            <Modal size="md" show={open}>
                <ServiceSearch
                    loading={loading}
                    searchServices={searchServices}
                    thematicsList={thematicsList}
                    closeFilter={closeFilter}/>
            </Modal>

        </div>
    );
}

export default SearchBar;
