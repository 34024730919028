import Badge from "@mui/material/Badge";
import {useTheme} from "@mui/material/styles";
import React, {useEffect, useState} from 'react';
import {
    Box,
    Typography,
    Tooltip,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton
} from '@mui/material';
import Modal from "react-bootstrap/Modal";
import {useSelector} from 'react-redux';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DateCalendar} from '@mui/x-date-pickers';
import moment from 'moment';
import 'moment/locale/fr';
import CustomPickerDay from "../../tools/customedPickerDay";
import {useWindowSize} from "../../tools/Tools";
import ContactCard from "../contactCards/ContactCard";
import CalendarSkeleton from "../skeletons/CalendarSkeleton";
import CalendarLegend from "./CalendarLegend";

moment.locale('fr');

function CalendarView({openToChat}) {

    const theme = useTheme();
    const width = useWindowSize();
    const {userId, contacts} = useSelector(state => state.profile);
    const [selectedDate, setSelectedDate] = useState(null);
    const [events, setEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedDayEvents, setSelectedDayEvents] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [showBook, setShowBook] = useState({});
    const [toShow, setToShow] = useState(false);

    const handleDayClick = (date) => {
        const eventsForDay = events.filter(event => event.date.isSame(date, 'day'));
        setSelectedDate(date);
        setSelectedDayEvents(eventsForDay);
        setShowDialog(true);
    };

    const handleYearChange = () => setShowDialog(false);
    const handleMonthChange = () => setShowDialog(false);

    // Group all events and format them with moment date and status
    const insertEventsList = () => {
        const groupedEvents = [];

        Object.keys(contacts)?.forEach(type => {
            ['accepted', 'pending']?.forEach(status => {
                contacts[type][status]?.forEach(event => {
                    groupedEvents.push({
                        ...event,
                        date: moment(event.reservation.event_date),
                        contact_status: event.status,
                        event_status: event.reservation.status,
                    });
                });
            });
        });

        setEvents(groupedEvents);
        setIsLoading(false)
    };

    const addReservationToShow = async (book) => {
        setShowBook(book);
        setToShow(true);
    };

    function CustomDay(props) {
        const {day, outsideCurrentMonth, ...other} = props;
        const eventsForDay = events.filter(event => event.date.isSame(day, 'day'));
        const hasPending = eventsForDay.some(event => event.status === 'pending');
        const hasAcceptedOrPaid = eventsForDay.some(event => event.status === 'accepted' || event.status === 'paid');

        return (
            <Tooltip
                placement="top"
                title={eventsForDay.length > 0 ? "Cette date contient des événements" : ""}
            >
                <Box position="relative" display="inline-flex">
                    {hasPending && (
                        <Badge
                            overlap="circular"
                            variant="dot"
                            sx={{
                                position: 'absolute',
                                top: 5,
                                right: 7,
                                '& .MuiBadge-dot': {
                                    backgroundColor: theme.palette.info.light,
                                },
                            }}
                        />
                    )}
                    {hasAcceptedOrPaid && (
                        <Badge
                            overlap="circular"
                            variant="dot"
                            sx={{
                                position: 'absolute',
                                top: 5,
                                right: 16,
                                '& .MuiBadge-dot': {
                                    backgroundColor: theme.palette.success.light,
                                },
                            }}
                        />
                    )}
                    <CustomPickerDay
                        {...other}
                        outsideCurrentMonth={outsideCurrentMonth}
                        day={day}
                        sx={{
                            width: {
                                xs: '40px',  // Très petit écran
                                sm: '60px',  // Petit écran
                                md: '80px',  // Écran moyen
                                lg: '105px', // Grand écran
                            },
                            '&.Mui-selected': {
                                backgroundColor: '#bbd899',
                                color: '#FFF',
                            },
                        }}
                    />
                </Box>
            </Tooltip>
        );
    }

    useEffect(() => {
        if (!events.length && userId) {
            insertEventsList();
        } else if (isLoading && userId) {
            setIsLoading(false)
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [events.length, isLoading, userId]);


    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>

        {/*<LocalizationProvider dateAdapter={AdapterDateFns}>*/}

            <Modal size="lg" show={toShow}>
                <ContactCard setToShow={setToShow} ContactCardToChat={showBook}/>
            </Modal>


            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                height: '100vh',
                overflowY: 'auto'
            }}>
                <DateCalendar
                    loading={isLoading}
                    value={selectedDate}
                    onChange={handleDayClick}
                    onYearChange={handleYearChange}
                    onMonthChange={handleMonthChange}
                    renderLoading={() => <CalendarSkeleton/>}
                    slots={{
                        day: CustomDay,
                    }}
                    sx={{
                        width: '100%',
                        overflowY: 'auto',
                        backgroundColor: '#f0f0f0',
                        border: '1px solid #ddd',
                        borderRadius: '8px',
                        '& .MuiDayPicker-weekContainer': {
                            display: 'flex',
                            justifyContent: 'space-around',
                        },
                        '& .MuiPickersCalendarHeader-root': {
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                            px: 2, // Padding horizontal pour aligner les éléments
                        },
                        '& .MuiTypography-caption': {
                            my: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: {
                                xs: '40px',  // Très petit écran
                                sm: '60px',  // Petit écran
                                md: '80px',  // Écran moyen
                                lg: '105px', // Grand écran
                            },
                            height: '40px',  // Hauteur pour centrer verticalement, ajustez selon besoin
                            fontSize: '0.875rem', // Taille du texte adaptative si besoin
                        },
                        '& .MuiPickersCalendarHeader-labelContainer': {
                            paddingLeft: {
                                xs: '0px',  // Très petit écran
                                sm: '16px',  // Petit écran
                                md: '26px',  // Écran moyen
                                lg: '36px' // Grand écran
                            },
                            paddingRight: {
                                xs: '0px',  // Très petit écran
                                sm: '16px',  // Petit écran
                                md: '26px',  // Écran moyen
                                lg: '36px' // Grand écran
                            },
                        },
                    }}
                />

                <Dialog open={showDialog} onClose={() => setShowDialog(false)} maxWidth="sm" fullWidth>
                    <DialogTitle>
                        {selectedDate ? moment(selectedDate).format('DD MMMM YYYY') : ''}
                        {width < 500 ? "" : " - Événements"}
                    </DialogTitle>
                    <DialogContent>
                        {selectedDayEvents.length > 0 ? (
                            selectedDayEvents.map((dayEvent, index) => (
                                <Tooltip title="Cliquer pour afficher la discussion" placement="top" key={'y' + index}>
                                    <IconButton
                                        key={"first_event_btn"}
                                        className="width-full border-bottom"
                                        sx={{
                                            backgroundColor: dayEvent?.contact_status === 'accepted'
                                                ? '#9bdf9e'
                                                : '#aaacaa',
                                            padding: '4px'
                                        }}
                                        onClick={() => {
                                            setShowDialog(false)
                                            dayEvent?.contact_status === 'accepted'
                                                ? openToChat(dayEvent)
                                                : addReservationToShow(dayEvent)
                                        }}>
                                        <Box display="flex" flexDirection="column" width="100%">
                                            <Typography
                                                variant="h6"
                                                sx={{ color: "white", marginBottom: "4px" }}
                                            >
                                                {dayEvent?.reservation?.event}
                                            </Typography>
                                            <Typography variant="subtitle2">
                                                {dayEvent?.reservation?.address}
                                            </Typography>
                                        </Box>
                                    </IconButton>
                                </Tooltip>
                            ))
                        ) : (
                            <Typography variant="body1">Pas d'événements pour cette journée.</Typography>
                        )}
                    </DialogContent>
                </Dialog>

                {/* Legend */}
                <CalendarLegend/>
            </Box>
        </LocalizationProvider>
    );
}

export default CalendarView;
