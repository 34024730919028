import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import {Box, Typography} from "@mui/material";
import Badge from "@mui/material/Badge";
import React from 'react';
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import TextsmsIcon from "@mui/icons-material/Textsms";
import VerifiedIcon from "@mui/icons-material/Verified";
import ScheduleIcon from '@mui/icons-material/Schedule';
import Tooltip from "@mui/material/Tooltip";
import dayjs from "dayjs";
import {useDispatch, useSelector} from "react-redux";
import {Row} from "reactstrap";
import {addContactCardToChat} from "../../../tools/Tools";

function DiscussCard({book}) {

    const dispatch = useDispatch();
    const {msgTabIndex, countries} = useSelector(state => state.global);
    const styles = {
        growButton: {
            transition: 'transform 0.2s',
            '&:hover': {
                transform: 'scale(1.1)',
            },
        },
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            gap={1}
            key={book?.id + '_chat_msg'}
            className="border-bottom px-4 py-2"
        >
            <Row className="justify-content-center">
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    className="col-2"
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Avatar src={msgTabIndex === 'auditor'
                            ? book?.reservation?.auditor_photo
                            : book?.reservation?.artist_photo}/>
                    </Box>
                </Box>
                {/* eslint-disable no-useless-concat */}
                <div className={"col-8"}>
                    <Box>
                        <Typography
                            variant="h6"
                            sx={{
                                fontSize: '14px',
                                fontWeight: 'bold',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            {msgTabIndex === 'auditor'
                                ? book?.lastname + ' ' + book?.name
                                : book?.reservation?.artist_name}
                            {msgTabIndex === 'book' && book?.is_certified && (
                                <Tooltip
                                    style={{ cursor: 'pointer' }}
                                    title="Profil vérifié"
                                    placement="top"
                                >
                                    <VerifiedIcon
                                        sx={{
                                            marginLeft: 0.5,
                                            marginBottom: 0.5,
                                            color: '#1976d2 !important',
                                            fontSize: '12px',
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            sx={{ fontSize: '12px', color: 'gray' }}
                        >
                            {book?.reservation?.event}
                            {msgTabIndex === 'book' && (
                                <small>
                                    &nbsp;-&nbsp;
                                    <strong>
                                        {book?.lastname + ' ' + book?.name}
                                    </strong>
                                </small>
                            )}
                        </Typography>
                        <Typography
                            variant="caption"
                            sx={{ fontSize: '10px', color: 'darkgray' }}
                        >
                            Le&nbsp;{dayjs(book?.reservation?.event_date).format("DD-MM-YYYY à HH:mm A")}
                        </Typography>
                    </Box>
                </div>
                <Box
                    minWidth={30}
                    textAlign="right"
                    display="flex"
                    justifyContent="flex-end"
                    className="col-2"
                >
                    <Tooltip title="Ouvrir la discussion" placement="bottom">
                        <IconButton
                            sx={styles.growButton}
                            onClick={() => addContactCardToChat(dispatch, book, countries)}
                        >
                            <Badge
                                color="transparent"
                                badgeContent={
                                    book?.reservation?.status === 'paid'
                                        ? <EventAvailableIcon fontSize='small' sx={{color: '#ce93d8!important'}}/>
                                        : <ScheduleIcon fontSize='small' sx={{color: '#757575!important'}}/>
                                }
                                invisible={['pending'].includes(book?.reservation?.status)}>
                                <TextsmsIcon sx={{
                                    color: ['accepted', 'paid'].includes(book?.reservation?.status)
                                        ? '#9bdf9e !important'
                                        : 'gray !important'
                                }}/>
                            </Badge>
                        </IconButton>
                    </Tooltip>
                </Box>
            </Row>
        </Box>
    )
}

export default DiscussCard;
