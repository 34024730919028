import {Typography} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import toast from "react-hot-toast";
import ReactInputVerificationCode from "react-input-verification-code";
import {useSelector, useDispatch} from "react-redux";
import {Spinner} from "reactstrap";
import {createSession} from "../../tools/Tools";

function Validation(props) {

    const dispatch = useDispatch()
    const isMounted = useRef(false);
    const {email, closeModal, forgotValidate, left} = props
    const [loading, setLoading] = useState(false);
    const auditor = useSelector(state => state.axios.auditor);
    const ipAddress = useSelector(state => state.global.ipAddress);
    const credentials = useSelector(state => state.registerCredentials.credentials);

    const validate = async (keys) => {
        await setLoading(true)
        await auditor.post("users/get_if_keys_validate", {email, keys}).then(async (resp) => {
            toast.success('Code validé')
            if (credentials && !forgotValidate) {
                await createSession(credentials, dispatch, ipAddress, setLoading, closeModal, true)
            }
            setLoading(false)
            closeModal()
        }).catch(error => {
            setLoading(false)
            toast.error("Code invalide");
        })
    }

    useEffect(() => {

        return () => {
            isMounted.current = true
        };
    }, []);

    return (
        <div className="m-4">
            <div
                className={`d-flex flex-column ${
                    left ? "align-items-start" : "align-items-center"
                }`}
            >
                <div className="custom-styles w-100">
                    <div className={left ? "text-left" : "text-center"}>
                        <h5>Code de validation</h5>
                    </div>
                    <ReactInputVerificationCode
                        autoFocus
                        length={6}
                        placeholder=""
                        onChange={(e) => e.length === 6 && validate(e)}
                    />
                </div>

                <div className={`mt-3 ${left ? "text-left w-100" : "text-center"}`}>
                    <Typography variant="caption">
                        Un code vous a été envoyé par émail
                    </Typography>
                </div>

                {loading && (
                    <div
                        className={`d-flex mt-4 ${
                            left ? "justify-content-start" : "justify-content-center"
                        } align-items-center`}
                    >
                        <Spinner size="sm" color="lightGreen" className="ml-2" />
                    </div>
                )}
            </div>
        </div>
    );
}

export default Validation;
