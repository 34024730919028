import EditIcon from "@mui/icons-material/Edit";
import {IconButton, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import React, {useState, useEffect} from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import apis from "../../../config/apis";
import {addProfileBanking} from "../../../reducer";
import {
    checkErrorMessage,
    deleteInObject,
    deleteNullInObj,
    validatorBanking
} from "../../../tools/Tools";
import {useStripe} from "@stripe/react-stripe-js";
import Box from "@mui/material/Box";
import AddBankAccount from "./AddBankAccount";

function BankAccount() {

    const stripe = useStripe();
    const dispatch = useDispatch();
    const auditor = useSelector(state => state.axios.auditor);
    const [up, setUp] = useState(false);
    const [add, setAdd] = useState(false);
    const [loading, setLoading] = useState(false);
    const {countryCode, globalCurrencyCode, countryLog} = useSelector(state => state.global);
    const {currencies_banking_code, banking, profileData} = useSelector(state => state.profile);

    const dispatchFunc = (func) => {
        let validate = validatorBanking(banking, countryCode, countryLog);
        if (validate['error']) {
            toast.error(validate.message);
        } else {
            setLoading(true)
            if (func === "add")
                addAccount().then(r => null);
            else editAccount()
        }
    };

    const addAccount = async () => {
        await auditor.get(apis.getStripeDocumentsId).then(async (resp) => {
            const newAccount = await stripe.createToken('account', {
                business_type: 'individual',
                individual: {
                    last_name: "MAMPIONONA",
                    first_name: "Cynthion Mahavonjy",
                    gender: "male",
                    email: "mahavonjy.cynthion@gmail.com",
                    phone: "+33652359897",
                    dob: {
                        day: 12,
                        month: 3,
                        year: 1998
                    },
                    address: {
                        city: "Moissy Cramayel",
                        line1: "15 chemin des charmilles",
                        postal_code: "77550",
                    },
                },
                tos_shown_and_accepted: true,
            });

            const newAccountVerification = await stripe.createToken('account', {
                individual: {
                    verification: {
                        additional_document: {front: resp.data['att_id']},
                        document: {front: resp.data['pass_id']},
                    },
                }
            });

            let stripe_token = [newAccount.token.id, newAccountVerification.token.id]
            const {name, lastname, iban, swift} = banking
            let createBank = {
                name,
                lastname,
                iban,
                swift,
                stripe_token,
                country: countryLog,
                email: profileData.email,
                country_code: countryCode,
                currency: globalCurrencyCode
            };

            auditor.post(apis.addBank, deleteNullInObj(createBank)).then((resp) => {
                setAdd(false)
                setLoading(false)
                dispatch(addProfileBanking(resp.data));
                toast.success("Votre compte a été bien ajouté")
            }).catch((error) => {
                setLoading(false)
                let errorMessage = checkErrorMessage(error).message;
                toast.error(errorMessage)
            })
        })
    }

    const editAccount = () => {
        let tmpBanking = {...banking};
        tmpBanking = deleteInObject(tmpBanking);
        tmpBanking['stripe_token'] = ['no_token']
        tmpBanking['country_code'] = countryCode
        auditor.put(apis.upBank, deleteNullInObj(tmpBanking)).then((resp) => {
            setUp(false)
            setLoading(false)
            dispatch(addProfileBanking(resp.data));
            toast.success("Votre compte a été bien mis a jour")
        }).catch((error) => {
            setLoading(false)
            let errorMessage = checkErrorMessage(error);
            toast.error(errorMessage.message)
        })
    }

    useEffect(() => {
        //
    }, [])

    return (
        <div>
            <Modal size="md" show={add || up}>
                <AddBankAccount
                    add={add}
                    setAdd={setAdd}
                    setUp={setUp}
                    loading={loading}
                    dispatchFunc={dispatchFunc}/>
            </Modal>

            {!Object.keys(banking)?.length
                ? (
                    <Button
                        variant="outlined"
                        color="primary"
                        disabled={!currencies_banking_code?.includes(globalCurrencyCode)}
                        onClick={() => setAdd(true)}
                        sx={{
                            textTransform: 'none',
                            height: 35,
                            fontSize: '0.75rem',
                            paddingX: 2,
                        }}
                    >
                        Ajouter un compte
                    </Button>
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            backgroundColor: '#424242', // Fond sombre
                            padding: .5,
                            borderRadius: 1,
                            color: 'white', // Couleur de texte pour contraste
                            border: '1px solid #616161', // Légère bordure pour un effet de profondeur
                        }}
                    >
                        <Typography
                            variant="body2"
                            sx={{fontWeight: 'bold', fontSize: '0.75rem', color: 'white'}}
                        >
                            {banking?.iban?.length >= 6 && Array.from(Array(banking.iban.length - 4).keys()).map(() => "*")}
                            {banking?.iban?.length >= 5 && banking.iban.slice(-4)}
                        </Typography>
                        <IconButton
                            color="primary"
                            size="small"
                            onClick={() => setUp(true)}
                            sx={{
                                marginLeft: 1,
                                color: '#90caf9', // Couleur bleue lumineuse pour l'icône
                                '&:hover': {
                                    color: '#64b5f6', // Une nuance plus claire au survol
                                },
                            }}
                        >
                            <EditIcon fontSize="small" sx={{color: "white!important"}}/>
                        </IconButton>
                    </Box>
                ) }
        </div>
    )
}

export default BankAccount;
