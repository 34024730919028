import DoneAllIcon from "@mui/icons-material/DoneAll";
import React, {useState, useEffect} from "react";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {Row} from "reactstrap";
import {IconButton, Typography} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import apis from "../../config/apis";
import {addProfileData} from "../../reducer";
import {checkErrorMessage, deleteNullInObj, fillInBodyForm} from "../../tools/Tools";

function ProfileBiography({checked}) {

    const dispatch = useDispatch();
    const auditorForm = useSelector((state) => state.axios.auditorForm);
    const [biography, setBiography] = useState("");
    const [activeBioEdit, setActiveBioEdit] = useState(false);
    const profileData = useSelector(state =>
        checked ? state.profileChecked.profileData : state.profile.profileData
    );

    const addDescription = async () => {
        const updatedProfile = deleteNullInObj({...profileData});
        updatedProfile.description = biography;
        delete updatedProfile['is_certified'];
        const bodyFormData = fillInBodyForm(new FormData(), updatedProfile);

        try {
            const response = await auditorForm.put(apis.updateProfile, bodyFormData);
            dispatch(addProfileData(response.data));
            toast.success("Biographie mise à jour.");
            setActiveBioEdit(false)
        } catch (error) {
            toast.error(checkErrorMessage(error).message);
        }
    };

    useEffect(() => {
        if (profileData?.description && profileData?.description !== "null") {
            setBiography(profileData.description);
        }
    }, [profileData]);

    return (
        <Row
            className="justify-content-between mb-2 align-items-center ml-2 mr-1"
            style={{display: "flex"}}
        >
            {activeBioEdit ? (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                >
                    <textarea
                        style={{
                            flexGrow: 1,
                            height: "200px",
                            marginRight: "10px",
                            padding: "8px",
                            borderRadius: "8px",
                            border: "1px solid #ccc",
                            resize: "none",
                        }}
                        value={biography}
                        onChange={(e) => setBiography(e.target.value)}
                        placeholder="Ajouter une biographie..."
                    />
                    <IconButton
                        size="small"
                        aria-label="save"
                        style={{marginLeft: "10px", color: "#51cbce"}}
                        onClick={() => addDescription()} // Sauvegarde simulée ici
                    >
                        <DoneAllIcon sx={{color: "lightGreen!important"}}/>
                    </IconButton>
                </div>
            ) : checked ? (
                    <div style={{
                        display: "flex",
                        textAlign: "justify",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                    }}>
                        <Typography
                            variant="caption"
                            sx={{
                                minHeight: '50px',
                                maxHeight: '150px',
                                overflowY: 'auto',
                                padding: '8px',
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                backgroundColor: '#f9f9f9',
                            }}
                        >
                            {!biography
                                ? "L'artiste n'a pas encore de biographie."
                                : biography}
                        </Typography>
                    </div>
                ) : (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                >
                    <a
                        href="#kantobiz_artist_bio"
                        className="mt-2 text-secondary text-justify"
                        style={{
                            flexGrow: 1,
                            textDecoration: "none",
                            color: "#555",
                            fontWeight: 400,
                        }}
                        onClick={() => setActiveBioEdit(true)}
                    >
                        {!biography
                            ? "Ajouter une biographie ..."
                            : biography}
                    </a>
                    <IconButton
                        aria-label="edit"
                        style={{marginLeft: "10px"}}
                        onClick={() => setActiveBioEdit(true)}
                    >
                        <EditIcon fontSize="small"/>
                    </IconButton>
                </div>
            )}
        </Row>
    );
}

export default ProfileBiography;
