import React from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import {Navigation, Autoplay} from "swiper/modules";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function ImgSlider({images}) {

    if (!images || images.length === 0) {
        return (
            <Box
                sx={{
                    height: 200,
                    width: "100%",
                    backgroundColor: "#f0f0f0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Typography variant="body2" color="textSecondary">
                    Aucune image disponible
                </Typography>
            </Box>
        );
    }

    if (images.length === 1) {
        return (
            <Box
                component="img"
                sx={{
                    height: 255, // Hauteur uniforme
                    width: "100%",
                    objectFit: "cover",
                }}
                src={images[0]}
                alt="Unique galerie"
            />
        );
    }

    return (
        <Box
            sx={{
                width: "100%",
                maxWidth: 400,
                position: "relative",
                overflow: "hidden", // Empêche tout dépassement visuel
            }}>
            <Swiper
                modules={[Navigation, Autoplay]}
                spaceBetween={10}
                slidesPerView={1}
                navigation={{
                    prevEl: ".swiper-button-prev", // Sélecteurs personnalisés pour les boutons
                    nextEl: ".swiper-button-next",
                }}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false, // Continuer l'autoplay après interaction
                }}
                style={{paddingBottom: "20px"}} // Ajout de marge sous la diapositive
            >
                {images.map((step, index) => (
                    <SwiperSlide key={index}>
                        <Box
                            component="img"
                            sx={{
                                height: 255, // Uniformité pour toutes les images
                                display: "block",
                                maxWidth: "100%",
                                width: "100%",
                                objectFit: "cover", // Remplir sans déborder
                            }}
                            src={step}
                            alt={"Gallery " + index}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </Box>
    );
}

export default ImgSlider;
