import React, {forwardRef, useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import apis from "../../../config/apis";
import {addWallet, addWalletTransfers} from "../../../reducer";
import {checkErrorMessage} from "../../../tools/Tools";
import {international_payout_minimum, national_payout_minimum} from "../../../tools/Variables";
import SkeletonRectangular from "../../skeletons/SkeletonRectangular";
import BankAccount from "./BankAccount";
import PayOut from "./PayOut";
import {Box, Button, Typography} from '@mui/material';
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import TransitEnterexitIcon from "@mui/icons-material/TransitEnterexit";

/* eslint-disable-next-line no-empty-pattern */
export const  WalletBalance =  forwardRef(({}, ref) => {

    const dispatch = useDispatch();
    const {wallets, transfers, selected} = useSelector(state => state.wallet);
    const [out, setOut] = useState(false);
    const [toPayOut, setToPayOut] = useState(0);
    const [loading, setLoading] = useState(false);
    const [orangeM, setOrangeM] = useState(false);
    const [walletCurrency, setWalletCurrency] = useState('');
    const [walletCurrencyCode, setWalletCurrencyCode] = useState('');
    const auditor = useSelector(state => state.axios.auditor);
    const {banking} = useSelector(state => state.profile);
    const wallet = wallets[selected]
    const [walletPayoutConfigs, setWalletPayoutConfigs] = useState({
        'national_payout_min': 0,
        'international_payout_min': 0
    });
    const {
        isDev,
        countries,
        countryCode,
        countryOrangeMoneyAllowed,
        countryLog
    } = useSelector(state => state.global);

    const checkIfPayoutExist = () => {
        let toPayOutNb = Number(toPayOut)

        if (!banking['id'] && !orangeM) {
            setLoading(false)
            toast.error("Veuillez Enregistrer Votre RIB dans la Rubrique Coordonnées bancaires")
        } else if (!wallet.balance) {
            setLoading(false)
            toast.error("Avant de retirer des fonds, veuillez attendre que votre solde soit positif.")
        } else if (wallet.balance < parseFloat(toPayOut).toFixed(2)) {
            setLoading(false)
            toast.error("Nous ne pouvons pas faire ce retrait")
        } else {
            if (orangeM) {
                return true
            } else {
                let bankingCountryCode = banking.iban.slice(0, 2).toUpperCase()
                let isNational = bankingCountryCode === countryCode
                let msg = 'Le montant minimal autorisé pour un retrait est de '
                if (isNational && walletPayoutConfigs.national_payout_min > toPayOutNb) {
                    setLoading(false)
                    toast.error(
                        msg + walletPayoutConfigs.national_payout_min + ' ' + walletCurrency + '.'
                    )
                } else if (!isNational && walletPayoutConfigs.international_payout_min > toPayOutNb) {
                    setLoading(false)
                    toast.error(
                        msg + walletPayoutConfigs.international_payout_min + ' ' + walletCurrency + '.'
                    )
                } else {
                    return true
                }
            }
        }
        return false;
    }

    const payOutToBankAccount = () => {
        setLoading(true)
        if (checkIfPayoutExist()) {
            auditor.post(apis.payOut +  '/' + parseFloat(toPayOut).toFixed(2) + '/' + selected + '/' + walletCurrencyCode).then(resp => {

                let data = resp.data;
                let tmpWallet = [...wallets]
                tmpWallet[selected] = data['wallet']
                dispatch(addWallet(tmpWallet))
                let tmpTransfers = [data['transaction'], ...transfers]
                dispatch(addWalletTransfers(tmpTransfers))

                setOut(false)
                setLoading(false)
                toast.success("Le transfert de fonds s'est déroulé avec succès.")
            }).catch(error => {
                setLoading(false)
                let errorMessage = checkErrorMessage(error);
                toast(errorMessage.message)
            })
        }
    }

    const payOutToOrangeMoney = () => {
        setLoading(true)
        if (checkIfPayoutExist()) {
            auditor.post(apis.payOutOrangeMoney +  '/' + parseFloat(toPayOut).toFixed(2) + '/' + selected).then(resp => {

                let data = resp.data;
                let tmpWallet = [...wallets]
                tmpWallet[selected] = data['wallet']
                dispatch(addWallet(tmpWallet))
                let tmpTransfers = [data['new_transfer'], ...transfers]
                dispatch(addWalletTransfers(tmpTransfers))

                setOrangeM(false)
                setLoading(false)
                toast.success("Le transfert de fonds a été accompli avec succès et arrivera sous peu.")
            }).catch(error => {
                setLoading(false)
                let errorMessage = checkErrorMessage(error);
                toast(errorMessage.message)
            })
        }
    }

    const checkWalletOptions = () => {
        let walletCountry = countries?.filter(c => c.currencies.code === wallet.currency)[0]
        setWalletCurrency(walletCountry.currencies.symbol)
        let tmpCurrencyCode = walletCountry.currencies.code
        setWalletCurrencyCode(tmpCurrencyCode)

        if (tmpCurrencyCode in national_payout_minimum && tmpCurrencyCode in international_payout_minimum) {
            setWalletPayoutConfigs({
                ...walletPayoutConfigs,
                national_payout_min: national_payout_minimum[tmpCurrencyCode],
                international_payout_min: international_payout_minimum[tmpCurrencyCode]
            });
        } else {
            setWalletPayoutConfigs({
                ...walletPayoutConfigs,
                international_payout_min: international_payout_minimum[tmpCurrencyCode]
            });
        }

    }

    const OrangeMoneyBtn = () => {
        return (
            <Button
                variant="contained"
                color="secondary"
                onClick={() => setOrangeM(true)}
                endIcon={<SwapHorizIcon sx={{color: "white!important"}}/>}
                sx={{
                    textTransform: 'none',
                    height: 35,
                    fontSize: '0.75rem',
                    paddingX: 2,
                }}
            >
                Orange Money
            </Button>
        )
    }

    useEffect(() => {
        wallet?.currency && checkWalletOptions()

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [wallet?.currency])


    return (
        <div>

            <Modal size="md" show={out}>
                <PayOut
                    loading={loading}
                    setModal={setOut}
                    toPayOut={toPayOut}
                    setToPayOut={setToPayOut}
                    payOut={payOutToBankAccount}
                    walletCurrency={walletCurrency}
                    walletPayoutConfigs={walletPayoutConfigs}/>
            </Modal>

            <Modal size="md" show={orangeM}>
                <PayOut
                    orangeMoney
                    loading={loading}
                    toPayOut={toPayOut}
                    setModal={setOrangeM}
                    setToPayOut={setToPayOut}
                    payOut={payOutToOrangeMoney}
                    walletCurrency={walletCurrency}
                    walletPayoutConfigs={walletPayoutConfigs}/>
            </Modal>

            {loading
                ? <div className="card-content">
                    <div className="card-body cleartfix">
                        <SkeletonRectangular width="100%" height={61}/>
                    </div>
                </div> :
                <Box
                    sx={{
                        flex: '0 0 auto',
                        backgroundColor: '#424242', // Couleur ajustée
                        color: 'white', // Couleur du texte pour contraste
                        borderRadius: 1,
                        padding: 1,
                        marginBottom: 1.5,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    {/* Section Solde */}
                    <Box>
                        <Typography
                            variant="subtitle1"
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '0.8rem', // Taille légèrement réduite
                            }}
                        >
                            Solde
                        </Typography>
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1.4rem', // Taille réduite pour le montant
                                marginTop: 0.5,
                            }}
                        >
                            {wallet?.balance}&nbsp;{walletCurrency}
                        </Typography>
                    </Box>

                    {/* Boutons alignés verticalement */}
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setOut(true)}
                            endIcon={
                                <TransitEnterexitIcon
                                    sx={{color: "white!important", transform: 'rotate(180deg)'}}/>
                            }
                            sx={{
                                textTransform: 'none',
                                height: 35,
                                fontSize: '0.75rem', // Texte plus compact
                                paddingX: 2, // Réduction interne de la largeur
                            }}
                        >
                            Retrait
                        </Button>

                        {isDev
                            ? OrangeMoneyBtn()
                            : countryOrangeMoneyAllowed.includes(countryLog.toLowerCase())
                                ? OrangeMoneyBtn()
                                : null}

                        <BankAccount/>
                    </Box>
                </Box>
            }
        </div>
    )
});
