// InfoCard.js
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const InfoCard = ({ caption, title, subtitle, sx }) => {
    return (
        <Box sx={{ ...sx, px: 1, py: 0.5 }}>
            {caption && (
                <Typography variant="caption" color="textSecondary">
                    {caption}
                </Typography>
            )}
            {title && (
                <Typography variant="subtitle2">
                    {title}
                </Typography>
            )}
            {subtitle && (
                <Typography variant="body2" color="textSecondary">
                    {subtitle}
                </Typography>
            )}
        </Box>
    );
};

export default InfoCard;
