import React from "react";
import dayjs from "dayjs";
import {Box, Grid, Typography, useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/material/styles";

function ContactReview({contactForm, country, currency}) {
    const {name, lastname, reservation} = contactForm;
    const {
        event,
        total_amount,
        travel_expenses,
        address,
        event_date,
        description,
        postal_code,
    } = reservation;

    const theme = useTheme(); // Accès aux breakpoints
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Box display="flex" flexDirection="column" className="my-3">
            <Box>
                <Typography variant="h6" gutterBottom>
                    Détails sur l'événement proposée
                </Typography>
                {event_date && (
                    <Typography variant="subtitle1" className="mt-2">
                        Le&nbsp;
                        <strong>{dayjs(event_date).format("DD-MM-YYYY")}</strong>
                        &nbsp;à partir de&nbsp;
                        <strong>{dayjs(event_date).format("HH:mm A")}</strong>
                    </Typography>
                )}
            </Box>

            <Box className="m-3">
                <Grid container spacing={2} className="justify-content-between">
                    <Grid item xs={6}>
                        <Typography
                            variant="subtitle2"
                            sx={{fontSize: isSmallScreen ? "0.85rem" : "1rem"}} // Adaptation texte
                        >
                            • Auditeur
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            variant="subtitle2"
                            sx={{fontSize: isSmallScreen ? "0.85rem" : "1rem"}}
                        >
                            <strong>{name + " " + lastname}</strong>
                        </Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography
                            variant="subtitle2"
                            sx={{fontSize: isSmallScreen ? "0.85rem" : "1rem"}}
                        >
                            • Type d'événement
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            variant="subtitle2"
                            sx={{fontSize: isSmallScreen ? "0.85rem" : "1rem"}}
                        >
                            <strong>{event}</strong>
                        </Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography
                            variant="subtitle2"
                            sx={{fontSize: isSmallScreen ? "0.85rem" : "1rem"}}
                        >
                            • Adresse
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            variant="subtitle2"
                            sx={{fontSize: isSmallScreen ? "0.85rem" : "1rem"}}
                        >
                            <strong>{address}</strong>
                        </Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography
                            variant="subtitle2"
                            sx={{fontSize: isSmallScreen ? "0.85rem" : "1rem"}}
                        >
                            • Code Postal
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            variant="subtitle2"
                            sx={{fontSize: isSmallScreen ? "0.85rem" : "1rem"}}
                        >
                            <strong>{postal_code}</strong>
                        </Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography
                            variant="subtitle2"
                            sx={{fontSize: isSmallScreen ? "0.85rem" : "1rem"}}
                        >
                            • Pays
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            variant="subtitle2"
                            sx={{fontSize: isSmallScreen ? "0.85rem" : "1rem"}}
                        >
                            <strong>{country}</strong>
                        </Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography
                            variant="subtitle2"
                            sx={{fontSize: isSmallScreen ? "0.85rem" : "1rem"}}
                        >
                            • Montant proposé
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            variant="subtitle2"
                            sx={{fontSize: isSmallScreen ? "0.85rem" : "1rem"}}
                        >
                            <strong>{total_amount + " " + currency}</strong>
                        </Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography
                            variant="subtitle2"
                            sx={{fontSize: isSmallScreen ? "0.85rem" : "1rem"}}
                        >
                            + Frais de transport
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            variant="subtitle2"
                            sx={{fontSize: isSmallScreen ? "0.85rem" : "1rem"}}
                        >
                            <strong>
                                {travel_expenses
                                    ? travel_expenses + " " + currency
                                    : "*Offert"}
                            </strong>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

            {description && (
                <Box>
                    <Typography variant="subtitle1" gutterBottom>
                        À propos
                    </Typography>
                    <Typography
                        variant="caption"
                        className="mt-2 text-justify"
                        sx={{
                            fontSize: isSmallScreen ? "0.85rem" : "1rem", // Responsiveness du texte pour 'description'
                        }}
                    >
                        {description}
                    </Typography>
                </Box>
            )}
        </Box>
    );
}

export default ContactReview;
