import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import ContactCardList from "../contactCards/ContactCardList";


function ContactDemand() {

    const navigate = useNavigate();
    const role = useSelector(state => state.profile.role);
    const isLogout = [null, ''].includes(role);
    const auth = useSelector(state => state.session.authenticated);
    const isAuditor = isLogout || role === "professional_auditor";


    useEffect(() => {

        isAuditor && !auth && navigate('/')

    }, [isAuditor, auth, navigate])

    return (
        <div className="content mb-5">
            <ContactCardList/>
        </div>
    )
}

export default ContactDemand;
