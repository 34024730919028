import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import FeedIcon from '@mui/icons-material/Feed';
import {Card, Row} from "reactstrap";
import InfoCard from "../../tools/InfoCard";

function TabPanel({children, value, index}) {
    return (
        value === index && (
            <Box sx={{p: 2}}>
                <Typography>{children}</Typography>
            </Box>
        )
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    value: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
};

function ContentCard({title, content, height = 150}) {
    return (
        <Card
            className="border-0 pt-2"
            style={{
                height,
                width: "100%",
                padding: "15px",
                borderRadius: "8px", // Arrondi de la carte
                boxShadow: "0 1px 4px rgba(0, 0, 0, 0.2)", // Légère ombre pour l'esthétique
                overflow: "hidden", // Empêcher le débordement de contenu
            }}
        >
            <Row className="justify-content-center">
                <InfoCard
                    title={title}
                    subtitle={
                        content || (
                            <Typography variant="body2" color="textSecondary">
                                Aucun contenu disponible
                            </Typography>
                        )
                    }
                    sx={{
                        maxHeight: height - 50,
                        overflowY: "auto", // Ajouter un défilement en cas de contenu trop grand
                        padding: "10px", // Espacement interne uniforme
                        margin: 0,
                        textAlign: "justify", // Justification pour une bonne lecture
                        wordWrap: "break-word", // Gérer les mots trop longs
                    }}
                />
            </Row>
        </Card>
    );
}


export default function VerticalTabs({materials, rPolicy, description}) {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => setValue(newValue);

    const tabContent = [
        {
            title: 'À propos de la fiche',
            content: description,
        },
        {
            title: 'Matériels',
            content: materials?.list_of_materials.length
                ? materials.list_of_materials.map((item, index) => (
                    <small key={index} className="btn btn-grey text-lowercase text-secondary mx-2">
                        {item}
                    </small>
                ))
                : 'Pas de matériel',
        },
        {
            title: 'Politique',
            content: rPolicy === 'strict' ? 'Stricte' : rPolicy,
        },
    ];

    return (
        <div className="mt-3">
            {/* Section du contenu actif */}
            <ContentCard {...tabContent[value]} />

            {/* Section des onglets */}
            <Box
                sx={{
                    flexGrow: 1,
                    display: "flex",
                    justifyContent: "center", // Centrer horizontalement
                    borderRadius: "8px",
                    border: "1px solid #e0e0e0",
                    mt: 1,
                }}
            >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                    textColor="primary"
                    indicatorColor="primary"
                    sx={{
                        width: "fit-content", // Ajuste la largeur pour être juste assez large pour le contenu
                    }}
                >
                    <Tab icon={<FeedIcon/>}/>
                    <Tab icon={<HomeRepairServiceIcon/>}/>
                    <Tab icon={<LocalPoliceIcon/>}/>
                </Tabs>
            </Box>
        </div>

    );
}
