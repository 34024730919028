import axios from "axios";
import {createRoot} from "react-dom/client";
import React from "react";
import {Provider} from 'react-redux';
import {BrowserRouter} from "react-router-dom";
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import {thunk as thunkMiddleware} from "redux-thunk";
import {persistStore, persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {PersistGate} from "redux-persist/integration/react";
import {ThemeProvider, createTheme} from "@mui/material/styles";
import App from "./App";
import config from "./config/tsconfig.json";
import Global from "./reducer/Global";
import sessionReducer from "./reducer/sessionReducer"; // Utilise le reducer mis à jour pour `session`
import ArtistFromData from "./reducer/ArtistFromData";
import AxiosUSerConfig from "./reducer/axiosConfig/AxiosConf";
import CertificationData from "./reducer/certifications/certificationData";
import ChatBotReducer from "./reducer/ChatBotData/chatBotReducer";
import ChatPersonReducer from "./reducer/ChatPresonData/chatPersonReducer";
import ContactForm from "./reducer/contact/contactForm";
import Data from "./reducer/contact/data";
import ContactCardsData from "./reducer/contactCards/contactCardsData";
import searchBarData from "./reducer/SearchBarData";
import serviceData from "./reducer/services/ServiceData";
import Services from "./reducer/services/Services";
import WalletData from "./reducer/Wallets/WalletData";
import PaymentsData from "./reducer/payments/PaymentsData";
import ProfileData from "./reducer/profile/profileData";
import ProfileChecked from "./reducer/profile/profileChecked";
import RegisterCredentials from "./reducer/RegisterCredentials";
import DecryptData from "./tools/DecryptData";

import "assets/css/index.css";
import "assets/scss/paper-dashboard.scss?v=1.3.0";
import "bootstrap/dist/css/bootstrap.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";

axios.defaults.baseURL = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? config.configs.ServerApiDev
    : config.configs.ServerApi

axios.interceptors.response.use(
    response => {
        return {data: DecryptData(response.data)}
    },
    error => {
        return Promise.reject(error);
    }
);

// Configuration pour Material-UI
const theme = createTheme({
    palette: {
        mode: 'light',
    },
});

// Configuration de redux-persist
const persistConfig = {
    key: "root",
    storage: storage,
    whitelist: ["session"],
};

// CombineReducers avec tous les reducers
const rootReducer = combineReducers({
    session: sessionReducer,
    global: Global,
    toContact: Data,
    chatBot: ChatBotReducer,
    chatPerson: ChatPersonReducer,
    wallet: WalletData,
    payment: PaymentsData,
    profile: ProfileData,
    serviceResults: Services,
    axios: AxiosUSerConfig,
    profileChecked: ProfileChecked,
    serviceForm: serviceData,
    searchBarData: searchBarData,
    contactForm: ContactForm,
    contactCards: ContactCardsData,
    artistFromData: ArtistFromData,
    certificationData: CertificationData,
    registerCredentials: RegisterCredentials,
});

// Enveloppe rootReducer avec persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configuration du store Redux
const store = createStore(persistedReducer, undefined, compose(applyMiddleware(thunkMiddleware)));

// Configuration de redux-persist pour gérer la persistance
const persistor = persistStore(store);

const root = createRoot(document.getElementById("root"));

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </ThemeProvider>
        </PersistGate>
    </Provider>
);
