import EditIcon from "@mui/icons-material/Edit";
import React, {useState, useEffect, useRef} from 'react';
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import {useDispatch, useSelector} from "react-redux";
import apis from "../../../config/apis";
import {addCertificationObject} from "../../../reducer";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import {Spinner} from "reactstrap";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContactCost from "./ContactCost";
import {Box, Typography, IconButton, Accordion, AccordionSummary, AccordionDetails} from '@mui/material';

function IsCertified({toCloseProfile}) {

    const dispatch = useDispatch();
    const isMounted = useRef(false);
    const [loading, setLoading] = useState(true);
    const auditor = useSelector(state => state.axios.auditor);
    const {profileData} = useSelector(state => state.profile);
    const {certification} = useSelector((state) => state.certificationData);


    const checkCertification = async () => {
        !loading && setLoading(true)
        auditor.get(apis.myCertification).then(async resp => {
            await dispatch(addCertificationObject(resp.data));
            setLoading(false)
        }).catch(() => {})
    }

    const getStatusText = (status) => {
        switch (status) {
            case 'pending':
                return 'En attente';
            case 'in_verification':
                return 'En cours de vérification';
            case 'declined':
                return 'Demande déclinée';
            default:
                return 'Statut inconnu';
        }
    };

    const openModalCertification = () => {
        toCloseProfile();
        document.getElementById("certification_button").click()
    }

    useEffect(() => {

        if (!isMounted.current && !certification && !profileData?.is_certified) {
            checkCertification().then(r => null)
        } else if (loading) {
            setLoading(false)
        }

        return () => {
            isMounted.current = true
        };
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [certification, profileData?.is_certified]);

    return loading ? (
        <div className="row justify-content-center mt-5">
            <Spinner type="grow" size="sm"/>
        </div>
    ) : (
        <div>
            {profileData?.is_certified
                ? <ContactCost/>
                : certification ?
                    <Box sx={{
                        margin: 1,
                        padding: 2,
                        border: '1px solid #ddd',
                        borderRadius: '10px',
                        backgroundColor: '#f9f9f9'
                    }}>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Typography variant="subtitle2">
                                Suivi de votre certification
                            </Typography>
                            <IconButton
                                sx={{color: '#000'}}
                                disabled={certification.status !== 'declined'}
                                onClick={() => openModalCertification()}>
                                <EditIcon fontSize="small"/>
                            </IconButton>
                        </Box>
                        <Typography variant="body1">
                            <strong>Status :</strong>
                            <RadioButtonCheckedIcon
                                fontSize="small"
                                sx={{
                                    marginLeft: 1,
                                    marginRight: 0.5,
                                    marginBottom: 0.5,
                                    color: certification.status === 'pending'
                                        ? '#f1c40f !important'
                                        : certification.status === 'in_verification'
                                            ? '#3498db !important'
                                            : '#e74c3c !important'
                                }}/>
                            {getStatusText(certification.status)}
                        </Typography>
                        <Typography variant="body1">
                            <strong>Date d'envoi :</strong>
                            &nbsp;&nbsp;{dayjs(certification?.created_at).format("DD-MM-YYYY")}
                        </Typography>

                        {certification?.missing_fields?.length > 0 && certification.status !== 'pending' && (
                            <Typography variant="caption" color="error" sx={{ display: 'block', marginTop: 1 }}>
                                Champs manquants ou incompatibles :&nbsp;
                                {certification.missing_fields
                                    .map((field) => {
                                        switch (field) {
                                            case "name":
                                                return "Nom";
                                            case "surname":
                                                return "Prénom";
                                            case "dob":
                                                return "Date de naissance";
                                            default:
                                                return field;
                                        }
                                    })
                                    .join(", ")}.
                            </Typography>
                        )}

                        {certification.admin_message && certification.status !== 'pending' && (
                            <Box className="accordion-container" sx={{marginTop: 1}}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography variant="caption" color="textSecondary">
                                            Un message est disponible.
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Box
                                            className="pb-5"
                                            alignItems="center"
                                            sx={{
                                                maxHeight: '150px', // Ajustez la hauteur maximale selon vos besoins
                                                overflowY: 'auto', // Ajoute un défilement vertical si nécessaire
                                                overflowX: 'hidden', // Cache tout débordement horizontal
                                            }}>
                                            <Typography variant="body2" sx={{marginLeft: 1, textAlign: 'justify'}}>
                                                {certification.admin_message}
                                            </Typography>
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                        )}
                    </Box>
                    : <div style={{backgroundColor: "#f7f7f7", padding: "20px", borderRadius: "10px", margin: "10px"}}>
                        <div className="text-center mb-3">
                            <Button
                                variant="contained"
                                onClick={() => openModalCertification()}
                                style={{
                                    textTransform: "none",
                                    backgroundColor: "#51cbce",
                                    color: "#ffffff",
                                    borderRadius: "20px",
                                    fontWeight: "bold",
                                }}
                            >
                                Certifier mon compte
                            </Button>
                        </div>
                        <div style={{textAlign: "center", color: "#555"}}>
                            <p><strong>Avantages de la certification</strong></p>
                            <ul style={{
                                listStyleType: "disc",
                                textAlign: "left",
                                margin: "0 auto",
                                maxWidth: "400px",
                                fontSize: "0.9rem",
                                lineHeight: "1.4"
                            }}>
                                <li>Inspirer confiance aux auditeurs.</li>
                                <li>Augmenter vos opportunités de prestation.</li>
                                <li>Ajouter des frais de contact.</li>
                                <li>Plus encore...</li>
                            </ul>
                        </div>
                    </div>}
        </div>
    )
}

export default IsCertified;
