import {Carousel} from 'react-bootstrap';
import React from 'react';
import {Typography, Box} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
    root: {
        position: 'relative',
        width: '100%',
        height: '250px',  // Hauteur limitée à 250px
        display: 'flex',
        alignItems: 'center',
        borderRadius: '1rem',
        background: 'linear-gradient(90deg, rgba(0, 0, 0, 0.7) 0%, rgba(255, 255, 255, 0.1) 100%)',
        overflow: 'hidden',
    },
    backgroundVideo: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        zIndex: 0,
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content: {
        position: 'relative !important',
        zIndex: 2,
        paddingLeft: '20px !important',  // Ajuster la marge à gauche
        paddingRight: '20px !important',  // Ajuster la marge à droite
        color: '#fff',
        textAlign: 'center !important', // Centrer le texte pour petits écrans
        '@media (min-width: 460px)': {
            textAlign: 'left !important', // Aligner à gauche pour écrans plus larges
        },
    },
    description: {
        fontSize: '16px !important',
        marginBottom: '5px',
        '@media (max-width: 460px)': {
            fontSize: '14px !important',  // Réduire la taille de la police pour les petits écrans
        },
        '@media (max-width: 320px)': {
            fontSize: '12px !important',  // Réduire davantage pour les très petits écrans
        },
    },
}));

function HomeServiceInfos() {
    const classes = useStyles();

    return (
        <div className="row justify-content-center px-3">
            <Box className={classes.root}>
                <video className={classes.backgroundVideo} autoPlay muted loop>
                    <source src="https://cdn.pixabay.com/video/2020/05/07/38388-418005845_tiny.mp4" type="video/mp4"/>
                </video>

                <Box className={classes.overlay}></Box>

                <Box className={classes.content}>
                    <Typography variant="h6">Bienvenue sur KantoBiz</Typography>

                    <Carousel className="w-100" controls={false} indicators={false} interval={5000} fade>
                        <Carousel.Item>
                            <Typography className={classes.description}>
                                Prenez contact avec un artiste(s)/Dj pour animer vos événements (showcases,
                                anniversaires, et plus encore)
                            </Typography>
                        </Carousel.Item>
                        <Carousel.Item>
                            <Typography className={classes.description}>
                                Organisez vos événements à la date et au lieu de votre choix.
                            </Typography>
                        </Carousel.Item>
                        <Carousel.Item>
                            <Typography className={classes.description}>
                                Simplifiez la gestion de votre agenda.
                            </Typography>
                        </Carousel.Item>
                    </Carousel>
                </Box>
            </Box>
        </div>
    )
}

export default HomeServiceInfos;
