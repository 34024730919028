const initialState = {
    authenticated: false, // Par défaut, l'utilisateur est non connecté
    token: null,          // Le token de la session (null par défaut)
    user: null,           // Les informations de l'utilisateur connecté (null par défaut)
};

const sessionReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_SESSION":
            return {
                ...state,
                authenticated: true,
                token: action.data.token,
                user: action.data.user,
            };
        case "CLEAR_SESSION":
            return {
                ...state,
                authenticated: false,
                token: null,
                user: null,
            };
        default:
            return state;
    }
};

export default sessionReducer;
