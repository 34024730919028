import React, {useState} from 'react';
import Rating from "@mui/material/Rating";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import moment from "moment/moment";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import {Divider, IconButton, Box, Grid, TextField, Typography} from "@mui/material";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import {useSelector} from "react-redux";
import {CardBody, CardFooter} from "reactstrap";
import apis from "../../config/apis";
import CardHeaderCustom from "../../tools/CardHeaderCustom";
import {btnStyle, changeFields, checkErrorMessage, footerStyle} from "../../tools/Tools";

function ArtistEventRate({ContactCardToChat}) {

    const title = "survey_artist_event_rate";
    const artist_id = ContactCardToChat?.artist_id;
    const reservation_id = ContactCardToChat?.reservation?.id;
    const eventDate = ContactCardToChat?.reservation?.event_date;
    const auditor = useSelector(state => state.axios.auditor);
    const [rating, setRating] = useState(false);
    const [signal, setSignal] = useState(false);
    const [process, setProcess] = useState(false);
    const [note, setNote] = useState(false);
    const [message, setMessage] = useState('');
    const isWithinLast48Hours = eventDate && moment().diff(moment(eventDate), 'hours') <= 48;


    const sendNewSupport = async () => {
        try {
            setProcess(true);
            await auditor.put(apis.addArtistRating, {user_id: artist_id, note: [note]});
            await auditor.post(apis.newSupportMessage, {title, message, reservation_id});
            toast.success("Merci de votre aide !");
            setSignal(false);
            setRating(false);
        } catch (error) {
            toast.error(checkErrorMessage(error).message);
        } finally {
            setProcess(false);
        }
    };

    return (
        <div>

            <Modal size="md" style={{marginTop: '10px'}} show={rating || signal}>
                <CardHeaderCustom
                    disabled={process}
                    cardTitle={rating ? 'Noter la prestation' : 'Signaler un incident'}
                    closeModal={() => {
                        setRating(false);
                        setSignal(false);
                    }}/>

                <CardBody>
                    <Box sx={{margin: "12px !important"}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sx={{textAlign: 'center'}}>
                                <Typography
                                    variant="subtitle1"
                                    component="div"
                                    className="font-weight-bold mb-2">
                                    Note de l'artiste
                                </Typography>
                                <Rating
                                    size="large"
                                    value={note}
                                    name="half-rating"
                                    onChange={(e, newValue) => setNote(newValue)}/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    rows={2}
                                    multiline
                                    label="Quelque description"
                                    variant="filled"
                                    value={message}
                                    placeholder="Décrivez brièvement la prestation de l'artiste"
                                    onChange={(e) => changeFields(setMessage, e)}
                                    sx={{
                                        "& .MuiFilledInput-root": {
                                            borderRadius: "10px",
                                            backgroundColor: "#f1f3f4",
                                            border: "1px solid rgba(0, 0, 0, 0.1)", // Légère bordure
                                        },
                                        "& .MuiFilledInput-root:before, & .MuiFilledInput-root:after": {
                                            display: "none", // Masquer les lignes avant et après le champ
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </CardBody>

                <CardFooter>
                    <Box sx={footerStyle}>
                        <Tooltip title="Valider" placement="top">
                            <IconButton
                                size="small"
                                disabled={process}
                                aria-label="settings"
                                sx={btnStyle('lightGreen')}
                                onClick={() => sendNewSupport()}>
                                <small>Envoyer&nbsp;</small>
                                <DoneAllIcon fontSize="small" sx={{color: "lightGreen!important"}}/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                </CardFooter>
            </Modal>

            <div style={{
                textAlign: 'right',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: '8px'
            }}>
                <Tooltip
                    title="Comment a été l'artiste pendant sa prestation ?"
                    placement="top">
                <span
                    onClick={() => setRating(true)}
                    className="font-weight-bold border px-1 rounded-sm cursor-pointer"
                    style={{fontSize: '0.75rem', color: '#757575'}}>
                    Noter l'artiste et sa prestation&nbsp;
                    <InfoOutlinedIcon
                        sx={{
                            color: '#757575 !important',
                            fontSize: '0.875rem',
                            marginBottom: 1 / 2,
                            cursor: 'pointer'
                        }}/>
                </span>
                </Tooltip>
                {isWithinLast48Hours && (
                    <>
                        <Divider orientation="vertical" sx={{width: '0.1px', height: '15px !important'}}/>
                        <span
                            onClick={() => setSignal(true)}
                            style={{
                                cursor: 'pointer',
                                color: 'orange',
                                display: 'flex',
                                marginBottom: 1 / 2,
                                alignItems: 'center',
                                fontSize: '0.75rem'
                            }}>
                    Signaler&nbsp;
                            <Tooltip
                                title="Dans les 24h précédent la prestation, on peut signaler s'il y a un incident ou autres."
                                placement="top"
                            >
                    <WarningAmberOutlinedIcon sx={{color: "orange!important", fontSize: '0.875rem'}}/>
                </Tooltip>
            </span>
                    </>
                )}
            </div>

        </div>
    )
}

export default ArtistEventRate;
