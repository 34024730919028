import CircleIcon from "@mui/icons-material/Circle";
import FemaleIcon from "@mui/icons-material/Female";
import MaleIcon from "@mui/icons-material/Male";
import VerifiedIcon from "@mui/icons-material/Verified";
import {Typography} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React, {useState} from "react";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {Row, Input} from "reactstrap";
import apis from "../../config/apis";
import {addProfileData} from "../../reducer";
import {changeFields, checkErrorMessage, deleteNullInObj, fillInBodyForm} from "../../tools/Tools";
import EditIcon from "@mui/icons-material/Edit";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import IconButton from "@mui/material/IconButton";
import {artist_t} from "../../tools/Variables";
import AddGender from "./Edits/AddGender";

function ProfileNameAndPseudo({checked}) {
    const dispatch = useDispatch();
    const auditorForm = useSelector((state) => state.axios.auditorForm);
    const {role, profileData} = useSelector(state =>
        checked ? state.profileChecked : state.profile
    );
    const isAuditor = role === "professional_auditor";
    const {is_certified} = profileData;
    const [name, setName] = useState(profileData.name);
    const [lastname, setLastname] = useState(profileData.lastname);
    const [artistName, setArtistName] = useState(profileData.artist_name);
    const [activeEditGender, setActiveEditGender] = useState(false);
    const [activeNameEdit, setActiveNameEdit] = useState(false);
    const [activeArtistNameEdit, setActiveArtistNameEdit] = useState(false);

    const upDateProfileInfo = async () => {
        let tData = deleteNullInObj({...profileData});
        tData["name"] = name;
        tData["lastname"] = lastname;
        tData["artist_name"] = artistName;
        delete tData['is_certified'];
        const bodyFormData = fillInBodyForm(new FormData(), tData);
        await auditorForm
            .put(apis.updateProfile, bodyFormData)
            .then(async (resp) => {
                await dispatch(addProfileData(resp.data));
                toast.success("Votre pseudo a été mis à jour.");
            })
            .catch((error) => {
                toast.error(checkErrorMessage(error).message);
            });
    };

    const validateName = () => {
        if (!name) {
            toast.error("Votre nom est nécessaire.");
        } else upDateProfileInfo().then((r) => setActiveNameEdit(false));
    };

    const validateArtistName = () => {
        upDateProfileInfo().then((r) => setActiveArtistNameEdit(false));
    };

    return checked ? (
        <div style={{
            display: "flex",
            flexDirection: "column", // Aligner les éléments en colonne
            textAlign: "center", // Centrer le texte horizontalement
            alignItems: "center",
            justifyContent: "center",
            width: "100%"
        }}>
            <Typography variant="subtitle1">
                {profileData?.artist_name}
                {profileData?.is_certified
                    ? <Tooltip style={{cursor: 'pointer'}} title="profil verifié" placement='top'>
                        <VerifiedIcon
                            fontSize="small"
                            sx={{
                                marginLeft: 0.5,
                                marginBottom: 0.5,
                                color: '#1976d2 !important'
                            }}/>
                    </Tooltip> : null}
            </Typography>
            <Typography variant="caption">
                {artist_t[role]}
            </Typography>
        </div>
    ) : (
        <div className={profileData?.photo && profileData?.photo !== "null" ? '' : ' mt-5'}>
            {activeEditGender ? null : profileData?.gender === "male" ? (
                <Tooltip title="Homme" placement="top">
                    <MaleIcon
                        sx={{cursor: "pointer"}}
                        onClick={() => setActiveEditGender(true)}/>
                </Tooltip>
            ) : profileData?.gender === "female" ? (
                <Tooltip title="Femme" placement="top">
                    <FemaleIcon
                        sx={{cursor: "pointer"}}
                        onClick={() => setActiveEditGender(true)}/>
                </Tooltip>
            ) : (
                <Tooltip title="Sexe non défini" placement="top">
                    <CircleIcon
                        fontSize="small"
                        sx={{marginTop: 0.2, cursor: "pointer"}}
                        onClick={() => setActiveEditGender(true)}/>
                </Tooltip>
            )}
            &nbsp;
            {activeEditGender && <AddGender closeModal={() => setActiveEditGender(false)}/>}
            <Row className="justify-content-between align-items-center" style={{display: "flex", position: "relative"}}>
                {activeNameEdit ? (
                    <div style={{display: "flex", alignItems: "center", width: "100%", flexDirection: "column"}}>
                        <div style={{width: "100%", position: "relative"}}>
                            <Input
                                type="text"
                                value={lastname}
                                onChange={(e) => changeFields(setLastname, e)}
                                placeholder="Votre prénom"
                                style={{flexGrow: 1, marginBottom: "10px", width: "calc(100% - 50px)"}}
                            />
                            <Input
                                type="text"
                                value={name}
                                onChange={(e) => changeFields(setName, e)}
                                placeholder="Votre nom"
                                style={{flexGrow: 1, marginBottom: "10px", width: "calc(100% - 50px)"}}
                            />
                            <IconButton
                                size="small"
                                aria-label="save"
                                style={{
                                    position: "absolute",
                                    top: "50%",
                                    right: "0",
                                    transform: "translateY(-50%)",
                                    color: "#51cbce"
                                }}
                                onClick={validateName}
                            >
                                <DoneAllIcon sx={{color: "lightGreen!important"}}/>
                            </IconButton>
                        </div>
                    </div>
                ) : (
                    <div
                        style={{
                            marginTop: "10px",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: window.innerWidth < 600 ? "0.9rem" : "1rem",
                        }}
                    >
                        <span
                            style={{
                                fontWeight: 400,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                marginRight: "8px", // Ajoute un léger espacement entre le texte et l'icône
                            }}
                        >
                            {lastname && lastname} {name}
                        </span>
                        <IconButton
                            aria-label="edit"
                            onClick={() => setActiveNameEdit(true)}
                        >
                            <EditIcon fontSize="small"/>
                        </IconButton>
                    </div>
                )}
            </Row>

            {!isAuditor && (
                <Row className="justify-content-between align-items-center" style={{display: "flex"}}>
                    {activeArtistNameEdit ? (
                        <div style={{display: "flex", alignItems: "center", width: "100%"}}>
                            <Input
                                type="text"
                                value={artistName}
                                onChange={(e) => changeFields(setArtistName, e)}
                                placeholder="Nom d'artiste"
                                style={{flexGrow: 1, marginRight: "10px"}}
                            />
                            <IconButton
                                size="small"
                                aria-label="save"
                                style={{marginLeft: "10px", color: "#51cbce"}}
                                onClick={validateArtistName}
                            >
                                <DoneAllIcon sx={{color: "lightGreen!important"}}/>
                            </IconButton>
                        </div>
                    ) : (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%",
                                fontSize: window.innerWidth < 600 ? "0.9rem" : "1rem",
                            }}
                        >
                              <span
                                  style={{
                                      flexGrow: 1,
                                      fontWeight: 400,
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      marginLeft: window.innerWidth < 600 ? "5px" : "0",
                                      marginRight: window.innerWidth < 600 ? "5px" : "0",
                                  }}
                              >
                                (aKa: {artistName}{is_certified
                                  ? <Tooltip style={{cursor: 'pointer'}} title="Profil verifié" placement='top'>
                                      <VerifiedIcon
                                          fontSize="small"
                                          sx={{
                                              marginLeft: 0.5,
                                              marginBottom: 0.5,
                                              color: '#1976d2 !important'
                                          }}/>
                                  </Tooltip>
                                  : null})
                              </span>
                            <IconButton
                                aria-label="edit"
                                style={{marginLeft: "10px"}}
                                onClick={() => setActiveArtistNameEdit(true)}
                            >
                                <EditIcon fontSize="small"/>
                            </IconButton>
                        </div>
                    )}
                </Row>
            )}
        </div>
    );
}

export default ProfileNameAndPseudo;
