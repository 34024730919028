import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import apis from "../../../config/apis";
import {addWalletTransfers} from "../../../reducer";
import SkeletonRectangular from "../../skeletons/SkeletonRectangular";
import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemText
} from '@mui/material';
dayjs.extend(utc)

function Transfers() {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    // eslint-disable-next-line
    const [page, setPage] = useState(1);
    const {transfers} = useSelector(state => state.wallet);
    const auditor = useSelector(state => state.axios.auditor);
    const currency = useSelector(state => state.global.globalCurrencySymbol);

    const getTransfers = (_page) => {

        let p = _page ? _page : page
        auditor.get(apis.transactions + '/' + p).then(resp => {
            dispatch(addWalletTransfers(resp.data))
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    }

    const checkSubjectString = (subject) => {
        switch (subject) {
            case 'reservation_fee':
                return "Événement";
            case 'orange_money_transfer':
                return "Orange Money";
            case 'bank_transfer':
                return "Virement";
            case 'prestige_fee':
                return "Prestige Money";
            case 'contact_fee':
                return "Contact";
            default:
                return "unknown";
        }
    }

    useEffect(() => {
        if (!transfers?.length) {
            getTransfers()
        } else {
            setLoading(false);
        }

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [transfers?.length])

    return (
        loading
            ? <SkeletonRectangular width="100%" height={61}/>
            : <Box sx={{flex: '1 1 auto', overflowY: 'auto'}}>
                <Typography
                    variant="subtitle1"
                    sx={{
                        fontWeight: 'bold',
                        fontSize: '0.9rem',
                        marginBottom: 1,
                    }}
                >
                    Transactions
                </Typography>

                {transfers?.length
                    ? (
                        <List disablePadding>

                            {transfers.map((row) => (
                                <ListItem
                                    button
                                    dense
                                    className="border-bottom"
                                    key={'transfers-' + transfers.id}
                                    onClick={() => console.log('Achat sur Amazon')}
                                    sx={{
                                        cursor: 'pointer',
                                        borderRadius: 1,
                                        padding: "0px 5px",
                                        '&:hover': {
                                            backgroundColor: '#e0e0e0',
                                        },
                                    }}
                                >
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant="body2"
                                                sx={{fontWeight: 'bold', fontSize: '0.8rem'}}
                                            >
                                                {checkSubjectString(row.subject)}
                                                <FiberManualRecordIcon
                                                    sx={{
                                                        fontSize: '0.8rem',
                                                        color:
                                                            row.status === 'success'
                                                                ? 'lightGreen!important'
                                                                : 'silver!important'
                                                    }}/>
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography
                                                variant="caption"
                                                color="textSecondary"
                                                sx={{fontSize: '0.7rem'}}
                                            >
                                                {dayjs(row.created_at).utc().format("DD-MM-YYYY à HH:mm A")}
                                            </Typography>
                                        }
                                    />
                                    <Typography
                                        variant="body2"
                                        color={row.increase ? 'success' : 'error'}
                                        sx={{fontWeight: 'bold', fontSize: '0.8rem'}}
                                    >
                                        {row.amount}&nbsp;{currency}
                                    </Typography>
                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',           // Utilisation du conteneur Flexbox
                                justifyContent: 'center',  // Aligne horizontalement au centre
                                alignItems: 'center',      // Aligne verticalement au centre
                                height: '100%',            // Remplit la hauteur disponible (facultatif)
                                padding: 2,                // Ajoute un espace autour
                            }}
                        >
                            <Typography variant="body2" color="textSecondary">
                                Aucun transfer
                            </Typography>
                        </Box>
                    )}

            </Box>
    )
}

export default Transfers;
