import CardHeader from "@mui/material/CardHeader";
import {Box, IconButton, Typography} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import 'moment/locale/fr';
import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {
    addProfileContactsArchived,
    addProfileContactsToFinish,
    addWallet,
    addWalletTransfers, setUpcomingEvent,
} from "../../reducer";
import Timer from "../../tools/Countdown/timer";
import {checkTimer} from "../../tools/Tools";
import ContactCard from "../contactCards/ContactCard";
import EventListSkeleton from "../skeletons/EventListSkeleton";

moment.locale('fr');

function EventList({openToChat}) {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const {contacts_to_finish, contacts, contacts_archived} = useSelector(state => state.profile);
    const {transfers, wallets, selected} = useSelector(state => state.wallet);
    const upComingEvent = useSelector(state => state.global.upComingEvent);
    const wallet = wallets[selected];
    const [showBook, setShowBook] = useState({});
    const [toShow, setToShow] = useState(false);
    const [time, setTime] = useState({days: 0, hours: 0, minutes: 0, seconds: 0});
    const [street, pcCity, country] = upComingEvent?.reservation?.address?.split(', ') || [];
    const [comingEvents, setComingEvents] = useState([]);

    // Function to find the closest event based on the current date
    const findClosestEvent = () => {
        const events = [
            ...(contacts.book?.accepted || []), // Vérifie que 'contacts.book.accepted' existe, sinon utilise un tableau vide
            ...(contacts.book?.pending || []), // Vérifie que 'contacts.book.pending' existe
            ...(contacts.auditor?.accepted || []), // Vérifie que 'contacts.auditor.accepted' existe
            ...(contacts.auditor?.pending || []), // Vérifie que 'contacts.auditor.pending' existe
        ];

        const now = moment();

        // Filtrer et trouver l'événement le plus proche basé sur la date actuelle
        const upcoming = events
            ?.filter(event => ['accepted', 'paid']?.includes(event.reservation.status) && moment(event.reservation.event_date).isAfter(now))
            .sort((a, b) => moment(a.reservation.event_date) - moment(b.reservation.event_date))[0];

        if (upcoming) {
            dispatch(setUpcomingEvent(upcoming))
            checkTimer(upcoming, setTime);
        } else {
            setComingEvents([]);
            dispatch(setUpcomingEvent(null));
        }

        setComingEvents(events
            ?.filter((event) => event.id !== upcoming?.id)
            .sort((a, b) => moment(a.reservation.event_date) - moment(b.reservation.event_date))
            .slice(0, 10));

        setTimeout(() => {
            setLoading(false);
        }, 500);
    };

    const toFinished = async (transaction, contact_card) => {
        const newList = contacts_to_finish.filter(contact => contact.id !== contact_card.id);
        dispatch(addProfileContactsToFinish(newList));
        dispatch(addProfileContactsArchived([...contacts_archived, contact_card]));

        const updatedWallets = [...wallets];
        updatedWallets[selected] = {...wallet, balance: wallet.balance + transaction.amount};

        dispatch(addWallet(updatedWallets));
        dispatch(addWalletTransfers([...transfers, transaction]));
        setToShow(false);
        toast("Vous avez terminé une prestation. La prise de contact sera automatiquement déplacée dans vos archives. Veuillez consulter votre compte pour vérifier votre dû.");
    };

    const addReservationToShow = async (book) => {
        setShowBook(book);
        setToShow(true);
    };

    useEffect(() => {
        findClosestEvent();
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [contacts]);

    return loading ? (
        <EventListSkeleton />
    ) : !upComingEvent && contacts_to_finish?.length === 0 && comingEvents?.length === 0
        ? (
            <Box
                className="my-5"
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Typography variant="subtitle2" color="textSecondary">
                    Aucun événement en cours
                </Typography>
            </Box>
    ) : (
        <div className="row justify-content-center">
            <Modal size="lg" show={toShow}>
                <ContactCard setToShow={setToShow} ContactCardToChat={showBook} toFinished={toFinished} />
            </Modal>

            <div className="col-md-12">
                <Box display="flex" flexDirection="column" gap={2} className="text-left">
                    <Box>
                        {upComingEvent && (
                            <Box className="d-block d-md-none mb-3">
                                <Typography variant="subtitle2" style={{ flexShrink: 0 }}>
                                    <Timer
                                        label={"Prochain événement dans"}
                                        timerData={time} left />
                                </Typography>
                            </Box>
                        )}

                        {upComingEvent && (
                            <Tooltip title="Cliquer pour afficher la discussion" placement="top">
                                <IconButton
                                    key={"first_event_btn"}
                                    className="width-full border-bottom"
                                    sx={{
                                        backgroundColor: "background.paper",
                                        padding: "4px",
                                    }}
                                    onClick={() => openToChat(upComingEvent)}
                                >
                                    <CardHeader
                                        key={"first_event"}
                                        style={{ width: "100%", padding: "8px" }}
                                        title={
                                            <Box display="flex" flexDirection="column" gap={0.5} className="text-left">
                                                <Typography variant="h6">{upComingEvent?.reservation?.event}</Typography>
                                                <Typography variant="subtitle2">{street}</Typography>
                                                <Typography variant="subtitle2">
                                                    {pcCity + ", " + country}
                                                </Typography>
                                                <Typography variant="caption">
                                                    <strong>
                                                        Le&nbsp;
                                                        {moment(upComingEvent?.reservation?.event_date)?.format(
                                                            "DD-MM-YYYY à HH:mm A"
                                                        )}
                                                    </strong>
                                                </Typography>
                                            </Box>
                                        }
                                    />
                                </IconButton>
                            </Tooltip>
                        )}

                        {contacts_to_finish?.length > 0 && (
                            <Box className="mt-2">
                                <Typography variant="subtitle1" className="mb-2 font-weight-bold">
                                    Événements Terminés
                                </Typography>

                                {contacts_to_finish.map((item, index) => (
                                    <Tooltip
                                        title="Cliquer pour afficher la discussion"
                                        placement="top"
                                        key={`w-${index}`}
                                    >
                                        <IconButton
                                            className="width-full border-bottom"
                                            sx={{ backgroundColor: "background.paper", padding: "4px" }}
                                            onClick={() => addReservationToShow(item)}
                                        >
                                            <CardHeader
                                                key={`event-${index}`}
                                                style={{ width: "100%", padding: "8px" }}
                                                title={
                                                    <Box display="flex" flexDirection="column" gap={1} className="text-left">
                                                        <Typography variant="h6">{item.reservation?.event}</Typography>
                                                        <Typography variant="subtitle2">
                                                            {item.reservation?.address}
                                                        </Typography>
                                                        <Typography variant="caption">
                                                            <strong>
                                                                Le&nbsp;
                                                                {moment(item.reservation?.event_date)?.format(
                                                                    "DD-MM-YYYY à HH:mm A"
                                                                )}
                                                            </strong>
                                                        </Typography>
                                                    </Box>
                                                }
                                            />
                                        </IconButton>
                                    </Tooltip>
                                ))}
                            </Box>
                        )}

                        {comingEvents?.length > 0 && <hr className="ml-1" />}

                        {comingEvents?.length > 0 && (
                            <Box className="mt-2">
                                <Typography variant="subtitle1" className="mb-3 font-weight-bold">
                                    Autres Événements
                                </Typography>

                                <Box style={{ maxHeight: "388px", overflowY: "auto" }}>
                                    {comingEvents.map((contact, i) => (
                                        <Tooltip
                                            title="Cliquer pour afficher la discussion"
                                            placement="top"
                                            key={`w-${i}`}
                                        >
                                            <IconButton
                                                key={`event-${i}`}
                                                className="width-full border-bottom"
                                                sx={{
                                                    backgroundColor:
                                                        contact.status === "accepted"
                                                            ? "secondary.light"
                                                            : "primary.light",
                                                    padding: "4px",
                                                }}
                                                onClick={() => addReservationToShow(contact)}
                                            >
                                                <CardHeader
                                                    key={`event-header-${i}`}
                                                    style={{ width: "100%", padding: "8px" }}
                                                    title={
                                                        <Box display="flex" flexDirection="column" gap={1} className="text-left">
                                                            <Typography variant="h6" style={{ color: "white" }}>
                                                                {contact.reservation?.event}
                                                            </Typography>
                                                            <Typography variant="caption" className="mt-2">
                                                                <strong>
                                                                    Le&nbsp;
                                                                    {moment(contact.reservation?.event_date)?.format(
                                                                        "DD-MM-YYYY à HH:mm A"
                                                                    )}
                                                                </strong>
                                                            </Typography>
                                                        </Box>
                                                    }
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    ))}
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>
            </div>
        </div>
    );
}

export default EventList;
