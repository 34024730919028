import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Tooltip from "@mui/material/Tooltip";
import React, {useEffect, useState} from "react";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {useNavigate} from "react-router-dom";
import {addProfileServices} from "../../reducer";
import {checkErrorMessage, checkUnit, deleteInObject, fillInBodyForm, useWindowSize} from "../../tools/Tools";
import SkeletonSquare from "../skeletons/SkeletonSquare";


function ServiceCardHeader({checked, service, openEditService}) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const width = useWindowSize();
    const [currency, setCurrency] = useState("");
    const [loading, setLoading] = useState(false);
    const countries = useSelector(state => state.global.countries);
    const services = useSelector(state => checked ? state.profileChecked.services : state.profile.services);
    const auditor = useSelector(state => state.axios.auditor);
    const auditorForm = useSelector(state => state.axios.auditorForm);

    const onChangeHidden = async () => {
        setLoading(true)
        let tmpService = services.filter((value) => value.id === service.id)[0]
        tmpService.hidden = !tmpService.hidden;
        let _id = tmpService['id']
        let tmpData = [...services].filter((value) => value.id !== service.id)
        tmpData.push({...tmpService})
        tmpService = deleteInObject({...tmpService}, ['notes', 'artist_name', 'materials', 'artist_type'])

        if (typeof tmpService['unit_of_the_preparation_time'] === 'object') {
            tmpService['unit_of_the_preparation_time'] = checkUnit(tmpService['unit_of_the_preparation_time']);
        }

        if (typeof tmpService['unit_duration_of_the_service'] === 'object') {
            tmpService['unit_duration_of_the_service'] = checkUnit(tmpService['unit_duration_of_the_service']);
        }

        await auditorForm.put("artist_services/update/" + _id, fillInBodyForm(new FormData(), tmpService)).then((resp) => {
            dispatch(addProfileServices([...tmpData]));
            if (!tmpService.hidden) {
                toast.success("Fiche visible")
            } else toast.success("Fiche invisible")
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            toast.error(checkErrorMessage(error).message)
        });
    };

    const deleteService = async () => {
        if (services.length <= 1) {
            toast.error("Nombre de service minimum: 1")
        } else {
            setLoading(true)
            await auditor.delete('artist_services/delete/' + service.id).then(async () => {
                let tmp = [...services].filter((_service) => _service.id !== service.id);
                await dispatch(addProfileServices([...tmp]));
                toast.success("Fiche KantoBiz supprimer");
                setLoading(false)
            }).catch((error) => {
                let response = checkErrorMessage(error).message;
                if (response === 'There are reservations on the service')
                    toast("Il y a une ou plusieurs reservation a l'encontre de ce service, impossible de le supprimer", {
                        duration: 6000,
                    });
                setLoading(false)
            });
        }
    };

    useEffect(() => {
        /* eslint-disable-next-line array-callback-return */
        countries?.forEach((tmpCountry) => {
            if (service?.country.toLowerCase() === tmpCountry['name'].toLowerCase()
                || service?.country.toLowerCase() === tmpCountry['nativeName'].toLowerCase()) {
                setCurrency(tmpCountry?.currencies?.symbol)
            }
        });
    }, [setCurrency, countries, service?.country]);

    return (
        <CardHeader
            style={{width: "100%", padding: "5px 10px"}}
            onClick={() => checked && navigate("/artist/service/" + service.id)}
            onDoubleClick={() => !checked ? navigate("/artist/service/" + service.id) : null}
            avatar={
                <img
                    alt="..."
                    height={40}
                    width={40}
                    src={service.galleries[0]}
                />
            }
            action={
                checked
                    ? <h6 className="mb-3 pr-1" style={{fontSize: 14}}>{service?.refund_policy}</h6>
                    : <div style={{
                        gap: "8px",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: '10px'
                    }}>
                        <IconButton
                            disabled={loading}
                            size={width < 600 ? "small" : "medium"}
                            aria-label="settings"
                            style={{marginTop: 8}}
                            onClick={() => onChangeHidden()}>
                            {loading
                                ? <SkeletonSquare dimension={25}/>
                                : service?.hidden
                                    ? <Tooltip title="Fiche invisible aux auditeurs Pro" placement="top">
                                        <VisibilityOffIcon fontSize="small" sx={{color: "gray!important"}}/>
                                    </Tooltip>
                                    : <Tooltip title="Fiche visible par tous" placement="top">
                                        <VisibilityIcon fontSize="small"/>
                                    </Tooltip>
                            }
                        </IconButton>

                        <Tooltip title="Modifier la Fiche" placement="top">
                            <IconButton
                                disabled={loading}
                                size={width < 600 ? "small" : "medium"}
                                aria-label="settings"
                                style={{marginTop: 8}}
                                onClick={() => openEditService(service)}>
                                {loading
                                    ? <SkeletonSquare dimension={25}/>
                                    : <ModeEditIcon sx={{color: "gray!important"}} fontSize="small"/>}
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Supprimer" placement="top">
                            <IconButton
                                disabled={loading}
                                size={width < 600 ? "small" : "medium"}
                                aria-label="settings"
                                style={{marginTop: 8}}
                                onClick={() => deleteService()}>
                                {loading ? <SkeletonSquare dimension={25}/> : <DeleteIcon fontSize="small"/>}
                            </IconButton>
                        </Tooltip>

                    </div>
            }
            title={(<>
                <strong>{service?.title}</strong>
                <>{width < 600 ? null : " - " + service?.price + currency}</>
            </>)}
            subheader={
                <div className="d-none d-lg-block">
                    {(service?.cities[0] === 'all' ? "Disponible en " + service?.country : service.cities.join(", "))}
                </div>
            }
        />
    );
}

export default ServiceCardHeader;
