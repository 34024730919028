import {Box} from "@mui/material";
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import SmartToyIcon from "@mui/icons-material/SmartToy";
import UndoIcon from "@mui/icons-material/Undo";
import React, {useState} from "react";
import toast from "react-hot-toast";
import {ImpulseSpinner} from "react-spinners-kit";
import {useDispatch, useSelector} from "react-redux";
import {CardBody, CardFooter, CardHeader, FormGroup} from "reactstrap";
import apis from "../../config/apis";
import {addBotMessageList, switchBotLoadingAnswer} from "../../reducer";
import ChatMsg from "../../tools/ChatMsg";
import {changeFields, checkErrorMessage, footerStyle, IPriceField, upCardEvents} from "../../tools/Tools";

function NewProposition(props) {

    const dispatch = useDispatch();
    const [load, setLoad] = useState(false);
    const {messagesList, loadingAnswer} = useSelector(state => state.chatBot);
    const [newPropose, setNewPropose] = useState(null);
    const auditor = useSelector(state => state.axios.auditor);
    const contacts = useSelector(state => state.profile.contacts);
    const {contactToChat, currenciesChat, countries} = useSelector(state => state.global);
    const {bookType, initMessage, isBot, propose, toScroll, setPropose} = props
    const {symbol} = currenciesChat;

    const newProposition = async (reservationId) => {
        if (!newPropose) return;

        setLoad(true);
        setPropose(true);

        if (isBot) {
            await addBotStartingMessage();
        }

        toScroll?.current?.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});

        try {
            const resp = await auditor.put(
                apis.eventNewProposition(reservationId),
                {new_proposition: newPropose}
            );
            await handleSuccessResponse(resp);
        } catch (error) {
            handleError(error);
        }
    };

    const addBotStartingMessage = async () => {
        dispatch(switchBotLoadingAnswer(true));
        const tmp = [
            ...messagesList,
            <ChatMsg
                side={"right"}
                avatar={<SmartToyIcon/>}
                messages={[
                    `Je fais une nouvelle proposition de ${newPropose.toString()} ${symbol}`,
                ]}
            />,
        ];
        await dispatch(addBotMessageList(tmp));
    };

    const handleSuccessResponse = async (resp) => {
        await upCardEvents(resp.data, contacts, dispatch, bookType, countries);

        if (isBot) {
            await finalizeBotMessage();
        }

        await initMessage(bookType);

        setLoad(false);
        setPropose(false);

        toScroll?.current?.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
    };

    const finalizeBotMessage = async () => {
        const tmp = [
            ...messagesList,
            <ChatMsg
                avatar={<SmartToyIcon/>}
                messages={["Votre nouvelle proposition a été prise en compte."]}
            />,
        ];
        await dispatch(addBotMessageList(tmp));
        dispatch(switchBotLoadingAnswer(false));
    };

    const handleError = (error) => {
        setLoad(false);
        if (isBot) {
            dispatch(switchBotLoadingAnswer(false));
        }
        toast.error(checkErrorMessage(error).message);
    };

    return (
        <div>
            <CardHeader>
                <p className="m-auto text-left">
                    Nouvelle proposition
                </p>
            </CardHeader>
            <CardBody>
                <FormGroup className="mt-3">
                    {IPriceField(
                        'Débuter à partir de ' + contactToChat?.reservation?.minPrice + ' ' + symbol,
                        newPropose,
                        symbol,
                        (e) => changeFields(setNewPropose, e),
                        "Sera présenter à l'auditeur d'aussitôt.",
                        contactToChat?.reservation?.total_amount)}
                </FormGroup>
            </CardBody>
            <CardFooter>
                <Box sx={footerStyle}>
                    <Button
                        size="small"
                        variant="outlined"
                        aria-label="delete"
                        disabled={load || loadingAnswer}
                        onClick={() => setPropose(false)}>
                        <UndoIcon fontSize="inherit"/>
                    </Button>
                    <Button
                        size="small"
                        variant="outlined"
                        className="ml-3"
                        disabled={load || loadingAnswer}
                        endIcon={<AddIcon sx={{color: "lightGreen!important"}}/>}
                        onClick={() => newProposition(propose)}>
                        Envoyer la proposition
                    </Button>
                    <div style={{margin: 12, paddingTop: 3}} hidden={!load || !loadingAnswer}>
                        <ImpulseSpinner size={25} frontColor="#f5f5f5"/>
                    </div>
                </Box>
            </CardFooter>
        </div>
    )
}

export default NewProposition;
