const initState = {
    artistTypes: [],
    eventTypes: [],
    countries: [],
    countryLog: '',
    ipAddress: null,
    contactIdToShow: null,
    msgTabIndex: null,
    countryLogID: null,
    countryCode: null,
    discussionsLoading: true,
    sideBarExpandIcon: false,
    socket: null,
    isDev: null,
    paymentMethod: '',
    contactToChat: null,
    upComingEvent: null,
    currenciesChat: {},
    reservationsMessageList: {},
    amountDetailChat: {},
    scheduleDropDown: "dropdown",
    globalCurrencySymbol: 'EUR',
    globalCurrencyCode: 'EUR',
    countryOrangeMoneyAllowed: ['madagascar'],
};

const Global = (state = initState, action) => {
    switch (action.type) {
        case "SET_UPCOMING_EVENT":
            return {
                ...state,
                upComingEvent: action.data
            };
        case "SET_COUNTRY_CODE":
            return {
                ...state,
                countryCode: action.data
            };
        case "SET_CHAT_CURRENCIES":
            return {
                ...state,
                currenciesChat: action.data
            };
        case "SET_RESERVATIONS_MESSAGES_LIST":
            return {
                ...state,
                reservationsMessageList: action.data
            };
        case "SET_DISCUSSIONS_LOADING":
            return {
                ...state,
                discussionsLoading: action.data
            };
        case "SET_CHAT_AMOUNT_DETAILS":
            return {
                ...state,
                amountDetailChat: action.data
            };
        case "SET_MESSAGES_TAB_INDEX":
            return {
                ...state,
                msgTabIndex: action.data
            };
        case "SWAP_SIDEBAR_EXPAND_ICON":
            return {
                ...state,
                sideBarExpandIcon: action.data
            };
        case "SET_CONTACT_ID_TO_SHOW":
            return {
                ...state,
                contactIdToShow: action.data
            };
        case "SWITCH_PAYMENT_METHOD":
            return {
                ...state,
                paymentMethod: action.data
            };
        case "SET_IS_DEV":
            return {
                ...state,
                isDev: action.data
            };
        case "SET_SCHEDULE_DROPDOWN":
            return {
                ...state,
                scheduleDropDown: action.data
            };
        case "SET_CONTACT_TO_CHAT":
            return {
                ...state,
                contactToChat: action.data
            };
        case "ADD_SOCKET":
            return {
                ...state,
                socket: action.data
            };
        case "ADD_EVENT_TYPES":
            return {
                ...state,
                eventTypes: action.data
            };
        case "ADD_ARTIST_TYPES":
            return {
                ...state,
                artistTypes: action.data
            };
        case "ADD_IP_ADDRESS":
            return {
                ...state,
                ipAddress: action.data
            };
        case "ADD_COUNTRIES":
            return {
                ...state,
                countries: action.data
            };
        case "ADD_COUNTRY_LOG":
            return {
                ...state,
                countryLog: action.data
            };
        case "ADD_COUNTRY_LOG_ID":
            return {
                ...state,
                countryLogID: action.data
            };
        case "ADD_GLOBAL_CURRENCY_SYMBOL":
            return {
                ...state,
                globalCurrencySymbol: action.data
            };
        case "ADD_GLOBAL_CURRENCY_CODE":
            return {
                ...state,
                globalCurrencyCode: action.data
            };
        default:
            return state;
    }
};

export default Global;
