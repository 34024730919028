import Privacy from "views/Privacy.js";
import Services from "views/services/Services.js";
import Questions from "./tools/Questions";
import AboutUs from "./views/AboutUs";
import Agenda from "./views/Agenda/Agenda";
import Contact from "./views/contactCards/Contact";
import Archives from "./views/DashBoard/Archives";
import ArtistContacted from "./views/DashBoard/ArtistContacted";
import ContactDemand from "./views/DashBoard/ContactDemand";
import KantoBizCustomCards from "./views/DashBoard/KantoBizCustomCards";
import NotFound from "./views/NotFound";
import ArtistProfile from "./views/profile/ArtistProfile";
import ArchiveIcon from '@mui/icons-material/Archive';
import Wallet from "./views/DashBoard/Wallet";
import Service from "./views/services/Service";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SouthWestIcon from '@mui/icons-material/SouthWest';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import BallotIcon from '@mui/icons-material/Ballot';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';


export const routes = (role) => {

    const isAuditor = !role ? true : role === "professional_auditor";

    return [
        {
            path: "/",
            name: "Les Kantobiz's",
            desc: "Entrez en contact avec un artiste",
            icon: (active) =>
                <SpaceDashboardIcon sx={{marginTop: 0.3, color: active ? "#b9f6ca !important" : "gray !important"}}/>,
            component: <Services/>,
            requireOAuth: false,
            hidden: false,
        },
        {
            path: "agenda",
            name: "Mon agenda",
            desc: "Mon agenda",
            icon: (active) =>
                <CalendarMonthIcon sx={{marginTop: 0.3, color: active ? "#b9f6ca !important" : "gray !important"}}/>,
            component: <Agenda/>,
            requireOAuth: true,
            hidden: false,
        },
        {
            path: "",
            name: "Tableau de bord",
            desc: "",
            icon: (active) =>
                <DashboardIcon sx={{marginTop: 0.3, color: active ? "#b9f6ca !important" : "gray !important"}}/>,
            requireOAuth: true,
            hidden: false,
            component: [{
                path: "artist-contact-demand",
                name: "Demandes de contact",
                desc: "Mes demandes de contact",
                icon: (active) =>
                    <SouthWestIcon sx={{marginTop: 0.3, color: active ? "#b9f6ca !important" : "gray !important"}}/>,
                component: <ContactDemand/>,
                requireOAuth: true,
                hidden: isAuditor,
            }, {
                path: "artist-contacted-demand",
                name: "Artistes contactés",
                desc: "Mes prises de contact",
                icon: (active) =>
                    <SouthWestIcon
                        sx={{
                            marginTop: 0.3,
                            color: active ? "#b9f6ca !important" : "gray !important",
                            transform: 'rotate(180deg)'
                        }}/>,
                component: <ArtistContacted/>,
                requireOAuth: true,
                hidden: !role,
            }, {
                path: "my-kantobiz-cards",
                name: "Mes fiches KantoBiz",
                desc: "Mes fiches KantoBiz",
                icon: (active) =>
                    <BallotIcon sx={{marginTop: 0.3, color: active ? "#b9f6ca !important" : "gray !important"}}/>,
                component: <KantoBizCustomCards/>,
                requireOAuth: true,
                hidden: isAuditor,
            }, {
                path: "my-kantobiz-transfers",
                name: "Compte & Transferts",
                desc: "Compte & transferts",
                icon: (active) =>
                    <AccountBalanceWalletIcon
                        sx={{marginTop: 0.3, color: active ? "#b9f6ca !important" : "gray !important"}}/>,
                component: <Wallet/>,
                requireOAuth: true,
                hidden: isAuditor,
            }, {
                path: "archives",
                name: "Mes Archives",
                desc: "Contact archivé(s)",
                icon: (active) =>
                    <ArchiveIcon
                        sx={{
                            marginTop: 0.3,
                            color: active ? "#b9f6ca !important" : "gray !important",
                            transform: 'rotate(180deg)'
                        }}/>,
                component: <Archives/>,
                requireOAuth: true,
                hidden: !role,
            }],
        },
        {
            path: "artists/pages/:id",
            name: "Page artiste",
            icon: "nc-icon nc-layout-11",
            component: <ArtistProfile/>,
            requireOAuth: false,
            hidden: true,
        },
        {
            path: "artist/service/:id",
            name: "Fiche KantoBiz",
            icon: "nc-icon nc-layout-11",
            component: <Service/>,
            requireOAuth: false,
            hidden: true,
        },
        {
            path: "artist/contact/service/:id",
            name: "Prise de contact",
            icon: "nc-icon nc-layout-11",
            component: <Contact/>,
            requireOAuth: false,
            hidden: true,
        },
        {
            path: "about",
            name: "A propos de nous",
            icon: "nc-icon nc-compass-05",
            component: <AboutUs/>,
            requireOAuth: false,
            hidden: true,
        },
        {
            path: "faq",
            name: "Foires aux questions",
            icon: "nc-icon nc-tap-01",
            component: <Questions/>,
            requireOAuth: false,
            hidden: true,
        },
        {
            path: "privacy",
            name: "Les mentions légales",
            icon: "nc-icon nc-paper",
            component: <Privacy/>,
            requireOAuth: false,
            hidden: true,
        },
        {
            path: "*",
            name: "Page non trouvée",
            icon: "nc-icon nc-layout-11",
            component: <NotFound/>,
            requireOAuth: false,
            hidden: true,
        },
    ];
}
