import React from "react";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import TextCard from "./TextCard";


function NoDataList(text_message, btn) {
    return (
        <div className="vh-25 d-flex justify-content-center align-items-center">
            <div className="m-3 justify-content-center">
                <div className="row mb-2 justify-content-center">
                    {btn ? btn : <DoNotDisturbIcon sx={{ color: 'silver!important' }} />}
                </div>
                <div className="row justify-content-center">
                    <TextCard
                        overline={text_message}
                        sx={{ textAlign: "center", py: 1 }}
                    />
                </div>
            </div>
        </div>
    );
}

export default NoDataList;
