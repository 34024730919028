import React, {useState, useRef} from "react";
import Avatar from '@mui/material/Avatar';
import Box from "@mui/material/Box";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import Drawer from '@mui/material/Drawer';
import CloseIcon from "@mui/icons-material/Close";
import {IconButton} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Modal from "react-bootstrap/Modal";
import {useSelector} from "react-redux";
import {CardBody, CardFooter, Nav} from "reactstrap";
import CardHeaderCustom from "../../tools/CardHeaderCustom";
import {btnStyle, footerStyle, useWindowSize} from "../../tools/Tools";
import NewArtist from "../../views/NewArtist";
import Certification from "../../views/profile/Certifications/Certification";
import Profile from "../../views/profile/Profile";

function Account() {

    const width = useWindowSize();
    const certifyRef = useRef();
    const [toArtist, setToArtist] = useState(false);
    const profile = useSelector(state => state.profile.profileData);
    const [process, setProcess] = useState(false);
    const [state, setState] = useState({right: false});
    const [editCertification, setEditCertification] = useState(false);

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({...state, [anchor]: open});
    };

    const closeCertify = () => {
        document.getElementById("profile_open").click()
        setEditCertification(false)
    }

    const sendToValidate = () => {
        setProcess(true)
        certifyRef?.current?.validate()
        setTimeout(() => setProcess(false), 5000);
    }

    return (
        <Nav navbar>

            <Modal size="md" show={toArtist}>
                <NewArtist toClose={() => setToArtist(false)} setProcess={setProcess}/>
                <CardFooter>
                    <Box sx={footerStyle}>
                        <Tooltip title="Fermer cette onglet" placement="top">
                            <IconButton
                                disabled={process}
                                size="small"
                                aria-label="settings"
                                sx={btnStyle('red')}
                                onClick={() => setToArtist(false)}>
                                <small>Fermer&nbsp;</small>
                                <CloseIcon sx={{color: "red!important"}}/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                </CardFooter>
            </Modal>

            <Modal size="lg" show={editCertification}>
                <CardHeaderCustom cardTitle="Certification" closeModal={() => closeCertify()}/>

                <CardBody>
                    <Certification ref={certifyRef} closeCertification={() => closeCertify()}/>
                </CardBody>

                <CardFooter>
                    <Box sx={footerStyle}>
                        <Tooltip title="Valider" placement="top">
                            <IconButton
                                size="small"
                                disabled={process}
                                aria-label="settings"
                                sx={btnStyle('lightGreen')}
                                onClick={() => sendToValidate()}>
                                <small>Envoyer ma demande&nbsp;</small>
                                <DoneAllIcon sx={{color: "lightGreen!important"}}/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                </CardFooter>
            </Modal>

            <Tooltip title="Mon profil" placement="bottom">
                <IconButton id="profile_open" sx={{p: '10px'}} onClick={toggleDrawer('right', true)}>
                    <div className="avatar-container">
                        {profile?.photo && profile?.photo !== "null"
                            ? <Avatar src={profile?.photo} sx={{width: 25, height: 25}}/>
                            : <Avatar
                                sx={{
                                    width: 25,
                                    height: 25,
                                    fontSize: "1rem",
                                    backgroundColor: "linear-gradient(to bottom right, #feac5e, #c779d0, #4bc0c8)"
                                }}>
                                {profile?.artist_name?.substring(0, 1).toUpperCase() || profile?.name?.substring(0, 1).toUpperCase()}
                            </Avatar>}
                    </div>
                </IconButton>
            </Tooltip>

            <button hidden id="certification_button" onClick={() => setEditCertification(true)}/>

            <Drawer
                anchor={'right'}
                open={state['right']}
                onClose={toggleDrawer('right', false)}
            >

                <Box sx={{width: width < 500 ? width : 450}} role="presentation">
                    <Profile setToArtist={setToArtist} toCloseProfile={() => setState({...state, 'right': false})}/>
                </Box>
            </Drawer>

        </Nav>
    );
}

export default Account;
