import Avatar from '@mui/material/Avatar';
import ListItem from "@mui/material/ListItem";
import Box from '@mui/material/Box';
import {Row} from "reactstrap";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import StarIcon from "@mui/icons-material/Star";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import InputBase from "@mui/material/InputBase";
import React, {useState, useEffect, useCallback} from "react";
import toast from "react-hot-toast";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {CardBody, CardFooter, CardHeader, Spinner} from "reactstrap";
import InfoCard from "../../../tools/InfoCard";
import NoDataList from "../../../tools/NoDataList";
import {checkErrorMessage} from "../../../tools/Tools";

function ArtistSearch(props) {

    const navigate = useNavigate();
    const {setOpened, setLoading} = props;
    const [loader, setLoader] = useState(false)
    const [artistResults, setArtistResults] = useState([])
    const auditor = useSelector(state => state.axios.auditor);
    const [debounceTimeout, setDebounceTimeout] = useState(null);

    const searchArtists = (e) => {
        let tmp;
        try {
            tmp = e.target.value;
        } catch {
            tmp = e;
        }

        let value = tmp.replace(/[^a-zA-Z0-9 ]/g, "");

        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }

        if (value) {
            const timeout = setTimeout(async () => {
                setLoader(true);
                setLoading(true);
                try {
                    const resp = await auditor.get("artist_services/artist_search/" + value);
                    setArtistResults(resp.data);
                } catch (error) {
                    toast.error(checkErrorMessage(error).message);
                } finally {
                    setLoading(false);
                    setLoader(false);
                }
            }, 500); // Ajustez le délai de 500 ms selon vos besoins

            setDebounceTimeout(timeout);
        } else {
            setArtistResults([]);
            setLoading(false);
            setLoader(false);
        }
    };

    const handleUserKeyPress = useCallback(event => {
        const {key, keyCode} = event;
        if (keyCode === 27 && key === 'Escape') {
            setOpened(false);
        }
    }, [setOpened]);

    useEffect(() => {
        window.addEventListener("keydown", handleUserKeyPress);
        return () => {
            window.removeEventListener("keydown", handleUserKeyPress);
        };
    }, [handleUserKeyPress]);

    return (
        <div>
            <CardHeader>
                <InputBase
                    sx={{
                        width: '100%',
                        paddingX: 2,
                        paddingY: 0.75,
                        mt: 1,
                    }}
                    placeholder="Trouver un artiste ..."
                    className="radius-1"
                    onChange={(e) => searchArtists(e)}
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon/>
                        </InputAdornment>
                    }
                    endAdornment={
                        <InputAdornment position="end">
                            <Button variant="outlined" onClick={() => setOpened(false)} endIcon={<CloseIcon/>}>
                                ESC
                            </Button>
                        </InputAdornment>
                    }
                    inputProps={{'aria-label': 'Trouver un artiste'}}
                />
            </CardHeader>
            <CardBody>
                <Box sx={{height: 400}}>
                    <Box display="flex" flexDirection="column" gap={2} width="100%">
                        {loader
                            ? null
                            : !artistResults?.length && NoDataList("Aucun Résultat")}

                        {loader
                            ? <div className="row justify-content-center mt-5">
                                <Spinner/>
                            </div>
                            : artistResults?.length
                                ? <Box sx={{maxHeight: 400}} className="overflow-auto">
                                    {artistResults.map((artist, index) =>
                                        <ListItem
                                            sx={{
                                                borderRadius: 1,
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                            key={index}
                                            onClick={() => {
                                                setOpened(false);
                                                navigate(`/artists/pages/${artist.id}`);
                                            }}
                                        >
                                            <Box display="flex" alignItems="center" sx={{gap: 2}}>
                                                {/* Avatar */}
                                                <Avatar
                                                    src={artist.photo}
                                                    sx={{
                                                        width: 40,
                                                        height: 40,
                                                        transition: 'transform 0.3s ease',
                                                        '&:hover': {transform: 'scale(1.1)'},
                                                    }}
                                                />
                                                {/* InfoCard */}
                                                <InfoCard
                                                    caption={artist.artist_name}
                                                    subtitle={`@${artist.artist_type}`}
                                                />
                                            </Box>

                                            {/* Section de droite avec la note */}
                                            <Box display="flex" alignItems="center" sx={{gap: 1}}>
                                                <Box component="span" sx={{fontSize: '0.675rem', fontWeight: 'bold'}}>
                                                    {artist.rating}
                                                </Box>
                                                <StarIcon fontSize="small" sx={{color: 'gold'}}/>
                                            </Box>
                                        </ListItem>
                                    )}
                                </Box>
                                : null}
                    </Box>
                </Box>
            </CardBody>
            <CardFooter style={{padding: 10}}>
                <Row
                    style={{textAlign: 'right', paddingRight: 10}}
                    className="justify-content-end">
                    <small>Search by <strong>KantoBiz</strong></small>
                </Row>
            </CardFooter>
        </div>
    )
}

export default ArtistSearch;
