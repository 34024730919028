import React, {useState, useEffect} from "react";

let timeOutId = 0;

const Timer = props => {

    const {timerData, left, label} = props;
    const [countdownTime, setCountdownTime] = useState(0);
    const [timeLeft, setTimeLeft] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    });

    const calculateTimeLeft = () => {
        let currDate = new Date().getTime();
        let difference = countdownTime - currDate;
        let timeDiff = {
            days: difference > 0 ? Math.floor(difference / (1000 * 60 * 60 * 24)) : 0,
            hours: difference > 0 ? Math.floor((difference / (1000 * 60 * 60)) % 24) : 0,
            minutes: difference > 0 ? Math.floor((difference / 1000 / 60) % 60) : 0,
            seconds: difference > 0 ? Math.floor((difference / 1000) % 60) : 0
        };

        timeDiff.days = timeDiff.days < 10 ? `0${timeDiff.days}` : `${timeDiff.days}`;
        timeDiff.hours = timeDiff.hours < 10 ? `0${timeDiff.hours}` : `${timeDiff.hours}`;
        timeDiff.minutes = timeDiff.minutes < 10 ? `0${timeDiff.minutes}` : `${timeDiff.minutes}`;
        timeDiff.seconds = timeDiff.seconds < 10 ? `0${timeDiff.seconds}` : `${timeDiff.seconds}`;

        return timeDiff;
    };

    useEffect(() => {

        let expectedTime = new Date().getTime();
        let {days, hours, minutes, seconds} = timerData;
        expectedTime += days > 0 ? days * 86400000 : 0;
        expectedTime += hours > 0 ? hours * 3600000 : 0;
        expectedTime += minutes > 0 ? minutes * 60000 : 0;
        expectedTime += seconds > 0 ? seconds * 1000 : 0;
        setCountdownTime(expectedTime);

        return () => {
            expectedTime = 0;
        };
    }, [timerData]);

    useEffect(() => {

        if (countdownTime > new Date().getTime()) {
            timeOutId = setTimeout(() => {
                setTimeLeft(calculateTimeLeft());
            }, 1000);
        }

        return () => {
            clearTimeout(timeOutId);
        };
    });

    return (
        <div className="d-flex align-items-center">
            {label && <span>{label}&nbsp;</span>}
            <div
                className={
                    left
                        ? "d-flex justify-content-start"
                        : "d-flex justify-content-center"
                }
                style={{display: "flex", alignItems: "center", gap: "5px"}}>
                {timeLeft.days !== '00' && (
                    <small style={{ color: '#95b35d', display: "inline-flex", alignItems: "center" }}>
                        {timeLeft.days}
                        <span>j&nbsp;:</span>
                    </small>
                )}
                {timeLeft.hours !== '00' && (
                    <small style={{ color: '#95b35d', display: "inline-flex", alignItems: "center" }}>
                        {timeLeft.hours}
                        <span>h&nbsp;:</span>
                    </small>
                )}
                <small style={{ color: '#95b35d', display: "inline-flex", alignItems: "center" }}>
                    {timeLeft.minutes}
                    <span>m&nbsp;:</span>
                </small>
                <small style={{ color: '#95b35d', display: "inline-flex", alignItems: "center" }}>
                    {timeLeft.seconds}
                    <span>s</span>
                </small>
            </div>
        </div>
    );
};

export default Timer;
