import {Box} from "@mui/material";
import Button from "@mui/material/Button";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import AddCardIcon from "@mui/icons-material/AddCard";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import LaunchIcon from "@mui/icons-material/Launch";
import UndoIcon from "@mui/icons-material/Undo";
import Tooltip from "@mui/material/Tooltip";
import {PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import React, {useState} from "react";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {ImpulseSpinner} from "react-spinners-kit";
import {CardBody, CardFooter, CardHeader, Row} from "reactstrap";
import apis from "../../config/apis";
import {
    addBotMessageList,
    switchBotMsgProcess,
    switchBotLoadingAnswer,
    switchLoadingDiscussions,
    switchPaymentMethod
} from "../../reducer";
import InfoCard from "../../tools/InfoCard";
import TextCard from "../../tools/TextCard";
import {
    cardValidation,
    checkErrorMessage, clearContactToChat, customButton, footerStyle,
    orangeMoneyBtn,
    upCardEvents,
    useWindowSize,
} from "../../tools/Tools";
import Timer from "../../tools/Countdown/timer";

const indicatorColors = ['#d93025', '#1a73e8', '#188038', '#e37400'];

function CheckOutEvent({close, isBot}) {

    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const width = useWindowSize();
    const {messagesList} = useSelector(state => state.chatBot);
    const [tabIndex, setTabIndex] = useState(0);
    const [orange, setOrange] = useState(false);
    const [process, setProcess] = useState(false);
    const [orangeResponse, setOrangeResponse] = useState({});
    const auditor = useSelector(state => state.axios.auditor);
    const contacts = useSelector(state => state.profile.contacts);
    const {
        countryLog,
        isDev,
        countries,
        amountDetailChat,
        paymentMethod,
        currenciesChat,
        countryOrangeMoneyAllowed,
        contactToChat
    } = useSelector(state => state.global);
    const {symbol, code} = currenciesChat;
    const {serviceFee, ttc} = amountDetailChat
    const tabsStyles = {
        "& .MuiTabs-indicator": {
            background: indicatorColors[tabIndex],
        }
    };

    const tabItem2Styles = {
        color: indicatorColors[1],
        "&.Mui-selected": {
            color: "white",
            backgroundColor: indicatorColors[1],
        },
    };

    const closeChat = async () => {
        await clearContactToChat(dispatch)
        dispatch(switchBotMsgProcess(true))
        isBot && dispatch(addBotMessageList([messagesList[0]]))
    }

    const auditorToPay = async (stripe_token) => {
        await auditor.put(apis.auditorToPaid + "/" + contactToChat?.reservation.id, {stripe_token}).then(async (resp) => {
            await upCardEvents(resp.data, contacts, dispatch, 'book', countries)
            isBot && dispatch(switchBotLoadingAnswer(false))
            setProcess(false)
            await closeChat()
            await dispatch(switchLoadingDiscussions(true))
            toast("Votre paiement à été un succès." +
                "\nL'événement a été ajouté à votre agenda ainsi qu'à celui de l'artiste.", {
                icon: <ThumbUpIcon sx={{color: "lightGreen!important"}}/>,
            });
        }).catch((error) => {
            isBot && dispatch(switchBotLoadingAnswer(false))
            setProcess(false)
            toast.error(checkErrorMessage(error).message)
        })
    }

    const checkValidationOrangeMoney = async () => {
        setProcess(true);
        let data = {
            "amount": isDev ? 1 : ttc,
            "order_id": orangeResponse['order_id'],
            "pay_token": orangeResponse['pay_token']
        }
        auditor.post("payments/orange_money_status", data).then(async (resp) => {
            setProcess(false);
            let status = resp.data.status
            if (['INITIATED', 'PENDING'].includes(status)) {
                toast("Le paiement est en cours, je vous prie d'attendre jusqu’à la fin", {duration: 4000});
            } else if (['EXPIRED', 'FAILED'].includes(status)) {
                toast.error("Le paiement a échoué, veuillez recommencer.")
                await setOrange(false)
            } else {
                await setProcess(true)
                await setOrange(false)
                await auditorToPay({
                    "payment_url": orangeResponse['payment_url'],
                    "reference": orangeResponse['order_id']
                })
            }
        }).catch((error) => {
            toast.error(checkErrorMessage(error).message)
        })
    }

    const orangeMoney = () => {
        setProcess(true)
        let data = {"amount": ttc, "currency": code}
        auditor.post("payments/orange_money", data).then(async (resp) => {
            setProcess(false)
            await setOrangeResponse(resp.data)
            await setOrange(true)
        }).catch((error) => {
            setProcess(false)
            isBot && dispatch(switchBotLoadingAnswer(false))
            toast.error(checkErrorMessage(error).message)
        })
    }

    const onChangePaymentMethod = (e) => {
        let paymentType = e.value.type
        dispatch(switchPaymentMethod(paymentType))
    }

    const auditorValidation = async () => {
        isBot && dispatch(switchBotLoadingAnswer(true))
        setProcess(true)
        if (tabIndex) {
            orangeMoney()
        } else {

            if (paymentMethod === 'paypal') {
                localStorage.setItem('contactToChat', JSON.stringify(contactToChat));
            }

            let return_url = window.location.protocol + "//" + window.location.host + '/artist-contacted-demand'
            cardValidation(stripe, elements, return_url).then(async stripe_token => {
                if (stripe_token) {
                    await auditorToPay(stripe_token)
                } else {
                    isBot && dispatch(switchBotLoadingAnswer(false))
                    setProcess(false)
                }
            }).catch(error => {
                isBot && dispatch(switchBotLoadingAnswer(false))
                setProcess(false)
                toast.error(checkErrorMessage(error).message)
            })
        }
    }

    return (
        orange
            ? <div>
                <CardHeader>
                    <Row className="justify-content-center">
                        <Timer
                            label="Expire dans"
                            timerData={{days: 0, hours: 0, minutes: 10, seconds: 0}}/>
                    </Row>
                </CardHeader>
                <iframe
                    className="mt-3 width-full"
                    height={width < 500 ? 505 : 700}
                    title="orange money payment"
                    src={orangeResponse.payment_url}/>
                <CardFooter>
                    <Row className="justify-content-center">
                        <small>
                            Appuyez sur "Continuer" après avoir confirmé votre paiement.
                        </small>
                    </Row>
                    <Row className="justify-content-end mt-2">
                        <Tooltip title="Annuler la transaction" placement="top">
                            <Button
                                size="small"
                                variant="outlined"
                                color={'primary'}
                                aria-label="delete"
                                disabled={process}
                                onClick={() => setOrange(false)}>
                                <UndoIcon fontSize="inherit"/>
                            </Button>
                        </Tooltip>
                        <Tooltip title="Envoyer la prise contact après validation" placement="top">
                            <Button
                                size="small"
                                variant="outlined"
                                color={'primary'}
                                className="ml-3"
                                disabled={process}
                                endIcon={<DoneAllIcon sx={{color: "lightGreen!important"}}/>}
                                onClick={() => checkValidationOrangeMoney()}>
                                Continuer
                            </Button>
                        </Tooltip>
                    </Row>
                </CardFooter>
            </div>
            : <div>
                <CardHeader>
                    <p className="pt-1 m-auto text-center">
                        Méthode(s) de Paiement
                    </p>
                    <hr/>
                    <ul className="list-group">
                        <li className="list-group-item d-flex justify-content-between">
                            <div className="text-left">
                                    <pre className="my-0 pl-1 pr-1 text-capitalize rounded">
                                        {contactToChat?.reservation?.artist_name}&nbsp;
                                        <small>({contactToChat?.reservation?.event})</small>
                                    </pre>
                                <small className="text-muted ml-1">Frais de prestation</small>
                            </div>
                            <small className="text-red">
                                {contactToChat?.reservation?.total_amount + ' ' + symbol}
                            </small>
                        </li>
                        {contactToChat?.reservation?.travel_expenses
                            ? <li className="list-group-item d-flex justify-content-between">
                                <div className="text-left">
                                        <pre className="my-0 pl-1 pr-1 rounded">
                                            Frais de déplacement
                                        </pre>
                                </div>
                                <small className="text-red">
                                    {contactToChat?.reservation?.travel_expenses + ' ' + symbol}
                                </small>
                            </li>
                            : null}

                        <li className="list-group-item d-flex justify-content-between">
                            <div className="text-left">
                                    <pre className="my-0 pl-1 pr-1 rounded">
                                        Total&nbsp;
                                        <small>TTC</small>
                                    </pre>
                            </div>
                            <strong className="text-red">
                                {ttc + ' ' + symbol}
                            </strong>
                        </li>

                        <InfoCard
                            caption={`Dont ${serviceFee + symbol} inclus de frais de service.`}
                        />

                        <InfoCard
                            caption="Addresse de facturation"
                            subtitle={contactToChat?.reservation?.address}
                            sx={{mt: 2}}
                        />
                        <hr/>

                    </ul>
                </CardHeader>
                <CardBody className="pt-0">
                    {isDev || countryOrangeMoneyAllowed.includes(countryLog.toLowerCase())
                        ? <Tabs
                            sx={tabsStyles}
                            value={tabIndex}
                            className="mb-3"
                            onChange={(e, index) => setTabIndex(index)}
                            TabIndicatorProps={{
                                children: <div className={`MuiIndicator-${tabIndex}`}/>,
                            }}
                        >
                            <Tab
                                sx={tabItem2Styles}
                                disableTouchRipple
                                label={
                                    <div className={'MuiTabItem-labelGroup'}>
                                        <div className={'MuiTabItem-subLabel'}>
                                            Visa, Mastercard, paypal ...
                                        </div>
                                    </div>
                                }
                                icon={<AddCardIcon/>}
                            />
                            {isDev
                                ? orangeMoneyBtn(tabItem2Styles)
                                : countryOrangeMoneyAllowed.includes(countryLog.toLowerCase())
                                    ? orangeMoneyBtn(tabItem2Styles)
                                    : null}
                        </Tabs>
                        : null}
                    {tabIndex === 0
                        ? <div className='mb-4'>
                            <PaymentElement onChange={(e) => onChangePaymentMethod(e)}/>
                        </div>
                        : <div className="card p-2 rounded-lg">
                            <TextCard
                                overline={
                                    <div className="pt-2">Orange Money
                                        <hr/>
                                    </div>}
                                body={
                                    <div className="row mt-0 pt-0 ml-1 mr-1">
                                        <div className="col-md-2 pt-2">
                                            <LaunchIcon sx={{color: 'gray !important'}} fontSize="large"/>
                                        </div>
                                        <div className="col-md-10 text-justify text-muted">
                                            En sélectionnant Orange Money, nous vous redirigerons vers une page sécurisée
                                            pour valider les étapes suivantes.
                                        </div>
                                    </div>
                                }
                            />
                        </div>}
                </CardBody>
                <CardFooter>
                    <Box sx={footerStyle}>
                        <Button
                            size="small"
                            variant="outlined"
                            sx={customButton}
                            aria-label="delete"
                            disabled={process}
                            onClick={() => close()}>
                            <UndoIcon fontSize="inherit"/>
                        </Button>
                        <Button
                            size="small"
                            variant="outlined"
                            className="ml-3"
                            hidden={process}
                            sx={customButton}
                            endIcon={<DoneAllIcon sx={{color: "lightGreen!important"}}/>}
                            onClick={() => auditorValidation()}>
                            {tabIndex ? "Accéder à orange money" : "Finaliser"}
                        </Button>
                        <div style={{margin: 12, paddingTop: 3}} hidden={!process}>
                            <ImpulseSpinner size={25} frontColor="#f5f5f5"/>
                        </div>
                    </Box>
                </CardFooter>
            </div>
    )
}

export default CheckOutEvent;
