import CloseIcon from "@mui/icons-material/Close";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import {Box, FormControl, IconButton} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React, {useCallback, useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import toast from 'react-hot-toast';
import {useDispatch, useSelector} from "react-redux";
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {CardFooter, FormGroup, Row, Spinner} from "reactstrap";
import "../../assets/css/keyValidation.css";
import {addAuthCredentials} from "../../reducer";
import CardHeaderCustom from "../../tools/CardHeaderCustom";
import {
    AntSwitch, btnStyle,
    changeFields,
    checkErrorMessage, createSession, footerStyle,
    ITextField,
    registerValidation
} from "../../tools/Tools";
import NewArtist from "../NewArtist";
import Privacy from "../Privacy";
import LoginGoogle from "./Google";
import Validation from "./Validation";

function Register(props) {

    const {closeModal} = props
    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [rules, setRules] = useState(false);
    const [process, setProcess] = useState(false);
    const [toArtist, setToArtist] = useState(false);
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [rulesPop, setRulesPop] = useState(false);
    const currency = useSelector(state => state.global.globalCurrencyCode);
    const auditorForm = useSelector(state => state.axios.auditorForm);
    const [verificationCode, setVerificationCode] = useState(false);
    const ipAddress = useSelector(state => state.global.ipAddress);

    const register = useCallback(async () => {
        setLoading(true)
        let validator = registerValidation(name, email, password, rules, true)
        if (validator['error']) {
            setLoading(false)
            toast.error(validator['message']);
        } else {
            let bodyFormData = new FormData();
            bodyFormData.append('currency', currency);
            bodyFormData.append('name', name);
            bodyFormData.append('email', email);
            bodyFormData.append('password', password);
            await auditorForm.post('users/register', bodyFormData).then(response => {
                setLoading(false)
                dispatch(addAuthCredentials(response.data));
                setVerificationCode(true)
                toast.success('Inscription validé, veuillez maintenant valider votre compte.')
            }).catch(async (error) => {
                setLoading(false)
                let message = checkErrorMessage(error).message;
                toast.error(message);
            })
        }
    }, [auditorForm, dispatch, currency, name, email, password, rules])

    const handleUserKeyPress = useCallback(event => {
        const {key, keyCode} = event;

        if ((toArtist || rulesPop || verificationCode) && keyCode === 13 && key === 'Enter') {
            event.preventDefault()
        } else if (keyCode === 27 && key === 'Escape' && !toArtist && !rulesPop && !verificationCode) {
            closeModal()
        } else if (keyCode === 13 && key === 'Enter' && !toArtist && !rulesPop && !verificationCode) {
            register().then(r => null)
        }

    }, [closeModal, register, rulesPop, toArtist, verificationCode]);

    useEffect(() => {
        window.addEventListener("keydown", handleUserKeyPress);

        return () => {
            window.removeEventListener("keydown", handleUserKeyPress);
        };
    }, [handleUserKeyPress]);

    return (
        <div className="justify-content-center">

            <Modal size="md" show={toArtist}>
                <NewArtist toClose={async () => {
                    closeModal()
                    setToArtist(false)
                }} setProcess={setProcess}/>
                <CardFooter>
                    <Box sx={footerStyle}>
                        <Tooltip title="Fermer cette onglet" placement="top">
                            <IconButton
                                size="small"
                                disabled={process}
                                aria-label="settings"
                                sx={btnStyle('gray')}
                                onClick={() => setToArtist(false)}>
                                <CloseIcon sx={{color: "gray!important"}}/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                </CardFooter>
            </Modal>

            <Modal size="md" show={rulesPop}>

                <div>
                    <h6 className="mt-3 text-center">
                        CONDITIONS GÉNÉRALES D'UTILISATION ET DE VENTE
                    </h6>
                    <hr/>
                </div>

                <Privacy toModal/>

                <CardFooter>
                    <Box sx={footerStyle}>
                        <Tooltip title="Fermer cette onglet" placement="top">
                            <IconButton
                                aria-label="settings"
                                size="small"
                                sx={btnStyle('gray')}
                                onClick={() => setRulesPop(false)}>
                                <CloseIcon sx={{color: "gray!important"}}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Accepter" placement="top">
                            <IconButton
                                size="small"
                                aria-label="settings"
                                sx={btnStyle('lightGreen')}
                                onClick={() => {
                                    setRules(true)
                                    setRulesPop(false)
                                }}>
                                <small>Accepter&nbsp;</small>
                                <DoneAllIcon sx={{color: "lightGreen!important"}}/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                </CardFooter>
            </Modal>

            {!verificationCode &&
                <CardHeaderCustom
                    cardTitle="Créer mon compte KantoBiz"
                    disabled={loading || verificationCode}
                    closeModal={() => closeModal()}>
                    <Row className="justify-content-start pl-1">
                        <Stack direction="row" spacing={1} alignItems="center">
                            <AntSwitch
                                checked={toArtist}
                                onChange={() => setToArtist(true)}
                                inputProps={{'aria-label': 'ant design'}}/>
                            <Typography variant="caption">Devenir un artiste kantobiz</Typography>
                        </Stack>
                    </Row>
                </CardHeaderCustom>}

            <div className="mb-3">
                {!verificationCode ?
                    <div className="mx-4 px-1 mt-2">

                        <FormGroup>
                            {ITextField('Nom', null, name, (e) => changeFields(setName, e))}
                        </FormGroup>
                        <FormGroup>
                            {ITextField('Email', null, email, (e) => changeFields(setEmail, e))}
                        </FormGroup>
                        <FormControl variant="standard" fullWidth>
                            {ITextField('Mot de passe', 'password', password, (e) => changeFields(setPassword, e))}
                        </FormControl>

                        <Row className="justify-content-center mt-4">
                            <div className="material-switch cursor-pointer text-center">
                                <input type="checkbox"
                                       disabled={loading || verificationCode}
                                       onChange={() => setRules(!rules)}
                                       checked={rules}/>
                                <small onClick={() => setRulesPop(true)}
                                       className="text-monospacecursor-pointer border-bottom ml-1 text-muted">
                                    Lu et j'accepte les conditions d'utilisation
                                </small>
                            </div>
                        </Row>
                    </div> :
                    <Validation email={email} closeModal={closeModal}/>}
            </div>

            <CardFooter>
                <Box sx={footerStyle}>
                    <Tooltip title="S'inscrire avec Google" placement="top">
                        <LoginGoogle
                            LogIn
                            _setLoad={setLoading}
                            callBack={(data) => createSession(data, dispatch, ipAddress, setLoading, closeModal)}/>
                    </Tooltip>
                    <Tooltip title="S'inscrire" placement="top">
                        <IconButton
                            size="small"
                            aria-label="settings"
                            sx={btnStyle('lightGreen')}
                            disabled={loading || verificationCode}
                            onClick={() => register().then(() => setLoading(false))}>
                            <small>S'inscrire&nbsp;</small>
                            {loading
                                ? <Spinner size="sm"/>
                                : <DoneAllIcon sx={{color: "lightGreen!important"}}/>}
                        </IconButton>
                    </Tooltip>
                </Box>
            </CardFooter>

        </div>
    );
}

export default Register;
