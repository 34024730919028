import AddTaskIcon from '@mui/icons-material/AddTask';
import VerifiedIcon from "@mui/icons-material/Verified";
import Tooltip from '@mui/material/Tooltip';
import React, {useEffect, useState} from "react";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Spinner} from "reactstrap";
import CardHeader from "@mui/material/CardHeader";
import {Box, IconButton, Typography} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Avatar from '@mui/material/Avatar';
import Add from '@mui/icons-material/Add';
import {insertProfileChecked} from "../../tools/Tools";

const useStyles = makeStyles(() => ({
    action: {
        backgroundColor: '#fff',
        boxShadow: '0 1px 4px 0 rgba(0,0,0,0.12)',
        '&:hover': {
            backgroundColor: '#fff',
            color: '#000',
        },
    },
}));

function ArtistToFollow(props) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const styles = useStyles();
    const {id, service, artistName, checked, tag, disabled} = props;
    const auditor = useSelector(state => state.axios.auditor);
    const auth = useSelector(state => state.session.authenticated);
    const userConnectedId = useSelector(state => state.profile.userId);
    const [userDataToRead, setUserDataToRead] = useState([]);
    const [followed, setFollowed] = useState(false);
    const [loading, setLoading] = useState(true);
    const avatarStyles = {
        borderRadius: '12px',
        width: 48,
        height: 48,
    };

    const iconBtnStyles = {
        padding: 6,
        '&:hover': {
            backgroundColor: '#f5f5f5',
        },
    };

    const checkProfile = () => {
        let _id = id || service?.user_id
        auditor.get("profiles/check_other_profile/" + _id).then(async resp => {
            setUserDataToRead(resp.data['user_data']['followers'])
            await insertProfileChecked(dispatch, resp);
            /* eslint-disable-next-line array-callback-return */
            resp.data['user_data']['followers'].map((value, index) => {
                if (value.user_id === userConnectedId) {
                    setFollowed(true)
                }
            })
        }).catch((e) => null)
    }

    const FollowArtist = async () => {
        await setLoading(true)
        let _id = id || service?.user_id
        await auditor.post("admiration/admire_user/" + _id).then(() => {
            setFollowed(true)
            setLoading(false)
            let artist_name = service?.artist_name || artistName
            toast.success('Vous avez suivi ' + artist_name)
            try {
                setUserDataToRead({...userDataToRead?.push({id: userConnectedId})})
            } catch (e) {
                //
            }
        }).catch((e) => {
            setLoading(false)
            toast.error("Non autorisé")
        })
    };

    const UnfollowArtist = async () => {
        await setLoading(true)
        let _id = id || service?.user_id
        await auditor.delete("admiration/delete_admire_user/" + _id).then(async () => {
            setFollowed(false)
            setLoading(false)
            let artist_name = service?.artist_name || artistName
            toast.success('Vous ne suivez plus ' + artist_name)
            try {
                setUserDataToRead({...userDataToRead?.filter((value, index) => value.user_id !== userConnectedId)})
            } catch (e) {
                //
            }
        })
    };

    const FollowArtistBtn = () => {
        return (
            !loading
                ? <Box ml={1} display="flex" alignItems="center">
                    {!auth
                        ? <Tooltip title="Suivre l'artiste">
                            <IconButton
                                classes={iconBtnStyles}
                                onClick={() => FollowArtist()}
                            >
                                <Add/>
                            </IconButton>
                        </Tooltip>
                        : followed
                            ? <Tooltip title="Ne plus suivre l'artiste">
                                <IconButton
                                    disabled={disabled}
                                    className={styles.action}
                                    classes={iconBtnStyles}
                                    onClick={() => UnfollowArtist()}>
                                    <AddTaskIcon sx={{color: 'lightGreen!important'}}/>
                                </IconButton>
                            </Tooltip>
                            : <Tooltip title="Suivre l'artiste">
                                <IconButton
                                    disabled={disabled}
                                    className={styles.action}
                                    classes={iconBtnStyles}
                                    onClick={() => FollowArtist()}>
                                    <Add/>
                                </IconButton>
                            </Tooltip>}
                </Box> :
                <Box ml={1} display="flex" alignItems="center">
                    <Spinner type="grow" size="sm" className="m-auto p-3"/>
                </Box>
        )
    }

    useEffect(() => {
        if (userConnectedId) {
            setLoading(false)
            checkProfile()
        }
        if (!auth) {
            setLoading(false)
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [userConnectedId]);


    return (
        checked
            ? <FollowArtistBtn/>
            : <CardHeader
                avatar={
                    service?.artist_photo ? (
                        <Avatar sx={avatarStyles} src={service?.artist_photo}/>
                    ) : (
                        <Avatar sx={{...styles.avatar}}>
                            {service?.artist_name?.substring(0, 1).toUpperCase()}
                        </Avatar>
                    )
                }
                title={
                    <Box display="flex" alignItems="center">
                        <Typography
                            variant="body1"
                            fontWeight="bold"
                            display="flex"
                            alignItems="center"
                        >
                            {service?.artist_name?.substring(0, 22)}
                            {service?.artist_name.length > 22 && "..."}
                            {service?.is_certified && (
                                <Tooltip
                                    style={{cursor: "pointer"}}
                                    title="profil vérifié"
                                    placement="top"
                                >
                                    <VerifiedIcon
                                        fontSize="small"
                                        sx={{
                                            marginLeft: 0.5,
                                            marginBottom: 0.5,
                                            color: "#1976d2 !important",
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </Typography>
                    </Box>
                }
                subheader={
                    <Typography variant="body2" color="textSecondary">
                        {tag}
                    </Typography>
                }
                action={
                    <div style={{
                        gap: "8px",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: '8px'
                    }}>
                        <FollowArtistBtn/>
                    </div>
                }
                sx={{
                    gap: 2,
                    display: "flex",
                    paddingY: 1,
                    paddingLeft: 1,
                    backgroundColor: "#fff",
                    borderRadius: "5px",
                    alignItems: "center",
                }}
                onClick={() => navigate("/artists/pages/" + service.user_id)}
            />
    );
}

export default ArtistToFollow;
