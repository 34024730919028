import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import {Typography} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
import React, {useEffect, useState} from "react";
import toast from "react-hot-toast";
import logo from "../../assets/img/logo.png";
import {currencySymbolByAddress, useWindowSize} from "../../tools/Tools";
import VerticalAlignBottomOutlinedIcon from '@mui/icons-material/VerticalAlignBottomOutlined';
import RestoreIcon from '@mui/icons-material/Restore';
import Button from '@mui/material/Button';
import generatePDF, {Resolution, Margin} from 'react-to-pdf';
dayjs.extend(utc)

const options = {
    // default is `save`
    method: 'open',
    resolution: Resolution.HIGH,
    page: {
        margin: Margin.LARGE * 2,
    },
    canvas: {
        mimeType: 'image/png',
        qualityRatio: 1
    },
    overrides: {
        pdf: {
            compress: true
        },
        canvas: {
            useCORS: true
        }
    },
};

function ContactInvoice({address, ContactCardToChat, countries, bookedList}) {

    const width = useWindowSize();
    const [copied, setCopied] = useState(false);
    const [isPrint, setIsPrint] = useState(false);
    const [currency, setCurrency] = useState('');
    const Invoice = ContactCardToChat?.payment
    const eventReservation = ContactCardToChat?.reservation
    const reference = Invoice?.reference || eventReservation?.payment?.reference


    const copy = () => {
        navigator.clipboard.writeText(reference).then(() => {
            setCopied(true)
            toast.success("Vous avez copier la réference");
        })
    };

    const isMobilePaymentToRefund = () => {

        let contactInvoice = Invoice?.invoice?.split('.');
        let eventInvoice = eventReservation?.payment?.invoice?.split('.');

        if (contactInvoice && Invoice?.refund) {
            return ['https://mpayment'].includes(contactInvoice[0])
        } else if (eventInvoice && eventReservation?.payment?.refund) {
            return ['https://mpayment'].includes(eventInvoice[0])
        }

        return false
    }

    const getTargetElement = () => document.getElementById('contact-card-invoice-to-pdf');

    const downLoadFile = () => {
        setIsPrint(true)
        setTimeout(() => generatePDF(getTargetElement, options), 0)
    }

    const checkTotalAmount = () => {

        if (!eventReservation?.payment?.total_amount && Invoice?.total_amount) {
            return Invoice?.total_amount
        } else if (eventReservation?.payment?.total_amount && !Invoice?.total_amount) {
            return eventReservation?.payment?.total_amount
        } else if (eventReservation?.payment?.total_amount && Invoice?.total_amount) {
            let num = eventReservation?.payment?.total_amount + Invoice?.total_amount
            return Math.round(num * 100) / 100
        }

        return 0
    }

    useEffect(() => {
        !currency && setCurrency(currencySymbolByAddress(eventReservation?.address, countries))
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [currency]);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                padding: "16px",
                paddingLeft: '32px',
                paddingRight: '32px',
                width: "100%",
                maxWidth: "900px", // Limite la largeur pour rester centré
                margin: "0 auto", // Centre le composant sur la page
                boxSizing: "border-box",
            }}
        >
            {/* Titre et Date */}
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    textAlign: "left",
                }}
            >
                <Typography
                    variant="h6"
                    sx={{
                        fontSize: width >= 768 ? "18px" : "16px",
                        fontWeight: "bold",
                    }}
                >
                    Prise de contact
                </Typography>
                <Typography
                    variant="subtitle2"
                    sx={{
                        fontSize: width >= 768 ? "14px" : "12px",
                        color: "#757575",
                    }}
                >
                    {dayjs(Invoice?.created_at).utc().format("Le DD-MM-YYYY à HH:mm A")}
                </Typography>
            </div>

            {/* Informations principales */}
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                    padding: "16px",
                    backgroundColor: "#f5f5f5",
                    borderRadius: "8px",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                }}
            >
                {/* Référence */}
                {reference && (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: width >= 768 ? "16px" : "14px",
                                color: "#424242",
                            }}
                        >
                            Référence :{" "}
                            <strong>
                                {isPrint
                                    ? reference
                                    : reference
                                        ? reference.substring(0, 5) + "..."
                                        : "..."}
                            </strong>
                        </Typography>
                        {copied ? (
                            <Tooltip
                                title="Vous avez déjà copié cette référence"
                                placement="top"
                            >
                                <DoneAllIcon
                                    fontSize="small"
                                    sx={{ color: "lightGreen!important" }}
                                />
                            </Tooltip>
                        ) : (
                            <Tooltip title="Copier la référence" placement="top">
                                <ContentCopyIcon
                                    fontSize="small"
                                    onClick={() => reference && copy()}
                                    style={{ cursor: "pointer" }}
                                />
                            </Tooltip>
                        )}
                    </div>
                )}

                {/* Adresse */}
                <div>
                    <Typography
                        variant="subtitle2"
                        sx={{
                            fontWeight: "bold",
                            marginBottom: "4px",
                            fontSize: width >= 768 ? "15px" : "13px",
                        }}
                    >
                        Adresse :
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{
                            fontSize: width >= 768 ? "14px" : "12px",
                            color: "#424242",
                        }}
                    >
                        {address.street}, {address.city}, {address.postalCode},{" "}
                        {address.country}
                    </Typography>
                </div>
            </div>

            {/* Tableau de services */}
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                    overflowX: "auto",
                    border: "1px solid #e0e0e0",
                    borderRadius: "8px",
                    padding: "16px",
                }}
            >
                <table
                    style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        fontSize: width >= 768 ? "14px" : "12px",
                    }}
                >
                    <thead
                        style={{
                            backgroundColor: "#84B0CA",
                            color: "white",
                            textAlign: "left",
                        }}
                    >
                    <tr>
                        <th style={{ padding: "8px" }}>#</th>
                        <th style={{ padding: "8px", textAlign: "right" }}>
                            Service
                        </th>
                        <th style={{ padding: "8px", textAlign: "right" }}>
                            Artiste(s)
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={{ padding: "8px" }}>
                            Prise de contact
                            {Invoice?.refund ? (
                                <RestoreIcon
                                    fontSize="small"
                                    sx={{
                                        marginLeft: "4px",
                                        color: "gray !important",
                                    }}
                                />
                            ) : null}
                        </td>
                        <td
                            style={{
                                padding: "8px",
                                textAlign: "right",
                                color: "#424242",
                            }}
                        >
                            {Invoice?.isl_amount || 0}
                            {currency}
                        </td>
                        <td
                            style={{
                                padding: "8px",
                                textAlign: "right",
                                color: "#424242",
                            }}
                        >
                            {(Invoice?.refund
                                ? Invoice?.refunded
                                : Invoice?.artist_amount) || 0}
                            {currency}
                        </td>
                    </tr>
                    {eventReservation?.payment ? (
                        <tr>
                            <td style={{ padding: "8px" }}>
                                Événement
                                {eventReservation?.payment?.refund ? (
                                    <RestoreIcon
                                        fontSize="small"
                                        sx={{
                                            marginLeft: "4px",
                                            color: "gray !important",
                                        }}
                                    />
                                ) : null}
                            </td>
                            <td
                                style={{
                                    padding: "8px",
                                    textAlign: "right",
                                    color: "#424242",
                                }}
                            >
                                {eventReservation?.payment?.isl_amount}
                                {currency}
                            </td>
                            <td
                                style={{
                                    padding: "8px",
                                    textAlign: "right",
                                    color: "#424242",
                                }}
                            >
                                {(eventReservation?.payment?.refund
                                        ? eventReservation?.payment?.refunded
                                        : eventReservation?.payment?.artist_amount) ||
                                    0}
                                {currency}
                            </td>
                        </tr>
                    ) : null}
                    </tbody>
                </table>
            </div>

            {/* Total */}
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "16px",
                    borderRadius: "8px",
                    border: "1px solid #e0e0e0",
                    backgroundColor: "#e9f4fb",
                }}
            >
                <Typography
                    variant="subtitle2"
                    sx={{
                        fontWeight: "bold",
                        fontSize: width >= 768 ? "16px" : "14px",
                    }}
                >
                    Total :
                </Typography>
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: "bold",
                        fontSize: width >= 768 ? "18px" : "16px",
                        color: "#424242",
                    }}
                >
                    {checkTotalAmount()}
                    {currency}
                </Typography>
            </div>

            {/* Alerte remboursement et logo */}
            {isMobilePaymentToRefund() && (
                <Typography
                    variant="caption"
                    sx={{ textAlign: "right", display: "block", color: "#757575" }}
                >
                    Remboursement du paiement mobile sous 7 jours ouvrés.
                </Typography>
            )}
            {bookedList && (
                <div style={{ textAlign: "right" }}>
                    {isPrint ? (
                        <img
                            height={30}
                            width={30}
                            src={logo}
                            alt="Logo"
                            style={{
                                display: "block",
                                marginLeft: "auto",
                            }}
                        />
                    ) : (
                        <Tooltip title="Télécharger la facture" placement="top">
                            <Button
                                size="small"
                                variant="outlined"
                                disabled={!eventReservation?.payment && !Invoice}
                                onClick={() => downLoadFile()}
                            >
                                <VerticalAlignBottomOutlinedIcon />
                            </Button>
                        </Tooltip>
                    )}
                </div>
            )}
        </div>
    );
}

export default ContactInvoice;
