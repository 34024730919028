import React from 'react';
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { CardHeader, CardTitle } from "reactstrap";
import CloseIcon from "@mui/icons-material/Close";

function CardHeaderCustom({ cardTitle, closeModal, disabled, children }) {
    return (
        <CardHeader style={{ padding: closeModal ? '12px' : '20px' }}>
            {/* Conteneur principal en Flexbox */}
            <div
                style={{
                    display: "flex", // Organise le titre et le bouton sur la même ligne
                    justifyContent: "space-between", // Met le titre à gauche et le bouton à droite
                    alignItems: "center",
                    width: "100%",
                    paddingLeft: 15,
                    paddingRight: 15,
                }}
            >
                {/* Titre à gauche */}
                <CardTitle tag="h6" style={{ margin: 0 }}>
                    {cardTitle}
                </CardTitle>

                {/* Bouton à droite */}
                {closeModal && (
                    <Tooltip title="Fermer cet onglet" placement="top">
                        <IconButton
                            size="small"
                            disabled={disabled}
                            aria-label="settings"
                            onClick={closeModal}
                        >
                            <CloseIcon sx={{ color: "gray!important" }} />
                        </IconButton>
                    </Tooltip>
                )}
            </div>

            {/* Enfants positionnés en dessous */}
            {children && (
                <div style={{ width: "100%" }}>
                    {children}
                </div>
            )}
        </CardHeader>
    );
}

export default CardHeaderCustom;
