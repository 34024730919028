import React from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";

function ContactCardInfos({bookedList, ContactCardToChat}) {
    const navigate = useNavigate();
    const showBook = ContactCardToChat?.reservation;

    return (
        <CardContent
            style={{
                padding: "16px",
                borderRadius: "8px",
                paddingLeft: "32px",
                paddingRight: "32px",
                maxWidth: "800px",
                margin: "16px auto", // Centré au milieu de la page
            }}
        >
            {/* Titre principal */}
            <Typography
                variant="overline"
                style={{
                    fontWeight: "bold",
                    color: "#555",
                    letterSpacing: "1px",
                    fontSize: "12px", // Texte légèrement plus petit
                    textTransform: "uppercase",
                }}
            >
                Informations
            </Typography>
            <Typography
                variant="h5"
                component="h2"
                style={{
                    marginTop: "12px",
                    marginBottom: "16px",
                    fontWeight: "600",
                    color: "#333",
                    fontSize: "clamp(16px, 3.5vw, 20px)", // Réduction de la taille minimale
                }}
            >
                Quoi pour la suite ?
            </Typography>

            {/* Contenu dynamique basé sur bookedList */}
            <Typography
                variant="body2"
                component="div"
                style={{
                    lineHeight: "1.6", // Légèrement plus compact
                    fontSize: "clamp(13px, 2.2vw, 15px)", // Texte de base plus petit
                    color: "#555",
                }}
            >
                {bookedList ? (
                    <ul style={{ marginTop: "12px" }}>
                        <li>
                            La discussion sur l'événement sera ouverte après accord
                            de l'artiste.
                        </li>
                        <li>
                            Ce chat servira d'outil pour gérer les informations
                            concernant l'événement en cours.
                        </li>
                    </ul>
                ) : (
                    <ul style={{ marginTop: "12px" }}>
                        <li>
                            {['pending', 'declined'].includes(showBook?.status)
                                ? "Une discussion sera ouverte après votre accord. Tout échange avec l'auditeur se passera dans le chat."
                                : "Une discussion est ouverte concernant la réservation de l'auditeur."}
                        </li>
                        <li>
                            {['pending', 'declined'].includes(showBook?.status)
                                ? "Ce chat servira d'outil pour gérer les informations concernant l'événement en cours."
                                : "Vous pouvez voir quelques astuces concernant le chat."}
                        </li>
                    </ul>
                )}
            </Typography>

            {/* Bouton Voir plus */}
            <Button
                variant="contained"
                onClick={() => navigate('/faq')}
                endIcon={<ChevronRightIcon />}
                style={{
                    marginTop: "24px",
                    padding: "8px 18px", // Légèrement réduit
                    borderRadius: "8px",
                    backgroundColor: "#1976d2",
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: "clamp(12px, 2vw, 14px)", // Texte du bouton réduit
                    textTransform: "none",
                    boxShadow: "0 3px 5px rgba(0, 0, 0, 0.1)",
                    transition: "background-color 0.3s",
                }}
                sx={{
                    "&:hover": {
                        backgroundColor: "#115293", // Couleur plus sombre au survol
                    },
                }}
            >
                Voir plus
            </Button>
        </CardContent>
    );
}

export default ContactCardInfos;
