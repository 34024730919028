import React from "react";
import Box from "@mui/material/Box";
import VerifiedIcon from "@mui/icons-material/Verified";
import Tooltip from "@mui/material/Tooltip";
import dayjs from "dayjs";
import {IconButton, Typography} from "@mui/material";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {currencySymbolByAddress, useWindowSize} from "../../tools/Tools";


function EventToReserve({address, countries, ContactCardToChat, bookedList}) {

    const width = useWindowSize();
    const {reservation} = ContactCardToChat
    const description = reservation?.description
    const currency = currencySymbolByAddress(ContactCardToChat?.reservation?.address, countries)

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: width >= 768 ? 'row' : 'column', // En colonne sur petits écrans, en ligne sur grands écrans
                height: '100%',
                width: '100%',
                overflow: 'hidden', // Empêche le contenu déborder du conteneur
            }}
        >
            {/* Section gauche : Informations générales */}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    padding: '16px',
                    paddingLeft:  width < 768 ? '24px' : '32px',
                    paddingRight: '24px',
                    borderRight: width >= 768 ? '1px solid #e0e0e0' : 'none', // Supprime la bordure sur petits écrans
                    width: width >= 768 ? '50%' : '100%', // Change largeur selon la taille d'écran
                    overflowY: width >= 768 ? 'unset' : 'auto', // Autorise un défilement sur la hauteur pour les petits écrans
                }}
            >
                {/* Informations d'événements */}
                <Box sx={{ marginBottom: '16px' }}>
                    <Typography
                        variant="h6"
                        sx={{
                            fontWeight: 'bold',
                            marginBottom: '8px',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {ContactCardToChat?.reservation?.title?.substring(0, 22)}
                        {ContactCardToChat?.reservation?.title.length > 22 && "..."}
                        <IconButton
                            size="small"
                            aria-label="ouvrir"
                            onClick={() => {
                                const win = window.open(
                                    "/artist/service/" + ContactCardToChat?.reservation.services_id,
                                    "_blank"
                                );
                                win.focus();
                            }}
                        >
                            <OpenInNewIcon />
                        </IconButton>
                    </Typography>
                    <Typography variant="body2" sx={{ fontStyle: 'italic', color: '#757575' }}>
                        {ContactCardToChat?.reservation?.event}
                    </Typography>
                </Box>

                {/* Date et Horaires */}
                <Box sx={{ marginBottom: '16px' }}>
                    <Typography
                        variant="body2"
                        sx={{
                            fontStyle: 'italic',
                            color: '#424242',
                        }}
                    >
                        Le&nbsp;
                        <strong>
                            {dayjs(ContactCardToChat?.reservation.event_date).format(
                                "DD-MM-YYYY à HH:mm"
                            )}
                        </strong>
                    </Typography>
                </Box>

                {/* Adresse */}
                <Box sx={{ marginBottom: '16px' }}>
                    <Typography
                        variant="subtitle2"
                        sx={{
                            fontWeight: 'bold',
                            marginBottom: '4px',
                            color: '#424242',
                        }}
                    >
                        Adresse :
                    </Typography>
                    <Typography variant="body2">{address.street}</Typography>
                    <Typography variant="caption">
                        {address.postalCode}&nbsp;{address.city}
                    </Typography>
                    <Typography variant="caption">&nbsp;{address.country}</Typography>
                </Box>

                {/* Organisateur ou artiste */}
                <Box sx={{ marginBottom: '16px' }}>
                    <Typography
                        variant="subtitle2"
                        sx={{
                            fontWeight: 'bold',
                            marginBottom: '4px',
                        }}
                    >
                        {bookedList ? "Artiste contacté" : "Organisateur"}&nbsp;:
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{ display: 'flex', alignItems: 'center' }}
                    >
                        {bookedList ? (
                            <>
                                {ContactCardToChat?.reservation?.artist_name}
                                {ContactCardToChat?.is_certified && (
                                    <Tooltip title="Vérifié" placement="top" arrow>
                                        <VerifiedIcon
                                            sx={{
                                                marginLeft: '4px',
                                                color: '#4caf50',
                                                fontSize: '16px',
                                            }}
                                        />
                                    </Tooltip>
                                )}
                            </>
                        ) : (
                            `${ContactCardToChat?.lastname} ${ContactCardToChat?.name}`
                        )}
                    </Typography>
                </Box>

                {/* Prix */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        padding: '8px',
                        backgroundColor: '#f5f5f5',
                        borderRadius: '8px',
                        width: '100%',
                        '&:hover': {
                            backgroundColor: '#e0e0e0',
                        },
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: { xs: '10px', sm: '12px', md: '14px' },
                            color: '#757575',
                            textAlign: 'left',
                        }}
                    >
                        À partir de&nbsp;
                        <strong>
                            {ContactCardToChat?.reservation?.min_amount + currency}
                        </strong>
                    </Typography>

                    <Box
                        sx={{
                            width: '100%',
                            height: '1px',
                            backgroundColor: '#e0e0e0',
                            margin: '4px 0',
                        }}
                    ></Box>

                    <Typography
                        sx={{
                            fontSize: { xs: '12px', sm: '14px', md: '16px' },
                            fontWeight: 'bold',
                            color: '#424242',
                            textAlign: 'left',
                        }}
                    >
                        Cachet proposé
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: { xs: '14px', sm: '16px', md: '18px' },
                            fontWeight: 'bold',
                            textAlign: 'left',
                            color: '#000',
                        }}
                    >
                        {ContactCardToChat?.reservation?.total_amount + currency}
                    </Typography>
                </Box>
            </div>

            {/* Section droite : Description */}
            <div
                style={{
                    padding: '16px',
                    paddingLeft: '24px',
                    paddingRight:  width < 768 ? '24px' : '32px',
                    width: width >= 768 ? '50%' : '100%', // Pleine largeur sur petits écrans
                    overflowY: 'auto', // Scroll vertical activé pour la section droite
                    height: width < 768 ? '100px' : '100%', // Hauteur plus petite sur petits écrans
                    maxHeight: '100%', // Empêche tout dépassement
                }}
            >
                <Typography
                    variant="body2"
                    sx={{ textAlign: 'justify', color: '#757575' }}
                >
                    {description}
                </Typography>
            </div>
        </div>

    )
}

export default EventToReserve;
