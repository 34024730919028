import React from "react";
import IconButton from "@mui/material/IconButton";
import { Box, Divider, Skeleton, Tooltip, Typography } from "@mui/material";

function DiscussSkeletonCard({ index }) {
    return (
        <Box
            key={`ef_${index}`}
            display="flex"
            flexDirection="column"
            width="100%"
        >
            {/* Row container */}
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                px={4}
                py={2}
            >
                {/* Circular avatar skeleton */}
                <Skeleton variant="circular" width={50} height={50} />

                {/* Info block */}
                <Box flex={1} ml={2}>
                    <Typography variant="h6" sx={{ mb: 0.5 }}>
                        <Skeleton width={120} height={20} />
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ mb: 0.5 }}>
                        <Skeleton width={100} height={15} />
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                        <Skeleton width={150} height={10} />
                    </Typography>
                </Box>

                {/* Right button skeleton */}
                <Tooltip title="Open discussion" placement="bottom">
                    <IconButton>
                        <Skeleton variant="rectangular" width={30} height={30} />
                    </IconButton>
                </Tooltip>
            </Box>

            {/* Divider */}
            <Divider />
        </Box>
    );
}

export default DiscussSkeletonCard;
