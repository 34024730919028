// TextCard.js
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const TextCard = ({overline, heading, body, sx}) => {
    return (
        <Box sx={{...sx, px: 2, py: 1}}>
            {overline && (
                <Typography variant="overline" color="textSecondary" sx={{display: "block"}}>
                    {overline}
                </Typography>
            )}
            {heading && (
                <Typography variant="h6">
                    {heading}
                </Typography>
            )}
            {body && <Typography variant="body2" color="textSecondary">{body}</Typography>}
        </Box>
    );
};

export default TextCard;
