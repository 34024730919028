import React from "react";
import {useSelector} from "react-redux";
import NoDataList from "../../tools/NoDataList";
import ServiceSliders from "../../tools/ServiceSliders";
import SkeletonPage from "../skeletons/SkeletonPage";
import HomeServiceInfos from "./HomeServiceInfos";

function Services() {
    const {dj, others, artistMusician, loadingResult} = useSelector((state) => state.serviceResults);
    const results = useSelector((state) => state.searchBarData.results);

    const serviceCategories = [
        {data: artistMusician, genre: "Artist / Musicien"},
        {data: dj, genre: "Dj"},
        {data: others, genre: "Autres"},
    ];

    return (
        <div className="content mb-5">
            <HomeServiceInfos/>

            {loadingResult
                ? <SkeletonPage/>
                : !results?.length
                    ? (
                        NoDataList("Aucun Résultat")
                    ) : (serviceCategories.map(
                            ({data, genre}, index) =>
                                data?.length
                                    ? <ServiceSliders key={index} dataList={data} artistGenre={genre}/>
                                    : null
                        )
                    )}
        </div>
    );
}

export default Services;
