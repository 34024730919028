import React from "react";
import {Row} from "reactstrap";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";
import InfoCard from "../../tools/InfoCard";
import TextCard from "../../tools/TextCard";

function SkeletonServices() {

    return (
        <div className="row justify-content-center border-0">
            <div className="col-md-8 mt-2 height-full">
                <Box display="flex" justifyContent="space-between" m={2}>
                    <Box>
                        <Skeleton
                            sx={{ borderRadius: '5px' }}
                            animation="wave"
                            variant="rectangular"
                            height={15}
                            width={150}
                        />
                    </Box>
                    <Box>
                        <Skeleton
                            sx={{ borderRadius: '5px' }}
                            animation="wave"
                            variant="rectangular"
                            height={40}
                            width={150}
                        />
                    </Box>
                    <Box display="flex" flexDirection="column" gap={1.5} minWidth={250}>
                        <InfoCard
                            caption={
                                <Skeleton
                                    sx={{ borderRadius: '5px' }}
                                    animation="wave"
                                    className="mt-2"
                                    variant="rectangular"
                                    height={10}
                                    width={300}
                                />
                            }
                            subtitle={
                                <Skeleton
                                    sx={{ borderRadius: '5px' }}
                                    animation="wave"
                                    className="mt-2"
                                    variant="rectangular"
                                    width={150}
                                />
                            }
                            title={
                                <Skeleton
                                    sx={{ borderRadius: '5px' }}
                                    animation="wave"
                                    className="mt-2"
                                    variant="rectangular"
                                    height={25}
                                    width={80}
                                />
                            }
                        />
                        <Divider/>
                        <Box>
                            <Skeleton
                                sx={{ borderRadius: '5px' }}
                                animation="wave"
                                variant="rectangular"
                                height={25}
                                width={80}
                            />
                            <Skeleton
                                sx={{ borderRadius: '5px', marginTop: '8px' }} // Remplace `.mt-2`
                                animation="wave"
                                variant="rectangular"
                                width={200}
                            />
                        </Box>
                        <Divider/>
                        <Box>
                            <Skeleton
                                sx={{ borderRadius: '5px' }}
                                animation="wave"
                                className="mt-2"
                                variant="rectangular"
                                height={25}
                                width={80}
                            />
                            <Skeleton
                                sx={{ borderRadius: '5px' }}
                                animation="wave"
                                className="mt-2"
                                variant="rectangular"
                                width={150}
                            />
                            <Skeleton
                                sx={{ borderRadius: '5px' }}
                                animation="wave"
                                className="mt-2"
                                variant="rectangular"
                                height={10}
                                width={300}
                            />
                        </Box>
                        <Divider/>
                        <Box>
                            <Skeleton
                                sx={{ borderRadius: '5px' }}
                                animation="wave"
                                className="mt-2"
                                variant="rectangular"
                                height={25}
                                width={80}
                            />
                            <Skeleton
                                sx={{ borderRadius: '5px' }}
                                animation="wave"
                                className="mt-2"
                                variant="rectangular"
                                width={150}
                            />
                            <Skeleton
                                sx={{ borderRadius: '5px' }}
                                animation="wave"
                                className="mt-2"
                                variant="rectangular"
                                height={10}
                                width={300}
                            />
                        </Box>
                        <Divider/>
                    </Box>
                    <Row position={'bottom'}>
                        <Skeleton sx={{borderRadius: '5px', margin: '0 auto'}}
                                  animation="wave"
                                  className="mt-4 ml-2 mr-2 width-full"
                                  variant="rectangular"
                                  height={50}/>
                    </Row>
                </Box>
            </div>
            <Box
                className="border-0 ml-5 mt-2 mr-5"
                sx={{
                    boxShadow: 3,
                    borderRadius: 2,
                    backgroundColor: '#ffffff',
                }}>
                <div className="row justify-content-center">
                    {/*<Item grow>*/}
                    {/*   */}
                    {/*</Item>*/}
                    <Box minHeight={200} borderRadius={8}>
                        <Card
                            sx={{
                                maxWidth: 343,
                                margin: 'auto',
                                borderRadius: 12,
                                padding: 12,
                                boxShadow: 3, // Remplace les styles d'ombre
                            }}
                        >
                        <Skeleton
                                sx={{borderRadius: '5px'}}
                                height={239}
                                animation="wave"
                                variant="rectangular"/>
                            <CardContent className="m-auto">
                                <Skeleton sx={{borderRadius: '5px', margin: "0 auto"}}
                                          animation="wave"
                                          variant="rectangular"
                                          width={100}/>
                                <Skeleton sx={{borderRadius: '5px', margin: "0 auto"}}
                                          animation="wave"
                                          className="mt-3"
                                          height={25}
                                          variant="rectangular"
                                          width={200}/>

                                <TextCard
                                    caption={
                                        <Skeleton
                                            sx={{ borderRadius: '5px', margin: '0 auto' }}
                                            animation="wave"
                                            className="mt-3 width-full"
                                            height={50}
                                            variant="rectangular"
                                        />
                                    }
                                    title={
                                        <Skeleton
                                            sx={{ borderRadius: '5px', margin: '0 auto' }}
                                            animation="wave"
                                            variant="rectangular"
                                            width={100}
                                        />
                                    }
                                />

                                <Skeleton sx={{borderRadius: '5px', margin: "0 auto"}}
                                          animation="wave"
                                          className="mt-3 width-full"
                                          height={150}
                                          variant="rectangular"/>
                                <Skeleton sx={{borderRadius: '5px', margin: "0 auto"}}
                                          animation="wave"
                                          className="mt-3 width-full"
                                          height={50}
                                          variant="rectangular"/>

                            </CardContent>
                        </Card>
                    </Box>
                </div>
            </Box>
        </div>
    );
}

export default SkeletonServices;
