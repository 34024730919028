import {Box, Typography} from "@mui/material";
import Divider from "@mui/material/Divider";
import CelebrationIcon from '@mui/icons-material/Celebration';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import React, {useState, useEffect} from 'react';
import {convertUnit, labelFormatDecode} from "../../tools/Tools";

export default function ServiceDetails({service, currency}) {

    const transEx = service.travel_expenses
    const [events, setEvents] = useState([]);
    const isArtist = service.artist_type === 'artist_musician'
    const [thematics, setThematics] = useState([]);

    const separateEventAndThematics = () => {
        let tmpX = [...service?.events]
        const groupedEvents = [...Array(Math.ceil(
            tmpX?.length / 3
        ))].map(_ => tmpX?.splice(0, 4))

        let tmpY = [...service?.thematics]
        const groupedThematics = [...Array(Math.ceil(
            tmpY?.length / 3
        ))].map(_ => tmpY?.splice(0, 4))

        setEvents(groupedEvents)
        setThematics(groupedThematics)
    }

    useEffect(() => {
        separateEventAndThematics()
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    return (
        <Box display="flex" flexDirection="column" gap={2}>
            {/* Événements */}
            <Box>
                <Typography variant="body1" fontWeight="bold" className="mb-2">Événements</Typography>
                {events?.map((value, index) => (
                    <Typography variant="body2" key={`event_${index}`}>
                        {value.join(", ")}
                    </Typography>
                ))}
                <Typography variant="caption" color="textSecondary">
                    <CelebrationIcon sx={{ pb: 0.25 }} fontSize="small" />
                    &nbsp;• Ce sont les types d'événements pris en charge par l'artiste
                </Typography>
            </Box>

            {/* Styles musicaux */}
            <Divider />
            <Box>
                <Typography variant="body1" fontWeight="bold" className="mb-2">Styles musicaux</Typography>
                {thematics?.map((value, index) => (
                    <Typography variant="body2" key={`thematics_${index}`}>
                        {value.join(", ")}
                    </Typography>
                ))}
            </Box>

            {/* Localisation */}
            <Divider />
            <Box>
                <Typography variant="body1" fontWeight="bold" className="mb-2">Localisation</Typography>
                <Typography variant="body2">
                    {service.cities[0] === "all"
                        ? service.country
                        : service.cities.join(", ")}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                    <MyLocationIcon sx={{ pb: 0.25 }} fontSize="small" />
                    &nbsp;• Valable uniquement pour cet ou ces emplacements
                </Typography>
            </Box>

            {/* Déplacement */}
            <Divider />
            <Box>
                <Typography variant="body1" fontWeight="bold" className="mb-2">Déplacement</Typography>
                <Typography variant="body2">
                    {transEx?.from && transEx?.to
                        ? `Entre ${transEx?.from} et ${transEx?.to} ${currency}`
                        : transEx?.from
                            ? `${transEx?.from} ${currency}`
                            : "*Offert"}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                    <FlightTakeoffIcon sx={{ pb: 0.25 }} fontSize="small" />
                    &nbsp;• Les frais de déplacement de l'artiste
                </Typography>
            </Box>

            {/* Autres (durée minimale) */}
            <Divider />
            <Box>
                <Typography variant="body1" fontWeight="bold" className="mb-2">Autres</Typography>
                <Typography variant="body2">
                    Durée minimale de la prestation :{" "}
                    {labelFormatDecode(
                        convertUnit(service?.duration_of_the_service, service?.unit_duration_of_the_service),
                        isArtist
                    )}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                    <AccessTimeFilledIcon sx={{ pb: 0.25 }} fontSize="small" />
                    &nbsp;• Temps minimum à consacrer pour cette prestation
                </Typography>
            </Box>
        </Box>
    );
}
