import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import React, {useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate,} from "react-router-dom";
import {Col} from "reactstrap";
import {addProfileContactsArchived, setContactIdToShow} from "../../reducer";
import NoDataList from "../../tools/NoDataList";
import ContactCard from "../contactCards/ContactCard";
import PaginatedContacts from "../contactCards/PaginatedContacts";
import SkeletonContactCardHeader from "../skeletons/SkeletonContactCardHeader";
import {checkErrorMessage} from "../../tools/Tools";

function Archives() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const auth = useSelector(state => state.session.authenticated);
    const auditor = useSelector(state => state.axios.auditor);
    const [showBook, setShowBook] = useState({});
    const [toShow, setToShow] = useState(false);
    const [loading, setLoading] = useState(true);
    const {contactIdToShow} = useSelector(state => state.global);
    const {role, contacts_archived} = useSelector(state => state.profile);
    let isLogout = [null, ''].includes(role);


    const addReservationToShow = async (book) => {
        setShowBook(book)
        setToShow(true)
    }

    const toDelete = async (_id) => {
        setToShow(false)
        setLoading(true);
        let newList = contacts_archived?.filter((value) => value?.id !== _id)
        await auditor
            .delete('contact_cards/delete/' + _id)
            .then(async (resp) => {
                dispatch(addProfileContactsArchived(newList))
                setLoading(false)
                toast.success("supprimé")
            }).catch((error) => {
                let {message} = checkErrorMessage(error)
                setLoading(false);
                toast.error(message);
            });
    }

    const checkArchivesLIst = async () => {
        // per_page 1 for now
        let per_page = 1;
        await auditor
            .get('contact_cards/archives/per_page/' + per_page.toString())
            .then(async (resp) => {
                dispatch(addProfileContactsArchived(resp.data))
                setLoading(false)
            }).catch((error) => {
                let {message} = checkErrorMessage(error)
                toast.error(message);
            });
    }

    useEffect(() => {
        if (contacts_archived?.length && contactIdToShow) {
            let contact = contacts_archived?.filter(contact => contact.id === contactIdToShow)?.[0]
            contact && addReservationToShow(contact).then(r => dispatch(setContactIdToShow(0)))
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [contactIdToShow, contacts_archived?.length]);

    useEffect(() => {

        isLogout && !auth && navigate('/')
        !isLogout && !contacts_archived?.length && checkArchivesLIst().then(r => setLoading(false))
        contacts_archived?.length && loading && setLoading(false);

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [auth, contacts_archived?.length, navigate, loading, role])

    return (
        <div className="content mx-2 mb-5">
            <div className="row justify-content-center">

                <Modal size="lg" show={toShow}>
                    <ContactCard setToShow={setToShow} toDelete={toDelete} ContactCardToChat={showBook}/>
                </Modal>

                <Col sm="12" className="m-0 p-0 overflow-auto" style={{height: "100vh"}}>
                    <Box style={{height: '100%', width: '100%'}} className="radius-1">
                        <List>
                            {loading
                                ? Array.from(Array(6).keys()).map((key, index) =>
                                    <ListItem key={key} button>
                                        <SkeletonContactCardHeader/>
                                    </ListItem>)

                                : contacts_archived?.length
                                    ? <PaginatedContacts books={contacts_archived} setShowBook={addReservationToShow}/>
                                    : NoDataList("Vide")}
                        </List>
                    </Box>
                </Col>

            </div>
        </div>
    )
}

export default Archives;
