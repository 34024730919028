import React from "react";
import AppleIcon from "@mui/icons-material/Apple";
import ShopIcon from "@mui/icons-material/Shop";
import IconButton from "@mui/material/IconButton";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../../assets/img/logo.png";

const SideBarLogo = () => {
    const navigate = useNavigate();

    return (

        <div className="logo d-flex align-items-center justify-content-between">

            <div className="d-flex align-items-center">
                {/* Logo Image */}
                <NavLink
                    to="/" // Utiliser `to` au lieu de `href`
                    onClick={(e) => {
                        e.preventDefault(); // Prévient tout comportement par défaut
                        navigate("/"); // Navigue sans rechargement
                    }}
                    className="simple-text logo-mini"
                >
                    <div className="logo-img">
                        <img src={logo} alt="react-logo" />
                    </div>
                </NavLink>
                <NavLink
                    to="/"
                    onClick={(e) => {
                        e.preventDefault();
                        navigate("/");
                    }}
                    className="simple-text logo-normal"
                >
                    KantoBiz
                </NavLink>
            </div>

            {/* App Store and Google Play Icons */}
            <div className="ml-auto d-flex">
                {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                <a hidden
                   target="_blank"
                   rel="noreferrer"
                   id="go_to_apple_app_auditor"
                   href="https://apps.apple.com/us/app/kantobiz/id1606736780"/>
                {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                <a hidden
                   target="_blank"
                   rel="noreferrer"
                   id="go_to_google_app_auditor"
                   href="https://play.google.com/store/apps/details?id=com.kantobiz"/>
                <IconButton
                    aria-label="App Store"
                    onClick={() => document.getElementById("go_to_apple_app_auditor").click()}>
                    <AppleIcon fontSize="small" sx={{color: "white !important"}}/>
                </IconButton>
                <IconButton
                    aria-label="Google Play"
                    onClick={() => document.getElementById("go_to_google_app_auditor").click()}>
                    <ShopIcon fontSize="small" sx={{color: "white !important"}}/>
                </IconButton>
            </div>
        </div>
    );
};

export default SideBarLogo;
