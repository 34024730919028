import CakeIcon from "@mui/icons-material/Cake";
import CloseIcon from "@mui/icons-material/Close";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import EditIcon from "@mui/icons-material/Edit";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import LogoutIcon from "@mui/icons-material/Logout";
import {Box, IconButton} from "@mui/material";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import Tooltip from "@mui/material/Tooltip";
import React, {useState} from "react";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {CardFooter} from "reactstrap";
import Conf from "../../config/tsconfig.json";
import {clearSession, configAxios} from "../../reducer";
import CardHeaderCustom from "../../tools/CardHeaderCustom";
import {cleanAuthData, footerStyle, useWindowSize} from "../../tools/Tools";
import AddBirthDay from "./Edits/AddBirthDay";
import AddPhone from "./Edits/AddPhone";
import ArtistFollow from "./ArtistFollow";

function ProfileFooter({checked, userId, role, setToArtist, toCloseProfile, profileData}) {

    const dispatch = useDispatch();
    const width = useWindowSize();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const auditor = useSelector(state => state.axios.auditor);
    const [loading, setLoading] = useState(false);
    const [activeBirth, setActiveBirth] = useState(false);
    const id = open ? 'simple-popover' : undefined;
    const [activeContact, setActiveContact] = useState(false);
    const ipAddress = useSelector(state => state.global.ipAddress);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const removeSession = async () => {
        await setLoading(true)
        dispatch(clearSession());
        await cleanAuthData(dispatch)
        await dispatch(configAxios({ipAddress: ipAddress, token: Conf.configs.TokenVisitor}))
        await setLoading(false)
        toast.success('Vous êtes déconnecté', {
            duration: 6000,
            style: {
                border: '1px solid #6bd098',
                padding: '16px',
                color: '#6bd098',
            },
            iconTheme: {
                primary: '#6bd098',
                secondary: '#FFFAEE',
            },
        });

    }

    const logout = async () => {
        await auditor.delete("users/logout").then(() => {
            removeSession()
        }).catch(() => {
            removeSession()
        });
    };

    return (
        <CardFooter style={{
            zIndex: 1000,
            color: '#fff',
            paddingTop: '10px',
            backgroundColor: '#333',
            borderTop: '1px solid #444'
        }}>
            <Box sx={footerStyle}>
                {checked && <ArtistFollow checked id={userId} artistName={profileData.artist_name}/>}
                <Tooltip title={checked ? "" : "Plus"} placement="top">
                    {checked
                        ? null
                        : <IconButton sx={{color: '#1976d2', mx: 0.5}} onClick={handleClick}>
                            <DragIndicatorIcon/>
                        </IconButton>
                    }
                </Tooltip>

                {!checked && width < 500 && <InputAdornment position="start" sx={{mt: 2.5}}>
                    <Button
                        id="close_profile"
                        variant="outlined"
                        onClick={() => toCloseProfile()}
                        endIcon={<CloseIcon/>}>
                        ESC
                    </Button>
                </InputAdornment>}
                {!checked &&
                    <Tooltip title="Déconnexion" placement="bottom">
                        <IconButton sx={{color: '#1976d2', mx: 0.5}} onClick={(e) => !loading && logout(e)}>
                            <LogoutIcon/>
                        </IconButton>
                    </Tooltip>}
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    slotProps={{
                        paper: {
                            sx: {width: width < 500 ? '100%' : 415}
                        },
                    }}
                >
                    <div className="m-2">
                        <CardHeaderCustom cardTitle="Autres paramètres"/>
                        <MenuItem className="pt-3">
                            <ListItemIcon>
                                <ContactPhoneIcon
                                    fontSize={activeContact ? 'large' : 'medium'}
                                    sx={{mr: 1, mt: !activeContact ? .5 : 1.5}}/>

                                {!activeContact
                                    ? <div>
                                        <small>{profileData.phone || "Ajouter un numéro ..."}</small>
                                        <IconButton
                                            size="small"
                                            sx={{marginBottom: 0.5}}
                                            onClick={() => setActiveContact(true)}>
                                            <EditIcon fontSize="small"/>
                                        </IconButton>
                                    </div>
                                    : <AddPhone closeModal={() => setActiveContact(false)}/>}

                            </ListItemIcon>
                        </MenuItem>
                        <MenuItem>
                            <ListItemIcon>
                                <CakeIcon fontSize={activeBirth ? 'large' : 'medium'}
                                          sx={{mr: 1, mt: activeBirth ? 1 : 0.25}}/>
                                {!activeBirth
                                    ? <div>
                                        <small>{profileData.birth || "Date de naissance"}</small>
                                        <IconButton
                                            size="small"
                                            sx={{marginBottom: 0.5}}
                                            onClick={() => setActiveBirth(true)}>
                                            <EditIcon fontSize="small"/>
                                        </IconButton>
                                    </div>
                                    : <AddBirthDay closeModal={() => setActiveBirth(false)}/>}

                            </ListItemIcon>
                        </MenuItem>
                        <MenuItem hidden={role !== 'professional_auditor'} onClick={() => {
                            setAnchorEl(null);
                            toCloseProfile()
                            setToArtist(true);
                        }}>
                            <ListItemIcon>
                                <GroupAddIcon sx={{marginRight: 1}}/>
                                Dévenir artiste KantoBiz
                            </ListItemIcon>
                        </MenuItem>
                    </div>
                </Popover>
            </Box>
        </CardFooter>
    );
}

export default ProfileFooter;
