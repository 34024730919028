import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Card, CardBody, Row} from "reactstrap";
import makeStyles from '@mui/styles/makeStyles';
import Avatar from '@mui/material/Avatar';
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import IconButton from "@mui/material/IconButton";
import Skeleton from "@mui/material/Skeleton";
import Tooltip from "@mui/material/Tooltip";
import toast from "react-hot-toast";
import apis from "../../config/apis";
import {addProfileData} from "../../reducer";
import {checkErrorMessage, deleteNullInObj, fillInBodyForm} from "../../tools/Tools";
import SkeletonRectangular from "../skeletons/SkeletonRectangular";
import AutoAccept from "./AutoAccept";
import IsCertified from "./Certifications/IsCertified";
import ProfileNameAndPseudo from "./ProfileNameAndPseudo";
import ProfileAddress from "./Edits/ProfileAddress";
import ProfileBiography from "./ProfileBiography";
import ProfileFooter from "./ProfileFooter";
import RatingProfile from "./RatingProfile";
import p_cover from "assets/img/damir-bosnjak.jpg";

const Profile = ({checked, setToArtist, toCloseProfile}) => {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [loadingPhoto, setLoadingPhoto] = useState(false);
    const [activeDefaultAddress, setActiveDefaultAddress] = useState(false);
    const auditorForm = useSelector((state) => state.axios.auditorForm);
    const userId = useSelector((state) => (checked ? state.profileChecked.userId : state.profile.userId));
    const role = useSelector((state) => (checked ? state.profileChecked.role : state.profile.role));
    const profileData = useSelector((state) => (checked ? state.profileChecked.profileData : state.profile.profileData));

    const updateProfilePhoto = async (photo, cover_area, message) => {
        setLoadingPhoto(true);
        let tmp = deleteNullInObj({...profileData});
        delete tmp['is_certified'];
        const bodyFormData = fillInBodyForm(new FormData(), tmp);
        bodyFormData.append("cover_photo", cover_area ? cover_area : profileData?.cover_photo);
        bodyFormData.append("photo", photo ? photo : profileData?.photo);
        await auditorForm
            .put(apis.updateProfile, bodyFormData)
            .then(async (resp) => {
                await dispatch(addProfileData(resp.data));
                setLoadingPhoto(false);
                toast.success("Photo " + message + " mis a jour");
            })
            .catch((error) => {
                toast.error(checkErrorMessage(error).message);
                setLoadingPhoto(false);
            });
    };

    useEffect(() => {
        userId && setLoading(false);
    }, [userId]);

    return (
        <Card
            className="card-user"
            style={{
                marginBottom: 0,
                borderTopLeftRadius: 12.5,
                borderTopRightRadius: 12.5,
                height: checked ? "auto" : "100vh",
            }}
        >
            <div className="image">
                {loading || loadingPhoto ? (
                    <SkeletonRectangular width="100%" height={130}/>
                ) : (
                    <img
                        alt="..."
                        src={profileData?.cover_photo && profileData?.cover_photo !== "null" ? profileData?.cover_photo : p_cover}
                    />
                )}
            </div>

            <CardBody style={{height: "200px !important"}}>
                <div className="author">
                    <div style={{position: "absolute", right: 15, marginTop: 5}}>
                        <div className="cursor-pointer absolute ml-5">
                            <input
                                id="cover_area_file"
                                hidden={true}
                                disabled={!!checked}
                                type="file"
                                accept="image/png, image/jpeg"
                                className="input-file"
                                onChange={(e) => {
                                    updateProfilePhoto(null, e.target.files[0], "de couverture").then((r) => null);
                                }}
                            />
                            <input
                                id="picture"
                                hidden={true}
                                disabled={!!checked}
                                accept="image/png, image/jpeg"
                                className="input-file"
                                type="file"
                                onChange={(e) => {
                                    updateProfilePhoto(e.target.files[0], null, "de profile").then((r) => null);
                                }}
                            />
                        </div>
                        <Tooltip title="Modifier le photo de couverture" placement="bottom">
                            <IconButton>
                                {loading || loadingPhoto ? (
                                    <Skeleton hidden={!!checked} animation="wave" variant="rectangular"
                                              sx={{borderRadius: 1}} width={25} height={19}/>
                                ) : (
                                    <AddAPhotoIcon
                                        fontSize="medium"
                                        hidden={!!checked}
                                        disabled={!!checked}
                                        onClick={() => document.getElementById("cover_area_file").click()}
                                    />
                                )}
                            </IconButton>
                        </Tooltip>
                    </div>
                    <a href="#pablo" onClick={() => !loading && document.getElementById("picture").click()}>
                        {loading || loadingPhoto ? (
                            <Row className="justify-content-center mb-4">
                                <SkeletonRectangular height={124} width={124}/>
                            </Row>
                        ) : profileData?.photo && profileData?.photo !== "null" ? (
                            <Tooltip title={checked ? "" : "Modifier le photo de profil"} placement="right">
                                <img alt="..." className="avatar border-gray" src={profileData?.photo}/>
                            </Tooltip>
                        ) : (
                            <Tooltip title={checked ? "" : "Modifier le photo de profil"} placement="right">
                                <Avatar className={styles.logo}>
                                    {checked
                                        ? profileData?.artist_name?.substring(0, 1).toUpperCase()
                                        : profileData?.artist_name?.substring(0, 1).toUpperCase() || profileData?.name?.substring(0, 1).toUpperCase()}
                                </Avatar>
                            </Tooltip>
                        )}
                    </a>
                    <h5 className="title">
                        {loading ? (
                            <Row className="justify-content-center mb-3">
                                <Skeleton animation="wave" variant="circular" height={17} width={17}/>
                                <Skeleton animation="wave" sx={{marginLeft: "5px"}} height={17} width="43%"/>
                            </Row>
                        ) : (
                            <div className="row justify-content-center">
                                <ProfileNameAndPseudo checked={checked}/>
                            </div>
                        )}
                    </h5>
                </div>

                <p className={`description text-center ${loading ? "mb-3" : ""}`} hidden={checked}>
                    {loading ? (
                        <Skeleton animation="wave" sx={{margin: "0 auto"}} height={17} width="55%"/>
                    ) : (
                        profileData?.email
                    )}
                </p>

                <div
                    style={{
                        flexGrow: 1,
                        overflowY: "auto",
                        marginBottom: 10,
                        maxHeight: window.innerHeight < 500 ? "50vh" : "calc(100vh - 325px)", // Ajuste la hauteur si l'écran est petit
                        paddingTop: 0,
                        padding: "0 1rem",
                    }}
                >
                    {loading ? (
                        <Skeleton animation="wave" sx={{margin: "0 auto"}} height={17} width="50%"/>
                    ) : (
                        <ProfileBiography checked={checked}/>
                    )}

                    {!checked ? (
                        loading ? (
                            <Skeleton animation="wave" sx={{margin: "0 auto"}} height={17} width="63%"/>
                        ) : (
                            <ProfileAddress
                                activeDefaultAddress={activeDefaultAddress}
                                setActiveDefaultAddress={setActiveDefaultAddress}/>
                        )
                    ) : null}

                    <RatingProfile checked={checked}/>

                    <div className="mt-2" hidden={role === "professional_auditor" || checked}>
                        {loading ? (
                            <Skeleton animation="wave" sx={{margin: "0 auto"}} height={100} width={200}/>
                        ) : (
                            <AutoAccept/>
                        )}
                    </div>

                    <div className="mt-2" hidden={role === "professional_auditor" || checked}>
                        {loading ? (
                            <Skeleton animation="wave" sx={{margin: "0 auto"}} height={100} width={200}/>
                        ) : (
                            <IsCertified toCloseProfile={toCloseProfile}/>
                        )}
                    </div>
                </div>
            </CardBody>

            <ProfileFooter
                role={role}
                userId={userId}
                checked={checked}
                profileData={profileData}
                setToArtist={setToArtist}
                toCloseProfile={toCloseProfile}
            />
        </Card>
    );
};

const styles = makeStyles({
    logo: {
        transition: "0.3s",
        width: 200,
        height: 200,
        fontSize: "4rem",
        cursor: "pointer",
        boxShadow: "0 4px 12px 0 rgba(0,0,0,0.24)",
        borderRadius: "1rem",
    },
});

export default Profile;
