import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import React, {useCallback, useEffect, useState, useRef} from 'react';
import IconButton from "@mui/material/IconButton";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import {Divider} from "@mui/material";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {useSearchParams} from "react-router-dom";
import apis from "../../../config/apis";
import {setMsgTabIndex, switchBotMsgProcess, switchLoadingDiscussions} from "../../../reducer";
import NoDataList from "../../../tools/NoDataList";
import {
    checkErrorMessage,
    clearContactToChat,
    removePaymentSearchParams,
    upCardEvents
} from "../../../tools/Tools";
import DiscussSkeletonCard from "../../../views/skeletons/DiscussSkeletonCard";
import Chat from "../Chats/Chat";
import DiscussCard from "./DiscussCard";
import DiscussionsHeader from "./DiscussionsHeader";


function Discussions({toggleDrawer}) {

    const dispatch = useDispatch();
    const isMounted = useRef(false);
    const [booked, setBooked] = useState([]);
    const {role, userId, contacts} = useSelector(state => state.profile);
    const [searchParams, setSearchParams] = useSearchParams();
    const auditor = useSelector(state => state.axios.auditor);
    const {contactToChat, msgTabIndex, countries, discussionsLoading} = useSelector(state => state.global);

    const initMessage = useCallback(async (_type) => {
        const contactSelected = contacts[_type]?.accepted || [];
        const sortedContacts = contactSelected.sort((a, b) => new Date(a.reservation.event_date) - new Date(b.reservation.event_date));
        setBooked(sortedContacts);
        await dispatch(switchLoadingDiscussions(false))
    }, [contacts, dispatch]);

    const checkContactToAssign = async () => {
        let x = {}
        searchParams.forEach((value, key) => {
            x[key] = value
        });

        let {payment_intent, redirect_status} = x

        if (redirect_status === 'succeeded') {
            let stripe_token = {'id': payment_intent}
            localStorage.removeItem('contactToChat');
            removePaymentSearchParams(searchParams, setSearchParams);
            await auditor.put(apis.auditorToPaid + "/" + contactToChat?.reservation.id, {stripe_token}).then(async (resp) => {
                await clearContactToChat(dispatch)
                await upCardEvents(resp.data, contacts, dispatch, 'book', countries)
                await initMessage('book')
                toast("Votre paiement à été un succès." +
                    "\nL'événement a été ajouté à votre agenda ainsi qu'à celui de l'artiste.", {
                    icon: <ThumbUpIcon sx={{color: "lightGreen!important"}}/>,
                });
            }).catch((error) => {
                clearContactToChat(dispatch)
                dispatch(switchBotMsgProcess(false))
                toast.error(checkErrorMessage(error).message)
            })
        } else if (redirect_status === 'failed') {
            toast.error("Il y a eu une erreur lors de la transaction, veuillez réessayer.")
        }
    }

    useEffect(() => {
        if (!isMounted.current ) {
            checkContactToAssign().then(r => null)

            return () => {
                isMounted.current = true
            };
        }

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    useEffect(() => {
        if (![null, ''].includes(role) && !msgTabIndex) {
            dispatch(setMsgTabIndex(role !== 'professional_auditor' ? 'auditor' : 'book'))
        } else if (discussionsLoading && booked.length) {
            initMessage(role === 'professional_auditor' ? 'book' : msgTabIndex).then(r => null)
        } else if (![null, ''].includes(role) && !booked.length && userId) {
            initMessage(role === 'professional_auditor' ? 'book' : msgTabIndex).then(r => null)
        }

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [userId, role, msgTabIndex, discussionsLoading]);

    return (
        contactToChat
            ? <Chat bookType={role === 'professional_auditor' ? 'book' : msgTabIndex} initMessage={initMessage}/>
            : <div>
                <DiscussionsHeader setBooked={setBooked} initMessage={initMessage} toggleDrawer={toggleDrawer}/>
                <div>
                    <Divider sx={{width: '100%'}} orientation="horizontal"/>
                    <div
                        className="message-list-container"
                        style={{flex: 1, overflowY: 'auto', height: '200px !important'}}
                    >
                        {discussionsLoading
                            ? Array.from(Array(6).keys()).map((index) =>
                                <DiscussSkeletonCard key={"c_" + index} index={index}/>
                            )
                            : !booked?.length
                                ? <div>
                                    {NoDataList("0 Discussion")}
                                    {NoDataList(
                                        null,
                                        <IconButton
                                            size="small"
                                            onClick={() => userId && initMessage(role === 'professional_auditor' ? 'book' : msgTabIndex)}>
                                            <small>Rafraîchir</small>&nbsp;<RotateLeftIcon fontSize="small"/>
                                        </IconButton>
                                    )}
                                </div>
                                : booked.map((book, index) => <DiscussCard book={book} key={"b_" + index}/>)}
                    </div>
                </div>
            </div>
    );
}

export default Discussions;
