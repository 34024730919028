import axios from "axios";
import apis from "../config/apis";
import {addProfileDataContactsLength} from "../reducer";
import DecryptData from "./DecryptData";
import {checkErrorMessage} from "./Tools";

axios.interceptors.response.use(
    response => {
        return {data: DecryptData(response.data)}
    },
    error => {
        return Promise.reject(error);
    }
);

const CheckContactsLength = async (contact_type, headers, dispatch, data) => {
    let answers = {
        accepted: 0,
        pending: 0,
    };

    try {
        const resp = await axios.get(apis.contacts_length(contact_type), {headers: headers});
        const {accepted = 0, pending = 0} = resp.data;

        answers = {accepted, pending};

        data[contact_type] = answers;

        await dispatch(addProfileDataContactsLength(data));
    } catch (error) {
        console.log('error: ', checkErrorMessage(error).message);
    }

    return data;
};

export default CheckContactsLength;
