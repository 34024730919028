import React, {useState} from "react";
import makeStyles from '@mui/styles/makeStyles';
import {Box, Typography} from "@mui/material";
import EssentialPoints from "./EssentialPoints";

const useStyles = makeStyles(({palette}) => ({
    benefitsContainer: {
        backgroundColor: "#f5f5f5",
        borderRadius: "8px",
        padding: "16px",
        boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
        textAlign: "left",
        width: "100%",
    },
    benefitsTitle: {
        fontWeight: "bold",
        marginBottom: "15px !important",
        fontSize: "1rem",
        color: "#333",
    },
    benefitsList: {
        margin: 0,
        paddingLeft: "5px",
        listStyleType: "none", // Supprime les puces
    },
    benefitItem: {
        marginBottom: "6px",
        fontSize: "0.7rem",
        color: "#555",
        position: "relative",
        paddingLeft: "20px", // Espace pour le symbole
        lineHeight: "1.2", // Alignement vertical du texte
        "&::before": {
            content: '"✔"', // Symbole ajouté
            position: "absolute",
            left: 0,
            top: "50%", // Centrage vertical
            transform: "translateY(-50%)", // Correction pour centrer précisément
            color: "#4caf50", // Couleur verte
            fontSize: "1rem", // Taille du symbole
        },
    },
}));

function CertificationAdvantages() {

    const styles = useStyles();
    const [openModal, setOpenModal] = useState(true);

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    return (
        <Box
            sx={{
                p: 2,
                gap: 2,
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#f9f9f9",
                borderRadius: 2,
                boxShadow: 2,
            }}
        >
            <Box className={styles.benefitsContainer}>
                <Typography className={styles.benefitsTitle}>Avantages de la certification</Typography>
                <ul className={styles.benefitsList}>
                    <li className={styles.benefitItem}>Assistance disponible 24h/24 et protection renforcée.</li>
                    <li className={styles.benefitItem}>Ajouter des frais pour vos prises de contact.</li>
                    <li className={styles.benefitItem}>Ajouter des frais de transport pour vos fiches KantoBiz</li>
                    <li className={styles.benefitItem}>Augmenter vos opportunités de prestation.</li>
                    <li className={styles.benefitItem}>Inspirer confiance aux auditeurs.</li>
                </ul>
            </Box>

            <EssentialPoints
                openModal={openModal}
                handleOpenModal={handleOpenModal}
                handleCloseModal={handleCloseModal}
            />
        </Box>
    );
}

export default CertificationAdvantages;
