import CloseIcon from "@mui/icons-material/Close";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import {Box, IconButton, TextField} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {CardFooter, Col, Form, FormGroup, Spinner} from "reactstrap";
import {btnStyle, changeFields, footerStyle} from "../../../tools/Tools";
import AddPhone from "../Edits/AddPhone";

function PayOut({orangeMoney, loading, setToPayOut, walletPayoutConfigs, toPayOut, setModal, walletCurrency, payOut}) {

    const {wallets, selected} = useSelector(state => state.wallet);
    const profileData = useSelector(state => state.profile.profileData);
    const [validPhone, setValidPhone] = useState(false);
    const wallet = wallets[selected]

    const setAmountInput = () => {
        return (
            <TextField
                type="number"
                value={toPayOut}
                variant="filled"
                label="Montant du virement"
                id="filled-start-adornment"
                placeholder="..."
                helperText={
                    orangeMoney
                        ? <div>
                            <small>• Les fonds seront crédités sur votre compte dans les 10 prochaines minutes.</small><br/>
                        </div>
                        : <div>
                            <small>• Les fonds seront déposés sur votre compte dans un délai de 5 à 7 jours ouvrables.</small><br/>
                            <small>• Le Montant Minimum pour un retrait est
                                de {walletPayoutConfigs.national_payout_min}&nbsp;{walletCurrency}.</small><br/>
                            <small>• Il faut au minimum enregistrer votre RIB dans la rubrique Coordonées
                                Bancaires.</small>
                        </div>
                }
                onChange={(e) => changeFields(setToPayOut, e)}
            />
        )
    }

    return (
        <div>
            <div>
                <h5 className="m-auto pt-3 mt-0 text-center">
                    Soldes -&nbsp;<strong>{walletCurrency}&nbsp;{wallet?.balance}</strong>
                </h5>
                <hr/>
            </div>

            <div className="mb-4 pl-4 pr-4">
                <Form>
                    <Col md="12">
                        <FormGroup>
                            <h5 className="text-center mb-4">
                                {orangeMoney ? 'Vers Orange Money' : 'Faire un retrait'}
                            </h5>
                            {orangeMoney
                                ? !profileData?.phone?.toString()?.length && !validPhone
                                    ? <AddPhone payOut validation={() => setValidPhone(true)}/>
                                    : setAmountInput()
                                : setAmountInput()}
                        </FormGroup>
                    </Col>
                </Form>
            </div>

            <CardFooter>
                <Box sx={footerStyle}>
                    <Tooltip title="Fermer cette onglet" placement="top">
                        <IconButton
                            size="small"
                            disabled={loading}
                            aria-label="settings"
                            sx={btnStyle('red !important')}
                            onClick={() => setModal(false)}>
                            <CloseIcon sx={{color: "red!important"}}/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Faire le transfert" placement="top">
                        <IconButton
                            size="small"
                            disabled={loading || toPayOut <= 0}
                            aria-label="settings"
                            onClick={() => toPayOut >= 0 && payOut()}
                            sx={btnStyle('lightGreen !important')}>
                            {loading
                                ? <Spinner type="grow" size="sm"/>
                                : <DoneAllIcon sx={{color: "lightGreen!important"}}/>}&nbsp;Retrait&nbsp;
                        </IconButton>
                    </Tooltip>
                </Box>
            </CardFooter>
        </div>
    )
}

export default PayOut;
