import React from "react";
import {useNavigate} from "react-router-dom";
import {Container} from "reactstrap";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import {useWindowSize} from "../../tools/Tools";

function Footer({fluid}) {

    const navigate = useNavigate();
    const width = useWindowSize();

    return (
        <footer
            style={{
                left: 0,
                bottom: 0,
                position: "fixed",
                width: `calc(100% - ${width > 993 || !width ? 250 : 0}px)`, // Ajuste la largeur selon le sidebar
                marginLeft: width > 993 || !width ? 260 : 0, // Décale le footer si le sidebar est visible
                backgroundColor: "#f4f3ef",
                zIndex: 1030,
                display: "flex", // Active flexbox
                justifyContent: width <= 768 ? "center" : "flex-end", // Centre sur mobile, aligne à droite sur grand écran
                alignItems: "center", // Centre verticalement
                padding: "10px 20px", // Ajoute de l'espace autour des éléments
            }}
            className="footer"
        >
            <Container
                fluid={fluid}
                className={`d-flex flex-wrap ${
                    width <= 768 ? "justify-content-center" : "justify-content-end"
                } align-items-center`}
                style={{
                    fontSize: "0.85rem", // Taille du texte réduite
                    textAlign: width <= 768 ? "center" : "right", // Centre le texte sur mobile, aligne à droite sinon
                }}
            >
                {/* Conditions générales */}
                <Tooltip title="Conditions générales d'utilisations" placement="top">
                    <small
                        className="cursor-pointer border-bottom mx-2"
                        onClick={() => navigate("/privacy")}
                        style={{whiteSpace: "nowrap"}}
                    >
                        Conditions générales d'utilisations
                    </small>
                </Tooltip>

                {/* À propos de nous */}
                <Tooltip title="À propos de nous" placement="top">
                    <small
                        className="cursor-pointer border-bottom mx-2"
                        onClick={() => navigate("/about")}
                        style={{whiteSpace: "nowrap"}}
                    >
                        À propos de nous
                    </small>
                </Tooltip>

                {/* FAQ */}
                <Tooltip title="Foires aux questions" placement="top">
                    <small
                        className="cursor-pointer border-bottom mx-2"
                        onClick={() => navigate("/faq")}
                        style={{whiteSpace: "nowrap"}}
                    >
                        FAQ
                    </small>
                </Tooltip>

                {/* Copyright */}
                <Tooltip title="Copyright" placement="top">
                    <small
                        className="cursor-pointer border-bottom mx-2"
                        onClick={() => navigate("/")}
                        style={{whiteSpace: "nowrap"}}
                    >
                        KantoBiz &copy; {1900 + new Date().getYear()}
                    </small>
                </Tooltip>
            </Container>
        </footer>
    );
}

Footer.propTypes = {
    default: PropTypes.bool,
    fluid: PropTypes.bool,
};

export default Footer;
