import SmartToyIcon from "@mui/icons-material/SmartToy";
import {ImpulseSpinner} from "react-spinners-kit";
import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {CardBody, Spinner} from "reactstrap";
import {addBotMessageList, switchBotMsgProcess} from "../../../reducer";
import ChatMsg from "../../../tools/ChatMsg";
import {reservationChatUpdate} from "../../../tools/Tools";

function ChatBot({toScroll}) {

    const dispatch = useDispatch();
    const isMounted = useRef(false);
    const {userId} = useSelector(state => state.profile);
    const {process, messagesList, loadingAnswer} = useSelector(state => state.chatBot);
    const {contactToChat, currenciesChat} = useSelector(state => state.global);
    const updateInfo = contactToChat?.reservation?.last_user_updated;
    const {symbol} = currenciesChat;

    const assign = async () => {

        let tmp = []
        if (updateInfo) {
            tmp = [<ChatMsg avatar={<SmartToyIcon/>} messages={[
                reservationChatUpdate(contactToChat?.reservation, symbol, userId)
            ]}/>]
        }

        await dispatch(addBotMessageList([...messagesList, ...tmp]))

        dispatch(switchBotMsgProcess(false))

        toScroll?.current?.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'})
    }

    useEffect(() => {

        if (!isMounted.current && process && contactToChat && userId) {
            assign().then(r => null)

            return () => {
                isMounted.current = true
            };
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [contactToChat, userId, process]);

    return process ? (
        <div className="row justify-content-center mt-5">
            <Spinner type="grow" size="sm"/>
        </div>
    ) : (
        <CardBody className="mt-0">
            <div className="flex-grow-1 overflow-y-auto" style={{maxHeight: '100%', paddingBottom: '1rem'}}>
                <p style={{whiteSpace: "pre-line"}} ref={toScroll}>
                    {messagesList?.map((value) => value)}
                    <div style={{margin: 12, paddingTop: 10}} hidden={!loadingAnswer}>
                        <ImpulseSpinner size={25} frontColor="#f5f5f5"/>
                    </div>
                </p>
            </div>
        </CardBody>
    )
}

export default ChatBot;
