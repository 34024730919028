import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import React, {useRef, useEffect, useState} from "react";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import {Box, IconButton} from "@mui/material";
import {Elements,} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {CardBody, CardFooter, Spinner} from "reactstrap";
import settings from "../../config/tsconfig.json";
import {
    addContactFormEventProposedAmount,
    addContactFormToSend,
    addProfileDataContacts, addProfileDataContactsLength,
    switchContactFormProcess
} from "../../reducer";
import CardHeaderCustom from "../../tools/CardHeaderCustom";
import {
    btnStyle,
    checkContactForm,
    checkErrorMessage,
    checkStripePromise,
    cleanReservationFormData,
    fillContactFormData, footerStyle
} from "../../tools/Tools";
import {payment_appearance} from "../../tools/Variables";
import SkeletonContact from "../skeletons/SkeletonContact";
import ContactReview from "./ContactReview";
import OrangeMoney from "./OrangeMoney";
import AddPaymentMethods from "./Steps/AddPaymentMethods";
import Steps from "./Steps/Steps";

function ContactStepper({isValid, lastContactForm, paymentIntent, redirectStatus, paymentIntentSecret}) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isPaid = lastContactForm && redirectStatus === 'succeeded'
    const isFail = lastContactForm && redirectStatus === 'failed'
    const isMounted = useRef(false);
    const payRef = useRef(null);
    const orangeRef = useRef(null);
    const {service, send, currencySymbol, process, currency} = useSelector(state => state.toContact);
    const auditor = useSelector(state => state.axios.auditor);
    const isDev = useSelector(state => state.global.isDev);
    const [orange, setOrange] = useState(false);
    const [orangeResponse, setOrangeResponse] = useState({});
    const {globalCurrencyCode} = useSelector(state => state.global);
    const contactForm = useSelector(state => state.contactForm);
    const {contacts, contactsLength, profileData} = useSelector(state => state.profile);
    const {email} = profileData
    const [clientSecret, setClientSecret] = useState('');
    const [review, setReview] = useState(null);
    const [stripePromise, setStripPromise] = useState();
    const [loading, setLoading] = useState(true);
    const [toCheckOut, setToCheckOut] = useState(false);

    const reFilStripeInfo = () => {
        setClientSecret(paymentIntentSecret)
        if (isDev) {
            setStripPromise(loadStripe(settings.configs.StripeKeyDev));
        } else {
            setStripPromise(loadStripe(settings.configs.StripeKeyProd));
        }
    }

    const sendNewContactCard = async (contactCard) => {
        await auditor.post("contact_cards/new", contactCard).then(async (resp) => {
            dispatch(addContactFormToSend(true))

            setReview(
                <ContactReview
                    contactForm={contactCard}
                    country={service?.country}
                    currency={currencySymbol}
                />
            );

            await cleanReservationFormData(dispatch);

            if (contacts['book']['pending']) {
                let contactsTmp = {...contacts}
                contactsTmp['book']['pending'].push(resp.data)
                dispatch(addProfileDataContacts(contactsTmp))
            }

            const updatedContactsLength = {
                ...contactsLength,
                book: {
                    ...contactsLength['book'],
                    pending: contactsLength['book'].pending + 1
                }
            };
            dispatch(addProfileDataContactsLength(updatedContactsLength));
            dispatch(switchContactFormProcess(false))
            localStorage.removeItem('contactForm');
            toast.success("Votre prise de contact a été envoyée. Accéder a votre tableau de bord pour la gestion, voir votre émail pour plus de détails.");
        }).catch((error) => {
            console.log(error)
            dispatch(switchContactFormProcess(false))
            let errorMessage = checkErrorMessage(error);
            paymentIntentSecret && reFilStripeInfo()
            toast.error(errorMessage.message)
        });
    }

    const preparationProcessBeforeSend = (token, reference) => {
        let tDate = new Date(
            contactForm.eventDate.getTime() - contactForm.eventDate.getTimezoneOffset() * 60000,
        )
            .toISOString()
            .slice(0, -1);
        let contactCard = checkContactForm(contactForm, tDate, service, contactForm.amountProposed, email);
        if (service.contact_amount) {
            if (reference) {
                contactCard["stripe_token"] = {"payment_url": token, "reference": reference}
            } else {
                contactCard["stripe_token"] = token
            }
        }

        sendNewContactCard(contactCard).then(r => toCheckOut && setToCheckOut(false));
    }

    const validateForm = () => {
        if (service.contact_amount) {
            setToCheckOut(true);
        } else {
            preparationProcessBeforeSend(null, null)
        }
    }

    const orangeMoney = () => {
        dispatch(switchContactFormProcess(false))
        let data = {"amount": service.contact_amount, "currency": globalCurrencyCode}
        auditor.post("payments/orange_money", data).then(async (resp) => {
            await setOrangeResponse(resp.data)
            await setOrange(true)
        }).catch((error) => {
            toast.error(checkErrorMessage(error).message)
        })
    }

    const checkPaymentPassed = () => {
        let _contactForm = {...lastContactForm};

        fillContactFormData(dispatch, _contactForm).then(r => {
            dispatch(addContactFormEventProposedAmount(_contactForm.reservation.total_amount))

            if (isPaid) {
                _contactForm['stripe_token'] = {'id': paymentIntent}
                setReview(
                    <ContactReview
                        contactForm={_contactForm}
                        country={service?.country}
                        currency={currencySymbol}
                    />
                );
                sendNewContactCard(_contactForm, true).then(async r => {
                    setLoading(false);
                })
            } else {
                paymentIntentSecret && reFilStripeInfo()
                setLoading(false);
            }
        })
    }

    useEffect(() => {
        let amount = service?.contact_amount;
        !isMounted.current && dispatch(addContactFormEventProposedAmount(service.price))
        if (amount && currency && !isMounted.current) {

            if (isPaid || isFail) {
                checkPaymentPassed()
            } else {
                checkStripePromise(isDev, setStripPromise, auditor, amount, currency, setClientSecret, setLoading)
                    .then((isSuccess) => {
                        if (!isSuccess) {
                           navigate(-1)
                        }
                    });
            }

        } else {
            setLoading(false)
        }

        return () => {
            isMounted.current = true
        };
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [auditor, currency, service.price, service?.contact_amount, isPaid]);

    return (
        <div>

            <Modal show={toCheckOut}>
                <CardHeaderCustom
                    cardTitle="Mode de paiement"
                    closeModal={() => setToCheckOut(false)}/>

                <CardBody className="mx-4">
                    {orange
                        ? <OrangeMoney
                            ref={orangeRef}
                            setOrange={setOrange}
                            orangeResponse={orangeResponse}
                            sendContactCard={preparationProcessBeforeSend}/>
                        : <Elements stripe={stripePromise} options={{clientSecret, payment_appearance}}>
                            <AddPaymentMethods ref={payRef}/>
                        </Elements>}
                </CardBody>

                {orange
                    ? <CardFooter>
                        <Box sx={footerStyle}>
                            <Tooltip title="Annuler la transaction" placement="top">
                                <IconButton
                                    size="small"
                                    disabled={process}
                                    aria-label="settings"
                                    onClick={() => setOrange(false)}
                                    sx={{marginRight: 1}}>
                                    <CloseIcon sx={{color: "red!important"}}/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Envoyer la prise contact après validation" placement="top">
                                <IconButton
                                    size="small"
                                    disabled={process}
                                    aria-label="settings"
                                    sx={btnStyle('lightGreen')}
                                    onClick={() => orangeRef?.current?.checkValidationOrangeMoney()}>
                                    <small>Continuer&nbsp;</small>
                                    {process
                                        ? <Spinner type="grow" size="sm"/>
                                        : <DoneAllIcon sx={{color: "lightGreen!important"}}/>}
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </CardFooter>
                    : <CardFooter>
                        <Box sx={footerStyle}>
                            <IconButton
                                size="small"
                                aria-label="settings"
                                sx={btnStyle('lightGreen')}
                                onClick={() => payRef?.current?.cardValidate(orangeMoney, preparationProcessBeforeSend)}>
                                <small>Envoyer ma prise de contact&nbsp;</small>
                                {process
                                    ? <Spinner type="grow" size="sm"/>
                                    : <DoneAllIcon sx={{color: "lightGreen!important"}}/>}
                            </IconButton>
                        </Box>
                    </CardFooter>}
            </Modal>

            {loading
                ? <SkeletonContact/>
                : send
                    ? review
                    : <Steps isValid={isValid} validateForm={validateForm}/>
            }
        </div>
    );
}

export default ContactStepper;
