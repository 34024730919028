import {Typography} from "@mui/material";
import React from 'react';
import CloseIcon from "@mui/icons-material/Close";
import MessageIcon from '@mui/icons-material/Message';
import RateReviewIcon from '@mui/icons-material/RateReview';
import Badge from "@mui/material/Badge";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Button from "@mui/material/Button";
import {useDispatch, useSelector} from "react-redux";
import {setMsgTabIndex, switchLoadingDiscussions} from "../../../reducer";
import TextCard from "../../../tools/TextCard";
import {useWindowSize} from "../../../tools/Tools";

function DiscussionsHeader({setBooked, initMessage, toggleDrawer}) {

    const dispatch = useDispatch();
    const width = useWindowSize();
    const {msgTabIndex} = useSelector(state => state.global);
    const {role, contactsLength} = useSelector(state => state.profile);

    const handleChange = async (event, newValue) => {
        await dispatch(switchLoadingDiscussions(true))
        await dispatch(setMsgTabIndex(newValue))
        await setBooked([])
        await initMessage(newValue);
    };

    return (
        <div>
            <div className={width > 500 ? "justify-content-center" : null}>
                <TextCard
                    heading={width > 500 ? null :
                        <div
                            style={{width: '100%'}}
                            className="d-flex justify-content-between align-items-center mr-2">
                            <Typography variant="subtitle1">Discussions</Typography>

                            <Button
                                id="close_message"
                                variant="outlined"
                                onClick={toggleDrawer('right', false)}
                                endIcon={<CloseIcon/>}
                            >
                                ESC
                            </Button>
                        </div>
                    }
                />
            </div>

            {role !== 'professional_auditor' &&
                <div className="row justify-content-center">
                    <BottomNavigation
                        style={{width: '100%'}}
                        className="mb-3"
                        showLabels
                        value={msgTabIndex}
                        onChange={handleChange}>
                        <BottomNavigationAction
                            label="Avec les auditeurs"
                            value="auditor"
                            icon={
                                <Badge
                                    color="secondary"
                                    badgeContent={contactsLength['auditor']?.accepted}>
                                    <RateReviewIcon/>
                                </Badge>}
                        />
                        <BottomNavigationAction
                            label="Avec les artistes"
                            value="book"
                            icon={
                                <Badge
                                    color="secondary"
                                    badgeContent={contactsLength['book']?.accepted}>
                                    <MessageIcon/>
                                </Badge>}
                        />
                    </BottomNavigation>
                </div>}
        </div>
    )
}

export default DiscussionsHeader;
