import {Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import React, {useImperativeHandle, forwardRef} from "react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {addContactFormTabIndex, switchContactFormProcess, switchPaymentMethod} from "../../../reducer";
import {cardValidation, checkContactForm, orangeMoneyBtn} from "../../../tools/Tools";
import LaunchIcon from '@mui/icons-material/Launch';
import AddPhone from "../../profile/Edits/AddPhone";

const indicatorColors = ['#d93025', '#1a73e8', '#188038', '#e37400'];

/* eslint-disable-next-line no-empty-pattern */
export const AddPaymentMethods = forwardRef(({}, ref) => {

    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const {service, tabIndex} = useSelector(state => state.toContact);
    const contact_amount = service?.contact_amount
    const contactForm = useSelector(state => state.contactForm);
    const {countryLog, isDev, countryOrangeMoneyAllowed, paymentMethod} = useSelector(state => state.global);
    const {phone, email} = useSelector((state) => state.profile.profileData);

    const onChangePaymentMethod = (e) => {
        let paymentType = e.value.type
        dispatch(switchPaymentMethod(paymentType))
    }

    useImperativeHandle(ref, () => ({
        cardValidate(orangeMoney, preparationProcessBeforeSend) {
            dispatch(switchContactFormProcess(true))
            if (tabIndex === 0) {
                if (paymentMethod === 'paypal') {
                    let tDate = new Date(
                        contactForm.eventDate.getTime() - contactForm.eventDate.getTimezoneOffset() * 60000,
                    )
                        .toISOString()
                        .slice(0, -1);
                    let contactCard = checkContactForm(contactForm, tDate, service, contactForm.amountProposed, email);
                    localStorage.setItem('contactForm', JSON.stringify(contactCard));
                }

                cardValidation(stripe, elements).then(token => {
                    if (token) {
                        preparationProcessBeforeSend(token, null);
                    } else {
                        dispatch(switchContactFormProcess(false))
                    }
                }).catch(error => {
                    dispatch(switchContactFormProcess(false))
                })
            } else {
                dispatch(switchContactFormProcess(false))
                if (!phone) {
                    toast.error('Merci d’enregistrer un numéro au préalable.')
                } else {
                    orangeMoney()
                }
            }
        },
    }));

    return (
        <div className="justify-content-center">
            {(contact_amount && isDev) ||
            (contact_amount && countryOrangeMoneyAllowed.includes(countryLog.toLowerCase())) ? (
                <Tabs
                    value={tabIndex}
                    className="mb-3"
                    onChange={(e, index) => dispatch(addContactFormTabIndex(index))}
                    TabIndicatorProps={{
                        children: <div className={`MuiIndicator-${tabIndex}`} />,
                    }}
                    sx={{
                        ".MuiTabs-flexContainer": { gap: 2 },
                        ".MuiIndicator": {
                            backgroundColor: indicatorColors[tabIndex],
                            height: "3px",
                        },
                    }}
                >
                    <Tab
                        disableTouchRipple
                        label={
                            <Box className="MuiTabItem-labelGroup">
                                <Box className="MuiTabItem-subLabel">
                                    Visa, Mastercard, paypal ...
                                </Box>
                            </Box>
                        }
                        icon={<CreditCardIcon />}
                        sx={{
                            textTransform: "none",
                            color: indicatorColors[1],
                            "&.Mui-selected": {
                                color: indicatorColors[tabIndex],
                            },
                        }}
                    />
                    {orangeMoneyBtn({
                        textTransform: "none",
                        color: indicatorColors[1],
                        "&.Mui-selected": {
                            color: indicatorColors[tabIndex],
                        },
                    })}
                </Tabs>
            ) : null}

            {contact_amount && tabIndex === 0 ? (
                <div className="form-group">
                    <PaymentElement onChange={(e) => onChangePaymentMethod(e)} />
                </div>
            ) : (
                <div>
                    <div className="card p-2 rounded-lg">
                        <Box sx={{ mt: 2 }}>
                            <Box className="pt-2">
                                <Typography variant="subtitle1" className="font-weight-bold">
                                    Orange Money
                                </Typography>
                                <hr />
                            </Box>
                            <Box display="flex" alignItems="center" mt={1} ml={1} mr={1}>
                                <LaunchIcon
                                    sx={{ color: "gray", fontSize: "2rem", marginRight: 2 }}
                                />
                                <Typography variant="body2" className="text-justify text-muted">
                                    En sélectionnant Orange Money, nous vous redirigerons vers une
                                    page sécurisée pour valider les étapes suivantes.
                                </Typography>
                            </Box>
                        </Box>
                    </div>
                    {!phone && (
                        <Box sx={{ mt: 2 }}>
                            <AddPhone />
                        </Box>
                    )}
                </div>
            )}
        </div>
    );
})

export default AddPaymentMethods;
