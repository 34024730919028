import React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

const ChatMsg = ({ side = "left", avatar, messages = [] }) => {
    const isLeftSide = side === "left";

    return (
        <Box
            display="flex"
            flexDirection={isLeftSide ? "row" : "row-reverse"}
            alignItems="flex-start"
            mb={1}
        >
            <Avatar sx={{ ml: isLeftSide ? 0 : 1, mr: isLeftSide ? 1 : 0 }}>
                {avatar}
            </Avatar>
            <Box>
                {messages.map((message, index) => (
                    <Typography
                        key={index}
                        sx={{
                            bgcolor: isLeftSide ? "#f0f0f0" : "#1976d2",
                            color: isLeftSide ? "black" : "white",
                            p: 1,
                            borderRadius: 2,
                            mb: 0.5,
                        }}
                    >
                        {message}
                    </Typography>
                ))}
            </Box>
        </Box>
    );
};

export default ChatMsg;
