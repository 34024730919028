import React, {useEffect, useState} from "react";
import {Row} from "reactstrap";
import RefreshIcon from '@mui/icons-material/Refresh';
import CardContent from "@mui/material/CardContent";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import NoDataList from "./NoDataList";
import TextCard from "./TextCard";
import {changeFields, questions, useWindowSize} from "./Tools";

function Questions() {

    const width = useWindowSize();
    const [expanded, setExpanded] = useState(false);
    const [quests, setQuests] = useState(questions);
    const [textToSearch, setTextToSearch] = useState("");
    // const auth = useSelector(state => state.session.authenticated);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleKeyPress = async (event) => {
        if (event.key === 'Enter' && textToSearch.length) {
            event.preventDefault();
            await search()
        }
    }

    const search = async () => {
        await setQuests(questions.filter((quest, index) =>
            [quest.title, quest.subTitle, quest.description].join(' ').includes(textToSearch)
        ))
    }

    useEffect(() => {
        //
    }, [])

    return (
        <div className="content">
            <Row className="card-flex-row justify-content-center overflow-auto ml-2 mr-2 mt-2 bg-transparent border-0"
                 style={{height: "80vh"}}>
                <CardContent>
                    <TextCard
                        overline="Question réponses"
                        heading={
                            <div className="row justify-content-center pointer-event">
                                <Paper
                                    component="form"
                                    sx={{
                                        p: '2px 4px',
                                        display: 'flex',
                                        mt: 4,
                                        mb: 4,
                                        width: width < 500 ? width - 50 : 400
                                    }}
                                >
                                    <Tooltip title="Réstaurer" placement="bottom">
                                        <IconButton sx={{p: '10px'}} aria-label="menu"
                                                    onClick={() => setQuests(questions)}>
                                            <RefreshIcon/>
                                        </IconButton>
                                    </Tooltip>
                                    <InputBase
                                        sx={{ml: 1, flex: 1}}
                                        onKeyPress={(e) => handleKeyPress(e)}
                                        onChange={(e) => changeFields(setTextToSearch, e)}
                                        placeholder="Chercher votre question ici"
                                        inputProps={{'aria-label': 'Chercher votre question ici'}}
                                    />
                                    <IconButton
                                        type="button"
                                        sx={{p: '10px'}}
                                        aria-label="search"
                                        onClick={() => textToSearch.length && search()}
                                    >
                                        <SearchIcon/>
                                    </IconButton>
                                </Paper>
                            </div>
                        }
                        body={
                            <div className="p-0 text-justify">
                                {quests.map((question, index) => (
                                    <Accordion
                                        key={index}
                                        style={expanded === 'panel_' + index
                                            ? {backgroundColor: 'rgba(160,237,199,0.16)'}
                                            : {backgroundColor: 'rgba(171,236,244,0.16)'}}
                                        expanded={expanded === 'panel_' + index}
                                        onChange={handleChange('panel_' + index)}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon sx={{marginLeft: 1}}/>}
                                            aria-controls="panel-content"
                                            id="panel-header"
                                        >
                                            <Typography
                                                fontSize={14}
                                                sx={{width: '33%', flexShrink: 0, marginRight: 2}}>
                                                {question.title}
                                            </Typography>
                                            {question.subTitle && (
                                                <Typography fontSize={15} sx={{color: 'text.secondary'}}>
                                                    {question.subTitle}
                                                </Typography>
                                            )}
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography fontSize={15}>{question.description}</Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                                {!quests.length && NoDataList("Aucun Résultat")}
                            </div>
                        }
                    />
                </CardContent>
            </Row>
        </div>
    )
}

export default Questions;
