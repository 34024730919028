import VerifiedIcon from "@mui/icons-material/Verified";
import Tooltip from "@mui/material/Tooltip";
import React from 'react';
import cx from 'clsx';
import Avatar from '@mui/material/Avatar';
import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import randomColor from "randomcolor";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {artist_t} from "../../tools/Variables";
import {Swiper, SwiperSlide} from 'swiper/react'; // Import Swiper et SwiperSlide
import 'swiper/css'; // Import des styles par défaut de Swiper
import makeStyles from '@mui/styles/makeStyles';
import 'swiper/css/pagination'; // Optionnel : pagination
import 'swiper/css/navigation'; // Optionnel : navigation

const useStyles = makeStyles(({palette}) => ({
    root: {
        cursor: "pointer",
        position: "relative",
        overflow: "hidden",
        "&:before": {
            content: '""',
            position: "absolute",
            width: "100%",
            height: "100%",
            background: "linear-gradient(180deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3))", // Dégradé plus sombre
            backdropFilter: "blur(10px)", // Effet de flou
            borderRadius: "0.8rem",
            zIndex: 0,
        },
    },

    cover: {
        borderRadius: '0.8rem',
    },
    content: {
        backdropFilter: "blur(10px)", // Applique un effet de flou
        background: "linear-gradient(180deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.4))", // Dégradé clair
        borderRadius: "0.8rem",
        padding: "12px",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.15)", // Ombre légère
        color: palette.type === "dark" ? "#fff" : "#000", // Texte qui s'adapte au thème
    },
    titleContainer: {
        marginLeft: "10px", // Ajoute un espace entre l'avatar et le texte
    },
    title: {
        color: "#222",
        fontSize: "1rem",
        marginBottom: "0px",
        textTransform: "capitalize",
        textShadow: "0.5px 0.5px 1px rgba(0, 0, 0, 0.4)",
    },
    type: {
        fontFamily: 'Sen',
        fontSize: '0.75rem',
        color: "#555", // Texte gris foncé
        marginTop: "0px",
    },
    events: {
        fontFamily: 'Fjalla One',
        fontSize: '0.75rem',
        color: '#666', // Gris moyen
        margin: 0,
    },
    genre: {
        fontFamily: 'Sen',
        fontSize: '0.75rem',
        color: "#888", // Gris clair
    },
    logo: {
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        color: "#fff",
        transition: '0.3s',
        width: 40, // Taille réduite
        height: 40,
        fontSize: '1.5rem',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)', // Ombre plus douce
        borderRadius: '0.8rem',
    },
    service_title: {
        fontFamily: 'Sen',
        color: '#fff',
        textTransform: 'capitalize',
        backgroundColor: palette.text.hint,
        opacity: 0.65,
        fontSize: '0.8rem',
        padding: '0 0.4rem',
        borderRadius: 8,
    },
}));

const CustomCard = ({styles, cover, logo, is_certified, brand, genre, events, id, artist_type}) => {
    const navigate = useNavigate();

    return (
        <Box className={cx(styles.root)} onClick={() => navigate('/artist/service/' + id)}>
            <CardMedia image={cover} className={styles.cover}/>
            <Box className={styles.content}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div>
                        {logo ? (
                            <Avatar className={styles.logo} src={logo}/>
                        ) : (
                            <Avatar className={styles.logo}>
                                {brand?.charAt(0).toUpperCase()}
                            </Avatar>
                        )}
                    </div>
                    <div className={styles.titleContainer}>
                        <h2 className={styles.title}>
                            {brand?.substring(0, 10)}
                            {brand?.length > 10 && '...'}
                            {is_certified && (
                                <Tooltip style={{cursor: 'pointer'}} title="profil verifié" placement="top">
                                    <VerifiedIcon
                                        fontSize="small"
                                        sx={{
                                            marginLeft: 0.5,
                                            marginBottom: 0.5,
                                            color: '#1976d2 !important',
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </h2>
                        <small className={styles.type}>{artist_type}</small>
                    </div>
                </div>
                <div style={{marginTop: '8px'}}>
                    <div className={styles.events}>
                        {events?.join(', ')?.substring(0, 28)}
                        {events?.join(', ').length > 28 && '...'}
                    </div>
                </div>
                <div style={{marginTop: '8px'}}>
                    <div className={styles.genre}>
                        {genre?.join(', ')?.substring(0, 28)}
                        {genre?.join(', ').length > 28 && '...'}
                    </div>
                </div>
            </Box>
        </Box>
    );
};

export const ServiceCard = ({itemKey, data}) => {

    const styles = useStyles({color: randomColor({luminosity: 'dark'})});
    const currency = useSelector(state => state.global.globalCurrencySymbol);

    return (
        <Swiper
            slidesPerView="auto"
            spaceBetween={20} // Espacement entre les slides
            className="mySwiper"
        >
            {data.map((_data, index) => (
                <SwiperSlide key={`${itemKey}-${index}`}  className="myFixedSlide bg-transparent">
                    <CustomCard
                        id={_data.id}
                        styles={styles}
                        brand={_data.artist_name}
                        is_certified={_data.is_certified}
                        artist_type={artist_t[_data.artist_type]}
                        price={_data.price + ' ' + currency}
                        cover={_data.galleries[0]}
                        logo={_data.artist_photo}
                        events={_data.events}
                        genre={_data.thematics}
                    />
                </SwiperSlide>
            ))}
        </Swiper>

    );
};

