import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {Box, IconButton} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {Container, Navbar, NavbarText} from "reactstrap";
import {routes} from "routes";
import Timer from "../../tools/Countdown/timer";
import {checkTimer, getPathFromUrl, useWindowSize} from "../../tools/Tools";
import SearchBar from "./Search/SearchBar";
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';


function NavbarCustom() {

    const navigate = useNavigate();
    const location = useLocation();
    const width = useWindowSize();
    const sidebarToggle = useRef();
    const role = useSelector(state => state.profile.role);
    const upComingEvent = useSelector(state => state.global.upComingEvent);
    const auditor = useSelector(state => state.axios.auditor);
    const auth = useSelector(state => state.session.authenticated);
    const [isDashPath, setIsDashPath] = useState(false);
    const [brand, setBrand] = useState(null);
    const [pathname, setPathName] = useState(null);
    const [time, setTime] = useState({days: 0, hours: 0, minutes: 0, seconds: 0});

    const getBrand = (_routes) => {
        const pathname = getPathFromUrl(window.location.href);
        setPathName(pathname)
        let brandName = "Page non trouvée";

        _routes.forEach((prop) => {
            if (prop.component?.length && !(prop.requireOAuth && !auth && !role)) {
                prop.component.forEach((a) => {
                    if (pathname.includes(a.path?.split(":")[0])) {
                        brandName = a.desc || a.name;
                    }
                });
            } else if (pathname.includes(prop.path?.split(":")[0])) {
                brandName = prop.desc || prop.name;
            }
        });

        return brandName;
    };

    const openSidebar = () => {
        document.documentElement.classList.toggle("nav-open");
        sidebarToggle.current.classList.toggle("toggled");
    };

    const handleClick = (e) => {
        let srcElement = e.srcElement
        if (srcElement?.id && srcElement.id.includes("no-sidebar-close-id")) {
            document.documentElement.classList.toggle("nav-open");
            sidebarToggle.current.classList.toggle("toggled");
        }
    }

    const goBack = () => {
        if (
            (process.env.NODE_ENV === "development" && !window.location.host.includes("localhost")) ||
            (process.env.NODE_ENV === "production" && !window.location.host.includes("kantobiz.com"))
        ) {
            console.warn("Navigation interrompue : le domaine actuel ne correspond pas.");
            return;
        }

        // Si le domaine est correct, naviguer à la page précédente
        navigate(-1);
    }

    useEffect(() => {

        if (
            width < 993 &&
            document.documentElement.className.indexOf("nav-open") !== -1
        ) {
            document.documentElement.classList.toggle("nav-open");
            sidebarToggle.current.classList.toggle("toggled");
        }

        const pList = [
            "archives",
            "artist-contact-demand",
            "artist-contacted-demand",
            "my-kantobiz-cards",
            "my-kantobiz-transfers"
        ]

        const pathname = getPathFromUrl(window.location.href);
        setIsDashPath(pList.includes(pathname) && width > 500)

        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, [location, auth, auditor, width]);

    useEffect(() => {
        setBrand((prevBrand) => {
            if (prevBrand === "" || prevBrand === null) {
                return getBrand(routes(role, auth));
            }
            return getBrand(routes(role, auth));
        });
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [location, role, auth]);

    useEffect(() => {
        upComingEvent && checkTimer(upComingEvent, setTime);
    }, [upComingEvent])

    return (
        <Navbar
            expand="lg"
            className="fixed-top"
            style={{
                zIndex: 1030,
                border: 0,
                backgroundColor: '#f4f3ef',
                width: `calc(100% - ${width > 993 || !width ? 250 : 0}px)`, // Adjust the width when the sidebar is open
                marginLeft: width > 993 || !width ? 260 : 0, // Offset the navbar when the sidebar is visible
                transition: 'margin-left 0.3s ease, width 0.3s ease',
            }}
        >
            <Container className="border-0" fluid>
                <div className="navbar-wrapper">
                    <div className="navbar-toggle">
                        <button
                            type="button"
                            ref={sidebarToggle}
                            id="button-toggle-sidebar"
                            className="navbar-toggler border-0"
                            onClick={openSidebar}
                        >
                            <span className="navbar-toggler-bar bar1"/>
                            <span className="navbar-toggler-bar bar2"/>
                            <span className="navbar-toggler-bar bar3"/>
                        </button>
                    </div>
                    <div className="d-flex align-items-center">
                        <Tooltip title="Retour" placement="bottom">
                            <IconButton
                                id="sidebar-return-id"
                                onClick={() => goBack()}
                                className="border-right border-bottom"
                                sx={{
                                    mr: 0.5,
                                    mt: 0,
                                    mb: 0,
                                    fontSize: width < 500 ? 12 : 15,
                                }}
                            >
                                <KeyboardBackspaceIcon/>
                            </IconButton>
                        </Tooltip>
                        <NavbarText className="ml-2 d-none d-md-block">
                            <div role="presentation">
                                <Breadcrumbs aria-label="breadcrumb">
                                    {isDashPath ? (
                                        <Typography variant="caption">Tableau de Bord</Typography>
                                    ) : null}
                                    <Typography>
                                        {brand}
                                    </Typography>
                                    {pathname === "agenda" && upComingEvent && (
                                        <Typography variant="subtitle2" color="text.secondary">
                                            <Timer
                                                left
                                                timerData={time}
                                                label={upComingEvent?.reservation?.event + " dans "}/>
                                        </Typography>
                                    )}
                                </Breadcrumbs>
                            </div>
                        </NavbarText>
                    </div>
                </div>
                <Box component="div">
                    <SearchBar/>
                </Box>
            </Container>
        </Navbar>
    );
}

export default NavbarCustom;
